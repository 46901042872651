// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {NgxsLoggerPluginModule} from '@ngxs/logger-plugin';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {Environment} from '../modules/types/interfaces/environment';

export const environment: Environment = {
  type: 'dev',
  production: false,
  plugins: [
    NgxsLoggerPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot()
  ],
  apiUrl: 'https://back.kytd.alzp.tgscloud.net/api',
  logoutUri: 'https://digitalpassport.hubtotal.net',
  config:{
    "serviceBase": "/kyt/",
    "region": "eu-central-1",
    "userPoolId": "eu-central-1_W0PNBeo9g",
    "clientId": "6oqgsn3f814s65qbk4d5c0i36t",
    "redirectBaseUrl": "https://kytd.alzp.tgscloud.net/",
    "domainUrl": "tte-pool-prod.auth.eu-central-1.amazoncognito.com",
    "baseURL": "https://kytd.alzp.tgscloud.net/",
    "digital": "DigitalPass",
    "gigya": "GigyaB2BP"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

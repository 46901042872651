import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { AppSandboxService } from 'src/modules/app/app-sandbox.service';
import { MappingCode } from 'src/modules/types/interfaces';

@Component({
  selector: 'kyt-mapping-code',
  template: `
  <section>
    <div class="title-wrapper">
        <span class="title">
          <h1>Mapping BIN/EP Code</h1>
        </span>
    </div>
    <div class="mapping-container mat-elevation-z8">
      <table mat-table
             [dataSource]="  mappingCode$ | async"
             class="mat-elevation-z0">


        <ng-container matColumnDef="bin">
          <th mat-header-cell *matHeaderCellDef >BIN</th>
          <td mat-cell *matCellDef="let element"> {{ element?.bin}} </td>
        </ng-container>


        <ng-container matColumnDef="ep_code">
          <th mat-header-cell *matHeaderCellDef >EP Code</th>
          <td mat-cell *matCellDef="let element">
          <mat-form-field appearance="outline">
            <mat-label>EP Code</mat-label>
            <input autocomplete="off" required [(ngModel)]="element.ep" (keydown.enter)="addEpCode(element)" matInput type="text"/>
          </mat-form-field>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <mat-paginator [length]="mappingCodePaginator$ | async"
                   [pageSize]="10"
                   [pageSizeOptions]=[10,20]
                   (page)="onPageChange($event)">
    </mat-paginator>
  </section>
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p>Data is loading, please wait. </p>
  </ngx-spinner>
  `,
  styleUrls: ['./mapping-code.component.scss']
})
export class MappingCodeComponent implements OnInit {
  displayedColumns: string[] = ['bin', 'ep_code'];
  mappingCode$ = this.sb.mappingCodeObservable;
  mappingCodePaginator$ = this.sb.mappingCodePaginatorObservable;

  parameters: MappingCode = {
    id: '',
    ep: '',
    bin: '',
    limit: 10,
    offset: 0
  };

  constructor(private sb: AppSandboxService) { }

  ngOnInit(): void {
    this.loadMappingCode();
  }

  loadMappingCode(): void{
    this.sb.getMappingCode(this.parameters);
    this.sb.getMappingCodePaginator();
  }

  onPageChange(event?: PageEvent): void {
    this.parameters.limit = event.pageSize;
    this.parameters.offset = event.pageIndex * event.pageSize;
    this.loadMappingCode();
  }


  addEpCode(element): void{
    if (element.ep.trim() !== ''){
      this.parameters.id = element.id;
      this.parameters.ep = element.ep;
      delete this.parameters.bin;
      this.sb.updateMappingCode(this.parameters);
    }
  }
}

import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {AppSandboxService} from '../../app-sandbox.service';
import {map} from 'rxjs/operators';
import { ACCESS } from '../../tools/access';
import { Auth } from 'aws-amplify';
import { CognitoService } from '../../services/cognito/cognito.service';

@Component({
  selector: 'kyt-root',
  styleUrls: ['./app.container.scss'],
  template: `
    <kyt-header *ngIf="(userDetailsAvailable$ | async) && (userDetails$.getValue().status==='Active')"
                [user]="userDetails$ | async"
                (goToLanding)="goToLanding()"></kyt-header>

     <kyt-login *ngIf="(userDetailsAvailable$ | async) === false"></kyt-login>
    <kyt-not-allowed *ngIf="userDetails$.getValue()?.status==='Deactivated' || userDetails$.getValue()?.status==='' "></kyt-not-allowed>
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
      <p style="color:#fff;">Data is loading, please wait. </p>
    </ngx-spinner>
    <router-outlet></router-outlet>
  `
})
export class AppContainer implements OnInit {
  userDetails$ = this.sb.currentUser;
  isAuthenticated$ = this.cognitoService.authenticationSubject;
  hasLoggedOutFromKYT$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  userDetailsAvailable$ = this.sb.userDetailAvailable;
  access = ACCESS;
  constructor(private router: Router, private sb: AppSandboxService,
              private cognitoService: CognitoService) {
  }

  ngOnInit() {
  }

  logout(): void {
    this.router.navigate(['/logout']).then();
    this.hasLoggedOutFromKYT$.next(true);
  }

  goToLanding(): void {
    if (this.userDetailsAvailable$.getValue()){
        if (this.userDetails$.getValue().role === 'Armatis'){
          this.router.navigate(['/alerts-follow-up/cards']).then();
        }else{
          this.router.navigate(['/kyt/card-alerts']).then();
        }
    }else{
      this.router.navigate(['/login']);
    }
  }

  public getLoggedUserEmail() {
    return Auth.currentSession().then((res) => {
      const userEmailObject = res.getIdToken().decodePayload();
      const userEmail = userEmailObject['email'];
      localStorage.setItem('email', userEmail.toLowerCase());
    });
  }
}

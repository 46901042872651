import {Auth} from '../../types/interfaces/auth';

export class Login {
  static readonly type = '[Auth] Login';

  constructor(public payload: Auth) {
  }
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

import {Component, Output, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';
import { CognitoService } from '../../services/cognito/cognito.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'kyt-login',
  template: `
    <mat-card>
      <mat-card-title>
        <img src="../../../../assets/images/total-energies-logo.svg" alt="total-energies-logo"/>
      </mat-card-title>
      <mat-card-content>
        <div>
          <h5>KYT</h5>
          <div class="btn-container">
          <button mat-flat-button (click)="connexionRequest()">
            <mat-icon>arrow_right</mat-icon>
            Connexion
          </button>
          <button mat-flat-button (click)="externalLogin()">
            <mat-icon>arrow_right</mat-icon>
            External Login
          </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  `,
  styleUrls: ['./login.container.scss']
})
export class LoginContainer{
  constructor(private router: Router,private cognitoService: CognitoService, private spinnerSerivce: NgxSpinnerService){ }

  connexionRequest(): void {
    this.spinnerSerivce.show();
    this.cognitoService.digitalLogin().catch((err) => {
        console.log(err);
        this.spinnerSerivce.hide();
      })
      // .finally(()=> this.spinnerSerivce.hide());
    }

  externalLogin(): void {
    this.spinnerSerivce.show();
    this.cognitoService.gigyaLogin().catch((err) => {
      this.spinnerSerivce.hide();
      console.log(err);
    })
    // .finally(()=> this.spinnerSerivce.hide());
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomerFactorisation, CustomerFactorisationList } from 'src/modules/types/interfaces';
import { applyOnKeys, camelToSnake, snakeToCamel } from '../../tools';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from '../snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerFactorisationService {

  apiUrl = environment.apiUrl;
  sub: any;
  data: any;

  constructor(private http: HttpClient, private spinnerService: NgxSpinnerService,
              private snackBar: SnackBarService) {
}

  getFactorisationDetails(offset: number, limit: number, sortOrder: string, pcomNumber: string,
                          client: string, startDate: string, endDate: string): Observable<CustomerFactorisation>{
    return this.http.get(`${this.apiUrl}/customer_factorisation?offset=${offset}&limit=${limit}&sort_order=${sortOrder}&pcom_number=${pcomNumber.trim()}&client=${client.trim()}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map(value => {
        return applyOnKeys(value, snakeToCamel);
      }), catchError(error => {
        return of([]);
      })
    );
  }


  getFactorisationPaginator(pcomNumber: string, client: string, startDate: string, endDate: string): Observable<any>{
    return this.http.get(`${this.apiUrl}/customer_factorisation_paginator?pcom_number=${pcomNumber}&client=${client}&start_date=${startDate}&end_date=${endDate}`);
  }


  factoriseClients(parameters: CustomerFactorisationList): Observable<CustomerFactorisation>{
    return this.http.post(`${this.apiUrl}/update_customer_factorisation`, parameters).pipe(
      map(value => {
        return applyOnKeys(value, snakeToCamel);
      }), catchError(error => {
        return of([]);
      })
    );
  }


  deleteClients(parameters: string[]): Observable<CustomerFactorisation>{
    this.spinnerService.show();
    return this.http.delete(`${this.apiUrl}/update_customer_factorisation?client_list=${parameters}`).pipe(
      map(value => {
        this.spinnerService.hide();
        this.snackBar.openSnackBar('Deleted successfully', 'success');
        return applyOnKeys(value, snakeToCamel);
      }), catchError(error => {
        this.spinnerService.hide();
        this.snackBar.openSnackBar('Failed to deleted ', 'error');
        return of([]);
      })
    );
  }

  export(parameters: {client: string; pcomNumber: string, startDate: string  , endDate: string, offset?: number, limit?: number}): void{
    this.spinnerService.show();
    this.http.post(`${this.apiUrl}/export_customer_factorisation`, applyOnKeys(parameters, camelToSnake)
    ).subscribe(response => {
      const task_id = JSON.parse(JSON.stringify(response)).task_id;
      const blobName = JSON.parse(JSON.stringify(response)).blob_name;
      this.sub = timer(0, 10000).pipe(
        map(() => {
          this.http.get(`${this.apiUrl}/export_status?task_id=${task_id}&blob_name=${blobName}`, {
            responseType: 'json'
          }
          ).subscribe((res) => {
            if (res) {
              this.data = JSON.parse(JSON.stringify(res));
              if (this.data.status === 200) {
                const export_csv_url = this.data.export_csv_url;
                window.open(export_csv_url, '_self');
                this.sub.unsubscribe();
                this.spinnerService.hide().then();
              }
            }
          });
        })
      ).subscribe();
    }, error => {
      this.spinnerService.hide();
    });
}

}

import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {FetchMicaProductPageLength} from '../actions/mica-product-paginator.actions';

@State<number[]>({
  name: 'MicaProductPaginator',
  defaults: []
})

@Injectable({
  providedIn: 'root'
})
export class MicaProductPaginatorState {
  @Action(FetchMicaProductPageLength)
  getPageLength(ctx: StateContext<number>, {payload}: FetchMicaProductPageLength): void {
    ctx.setState(payload);
  }
}

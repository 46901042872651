import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ACCESS } from '../../tools/access';
import { AppSandboxService } from '../../app-sandbox.service';

interface TreeNode {
  label: string;
  icon: string;
  path: string | null;
  index: number;
  children?: TreeNode[];
}

@Component({
  selector: 'kyt-side-nav',
  template: `
   <mat-sidenav-container class="side-nav-container">
<mat-sidenav mode="side" #sidenav  opened="true" class="sidenav">
      <mat-action-list>

          <div *ngIf="sidenav.opened" class="btn-close">
            <mat-icon  (click)="sidenav.toggle()" >remove</mat-icon>
          </div>


          <ng-container  *ngFor="let tab of listOfTabs">
              <ng-container *ngIf="tab?.children?.length > 0;else originalTab">
                <div class="tab">
                 <kyt-side-nav-tree [treeData]="tab"
                  [selectedIndex]="selectedIndex"
                 (tabClicked)="tabClicked($event)"></kyt-side-nav-tree>
                </div>
              </ng-container>

              <ng-template #originalTab>
                <ng-container *ngIf="accessCheck(tab)">
                  <div class="tab" mat-list-item [ngClass]="{ 'selected-tab': selectedIndex === tab.index }" (click)="tabClicked(tab)">
                    <mat-icon>{{tab.icon}}</mat-icon>
                    <span class="tab-name">{{tab.label}}</span>
                  </div>
                </ng-container>
              </ng-template>
          </ng-container>

        </mat-action-list>
      </mat-sidenav>
      <mat-sidenav-content>
          <div class="btn-add-container" *ngIf="!sidenav.opened">
            <span>
              <mat-icon (click)="sidenav.toggle()">add</mat-icon>
            </span>
          </div>
          <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  `,
  styleUrls: ['./side-nav.component.scss']
})
/*
As below exapmple child tab can be added
{ icon:'tune', label:'MAPPING NETWORK CODES', path:'/configuration/mapping-netowork-codes',
  children:[
    { icon:'local_gas_station', label:'MICA PRODUCTS', path:'/configuration/mica-products', index:1},
    { icon:'storage', label:'RULES CONFIGURATION', path:'/configuration/rules-configuration', index: 2,},]
}
*/
export class SideNavComponent implements OnInit {
  @Input() listOfTabs: TreeNode[];
  selectedIndex: number;
  opened: boolean;
  user = this.sb.currentUser?.getValue();
  access = ACCESS;


  constructor(private router: Router, private location: Location, private sb: AppSandboxService) {}

          ngOnInit(): void {
            const index = this.listOfTabs?.findIndex((tab) => tab.path === this.location.path());
            if (index > -1){
              this.tabClicked(this.listOfTabs[index]);
            }
          }

          toggleSideNav(): boolean {
            this.opened = !this.opened;
            return this.opened;
          }

          tabClicked(tab: TreeNode): void{
              this.selectedIndex = tab.index;
              if (tab.path) { this.router.navigate([tab.path]); }
          }

          accessCheck(tab: TreeNode): boolean{
            const mainRoute = document.location.pathname.split('/')[1];
            const index = this.access[mainRoute].internal_routes.findIndex((internalRoute) => internalRoute.route === tab.path );
            if ( index > -1){
              if (this.access[mainRoute].internal_routes[index].route_access.includes(this.user.role)){
                return true;
              }else{
                return false;
              }
            }
            return false;
          }
  }


import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import { BlackListedBins } from 'src/modules/types/interfaces';
import { FetchBlackListedBins } from '../actions/blacklisted-bins.actions';

@State<BlackListedBins>({
  name: 'bins',
  defaults: {} as null
})

@Injectable({
  providedIn: 'root'
})
export class BlackListedBinsState {
  @Action(FetchBlackListedBins)
  getBlackListedBins(ctx: StateContext<BlackListedBins[]>, {payload}: FetchBlackListedBins): void {
    ctx.setState({...payload});
  }
}

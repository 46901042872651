import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {NetworkCodeParams} from '../../types/interfaces/configuration';
import {FetchNetworkCodeParamsList} from '../actions/network-code-param.actions';

@State<NetworkCodeParams[]>({
  name: 'listNetworkCodeParams',
  defaults: []
})

@Injectable({
  providedIn: 'root'
})
export class NetworkCodeParamsState {
  @Action(FetchNetworkCodeParamsList)
  getAlertsParams(ctx: StateContext<NetworkCodeParams>, {payload}: FetchNetworkCodeParamsList): void {
    ctx.setState({...payload});
  }
}

import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {CurrentUser} from '../../types/interfaces';
import {FetchCurrentUser} from '../actions/current-user.actions';

@State<CurrentUser>({
  name: 'currentUser',
  defaults: {} as null
})

@Injectable({
  providedIn: 'root'
})
export class CurrentUserState {
  @Action(FetchCurrentUser)
  getCurrentUser(ctx: StateContext<CurrentUser>, {payload}: FetchCurrentUser): void {
    ctx.setState(payload);
  }
}

import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {applyOnKeys, snakeToCamel} from '../../tools';
import {CurrentUser} from '../../../types/interfaces';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {

  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  /**
   * TODO: fetch current logged in user details
   * @param igg: user Igg number
   * @return observable of current user information
   */
  async fetchUserDetails(igg: string): Promise<CurrentUser> {
  try {
    const data = await this.http.get<CurrentUser>(`${this.apiUrl}/user-information?igg=${igg}`)
        .pipe(
          map(userDetails => applyOnKeys(userDetails, snakeToCamel))
        ).toPromise();
    return data;
    } catch (error) {
      if (error?.error?.error?.includes('not present in user container') || error.status === 404){
        return {firstname: '', lastname: '', role: '', status: '', igg: null};
      }
      return null;
    }
  }  
}

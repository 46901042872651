import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {Transaction} from '../../types/interfaces/transaction';
import {FetchTransactions} from '../actions/transactions.action';

@State<Transaction[]>({
  name: 'transactions',
  defaults: []
})

@Injectable({
  providedIn: 'root'
})
export class TransactionsState {
  @Action(FetchTransactions)
  getAlerts(ctx: StateContext<Transaction[]>, {payload}: FetchTransactions): void {
    ctx.setState([...payload]);
  }
}

import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, of, timer} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {applyOnKeys, snakeToCamel} from '../../tools';
import {User} from '../../../types/interfaces/user';
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class UsersListService {

  apiUrl = environment.apiUrl;
  data;
  sub;

  constructor(private http: HttpClient, private snackBar: SnackBarService, private spinnerService: NgxSpinnerService) {
  }

  fetchUsersList(offset: number, limit: number, sortKey: string, sortOrder: string,
                 searchKey: string, role: string[], status: string, userAffiliate: [], isExternal: boolean | null): Observable<User[]> {
    return this.http.get<User[]>(`${this.apiUrl}/users-list?offset=${offset}&limit=${limit}
                                  &search_key=${searchKey}&role=${role}&status=${status}`
                                  + `&user_affiliate=${userAffiliate}&sort_key=${sortKey}&sort_order=${sortOrder}`+
                                `&is_external=${isExternal}`)
      .pipe(
        map(users => {
          return applyOnKeys(users, snakeToCamel);
        }),
        catchError((error) => {
          this.spinnerService.hide();
          this.snackBar.openSnackBar('Failed to load users list', 'error');
          return of([]);
        })
      );
  }

  fetchNumberOfUsers(searchKey: string, role: string[], status: string, userAffiliate: [], isExternal: boolean | null): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}/users-list-paginator?search_key=${searchKey}&role=${role}&status=${status}&user_affiliate=${userAffiliate}`+
                                                `&is_external=${isExternal}`);
  }

  removeUser(id: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/update_user_information?id=${id}`);
  }

  updateUserDetails(user): Observable<any>{
    return this.http.post(`${this.apiUrl}/update_user_information`, user);
  }

  updateLastConnection(id: string): void{
    this.http.put(`${this.apiUrl}/update_user_information`, {id}).subscribe();
  }

  createUser(parameters): Observable<User[]>{
    return this.http.post(`${this.apiUrl}/save_user_information`, parameters)
    .pipe(
      map(users => {
        return applyOnKeys(users, snakeToCamel);
      }),
      catchError((error) => {
        this.spinnerService.hide();
        this.snackBar.openSnackBar('Failed to load users list', 'error');
        return of([]);
      })
    );
  }

  export(parameters: {searchKey: string; role: string; status: string; userAffiliate: string; sortKey: string; sortOrder: string; isExternal: boolean | null}): void {
    this.spinnerService.show().then();
    this.http.get(`${this.apiUrl}/export_user_list?search_key=${parameters.searchKey}&role=${parameters.role}&status=${parameters.status}&user_affiliate=${parameters.userAffiliate}&sort_key=${parameters.sortKey}&sort_order=${parameters.sortOrder}&is_external=${parameters.isExternal}`).subscribe(response => {
      const blob_name = JSON.parse(JSON.stringify(response)).blob_name;
      this.sub = timer(0, 10000).pipe(
        map(() => {
          this.http.get(`${this.apiUrl}/export_status?task_id=${blob_name}`, {
            responseType: 'json'
          }).subscribe((res) => {
            if (res) {
              this.data = JSON.parse(JSON.stringify(res));
              if (this.data.status === 200) {
                const export_csv_url = this.data.export_csv_url;
                window.open(export_csv_url, '_self');
                this.sub.unsubscribe();
                this.spinnerService.hide().then();
              }
            }
          }, (error) => {
            this.spinnerService.hide();
            this.snackBar.openSnackBar('Failed to export', 'error');
            this.sub.unsubscribe();
          });
        })).subscribe();
    }, error => {
      this.spinnerService.hide().then();
    });

  }

}

import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { applyOnKeys, camelToSnake, snakeToCamel } from '../../tools';
import { AlertCommentObj, AlertInformation, BadgeInformation, GenericStatus } from '../../../types/interfaces';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from '../snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class AlertDetailsService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private spinnerService: NgxSpinnerService,
              private snackBar: SnackBarService) {
}


  /**
   * TODO: fetch alert rules list by date and card chosen in alert analysis page
   * @param alertUuid: alert uuid
   * @return observable of alert information and rules list
   */
  fetchAlertDetails(alertUuid: string, pcomNumber: string, container: string): Observable<AlertInformation> {
    return this.http.get<AlertInformation>(`${this.apiUrl}/pcom-alert?alert-uuid=${alertUuid}&pcom_number=${pcomNumber}&container=${container}`)
      .pipe(
        map(alertDetails => applyOnKeys(alertDetails, snakeToCamel))
      );
  }

  updateComment(alertUuid: string, comment: AlertCommentObj, container: string): Observable<GenericStatus> {
    const params = new HttpParams().append('alert-uid', alertUuid)
                                  .append('container', container);
    const body = JSON.stringify(applyOnKeys(comment, camelToSnake));

    return this.http.post<GenericStatus>(`${this.apiUrl}/update-alert-comment`, body, { params })
      .pipe(map(status => applyOnKeys(status, snakeToCamel)));
  }

  markAlertDetail(uuid: string, read: boolean, container: string): Observable<GenericStatus> {
    const param = { alert_read: read };
    return this.http.post(`${this.apiUrl}/pcom-alert?alert-uuid=${uuid}&container=${container}`, param)
    .pipe(
      map(value => applyOnKeys(value, snakeToCamel))
    );
  }

  fetchBadgeDetails(alertUuid: string, pcomNumber: string): Observable<BadgeInformation> {
    this.spinnerService.show();
    return this.http.get<BadgeInformation>(`${this.apiUrl}/pcom-alert?alert-uuid=${alertUuid}&container=badges`)
      .pipe(
        map(alertDetails => {
          this.spinnerService.hide();
          return applyOnKeys(alertDetails, snakeToCamel);
        }), catchError(error => {
            this.spinnerService.hide();
            this.snackBar.openSnackBar('Failed to load badge details', 'error');
            return of([]);
          })
      );
  }

  getClientRefDna(alertUuid: string, container: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/client_ref_dna?alert_id=${alertUuid}&container=${container}`)
      .pipe(
        map(dnaDetails => {
          return applyOnKeys(dnaDetails, snakeToCamel);
        }), catchError(error => {
            this.snackBar.openSnackBar('Failed to load  client dna', 'error');
            return of([]);
          })
      );
  }

}

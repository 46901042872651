import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { applyOnKeys, snakeToCamel, camelToSnake } from '../../tools';

@Injectable({
  providedIn: 'root'
})

export class BlacklistParametersService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private snackBar: SnackBarService,
              private spinnerService: NgxSpinnerService) { }


  getBlacklistParameters(): Observable<any>{
    this.spinnerService.show();
    return this.http.get(`${this.apiUrl}/blacklist_parameters`).pipe(
      map(value => {
        this.spinnerService.hide();
        this.snackBar.openSnackBar('Blacklist parameters loaded successfully', 'success');
        return applyOnKeys(value, snakeToCamel);
      }), catchError(error => {
        this.spinnerService.hide();
        this.snackBar.openSnackBar('Failed to load blacklist parameters', 'error');
        return of([]);
      })
    );
  }

  createNewParameter(parameter: {'new_param_key': string, 'new_param_value': string}): Observable<any>{
    this.spinnerService.show();
    return this.http.put(`${this.apiUrl}/blacklist_parameters`, parameter).pipe(
      map(value => {
        this.spinnerService.hide();
        this.snackBar.openSnackBar('New parameter added successfully', 'success');
        return applyOnKeys(value, snakeToCamel);
      }), catchError(error => {
        this.spinnerService.hide();
        this.snackBar.openSnackBar('Failed to create parameter', 'error');
        return of([]);
      })
    );
  }

  editParameter(parameter: { [key: string]: string}): Observable<any>{
    this.spinnerService.show();
    return this.http.post(`${this.apiUrl}/blacklist_parameters`, applyOnKeys(parameter, camelToSnake)).pipe(
      map(value => {
        this.spinnerService.hide();
        this.snackBar.openSnackBar('Parameter updated successfully', 'success');
        return applyOnKeys(value, snakeToCamel);
      }), catchError(error => {
        this.spinnerService.hide();
        this.snackBar.openSnackBar('Failed to update parameter', 'error');
        return of([]);
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppSandboxService } from '../app-sandbox.service';
import { CurrentUser } from '../../types/interfaces';
import { ACCESS } from 'src/modules/app/tools/access';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  user$: Observable<CurrentUser> = this.sb.currentUser$;
  userDetails: CurrentUser;
  access = ACCESS;
  constructor(
    private sb: AppSandboxService, private router: Router) {
  }

 async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      try {
        const check = await this.sb.canActivateCurrentUser();
        if (check){
          if (this.isRouteAccessible(route.routeConfig.path, state.url)){
            return true;
          }else if (this.sb.currentUser.getValue().status === 'Deactivated' || this.sb.currentUser.getValue().status === ''){
            return false;
          }else if (this.sb.currentUser.getValue().status === 'Active' &&
                    (location.pathname === '/kyt/card-details' ||
                    location.pathname === '/kyt/badge-details' ||
                    location.pathname === '/kyt/machine-learning-alert-details')){
            return true;
          }
        }else{
          return false;
        }
      } catch (error) {
        console.error(error);
        return false;
      }
    }

    isRouteAccessible(mainRoute: string, url: string): boolean {
      const user = this.sb.currentUser.getValue();
      const filteredAccess = this.access[mainRoute].internal_routes.filter((route) => route.route_access.includes(user.role));
      if (user.status === 'Active'){
        if (this.access[mainRoute].main_route_access.includes(user.role)){
          for (const froutes of filteredAccess) {
            if (froutes.route === url){
              return true;
            }
          }
        }
      }
      return false;
    }
 }


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of, timer } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ProvidersConfigurationFilters } from 'src/modules/types/interfaces';
import { applyOnKeys, camelToSnake, snakeToCamel } from '../../tools';
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { environment } from 'src/environments/environment';
import { queryBuilder } from '../../tools/queryBuilder';

@Injectable({
  providedIn: 'root'
})
export class ProvidersConfigurationService {
  apiUrl = environment.apiUrl;
  data;
  sub;
  constructor(private http: HttpClient, private snackBar: SnackBarService,
              private spinnerService: NgxSpinnerService) { }

 async getProvidersConfiguration(param: ProvidersConfigurationFilters): Promise<any> {
    this.spinnerService.show();
    return await this.http.get(`${this.apiUrl}/providers-configuration?${queryBuilder(param)}`).toPromise()
    .then(response => {
      this.snackBar.openSnackBar('Providers config loaded successfulluy', 'success');
      return applyOnKeys(response, snakeToCamel);
    }).catch((error) => {
      this.snackBar.openSnackBar('Failed to load details', 'error');
      console.error(error?.error || error || 'Unexpected error');
      return [];
    }).finally(() => this.spinnerService.hide());
  }

  async getProvidersConfigurationPaginator(param: ProvidersConfigurationFilters): Promise<any> {
    this.spinnerService.show();
    return await this.http.get(`${this.apiUrl}/providers-configuration-paginator?${queryBuilder(param)}`).toPromise()
    .then(response => {
      return applyOnKeys(response, snakeToCamel);
    }).catch((error) => {
      console.error(error?.error || error || 'Unexpected error');
      return [];
    }).finally(() => this.spinnerService.hide());
  }

  exportProvidersConfiguration(filters: ProvidersConfigurationFilters): void {
    this.spinnerService.show().then();
    this.http.post(`${this.apiUrl}/export-providers-configuration`, filters).subscribe(response => {
      const blob_name = JSON.parse(JSON.stringify(response)).blob_name;
      this.sub = timer(0, 10000).pipe(
        map(() => {
          this.http.get(`${this.apiUrl}/export_status?task_id=${blob_name}`, {
            responseType: 'json'
          }).subscribe((res) => {
            if (res) {
              this.data = JSON.parse(JSON.stringify(res));
              if (this.data.status === 200) {
                const export_csv_url = this.data.export_csv_url;
                window.open(export_csv_url, '_self');
                this.sub.unsubscribe();
                this.spinnerService.hide().then();
              }
            }
          }, (error) => {
            this.spinnerService.hide();
            this.snackBar.openSnackBar('Failed to export', 'error');
            this.sub.unsubscribe();
          });
        })).subscribe();
    }, error => {
      this.spinnerService.hide().then();
      this.snackBar.openSnackBar('Failed to export', 'error');
    });

  }

  async createProvidersConfiguration(param: ProvidersConfigurationFilters): Promise<any>{
    this.spinnerService.show();
    return await this.http.post(`${this.apiUrl}/providers-configuration`, applyOnKeys(param, camelToSnake)).toPromise()
    .then(response => {
      this.snackBar.openSnackBar('Providers config created successfulluy', 'success');
      return applyOnKeys(response, snakeToCamel);
    }).catch((error) => {
      this.snackBar.openSnackBar('Failed to update configuration', 'error');
      console.error(error?.error || error || 'Unexpected error');
      return [];
    }).finally(() => this.spinnerService.hide());
  }

}

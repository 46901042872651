import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {User} from '../../../../types/interfaces';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import { AppSandboxService } from 'src/modules/app/app-sandbox.service';
import { MatDialog } from '@angular/material/dialog';
import { EditUserComponent } from '../edit-user/edit-user.component';
import { Observable } from 'rxjs';
import { PreviousRolesComponent } from './previous-roles/previous-roles.component';

@Component({
  selector: 'kyt-users-table',
  styleUrls: ['./users-table.component.scss'],
  template: `
    <table mat-table
           [dataSource]="usersList$ | async"
           class="mat-elevation-z0"
           matSort
           (matSortChange)="sortData($event)"
           matSortStart="desc">

      <!-- Igg Column -->
      <ng-container matColumnDef="igg">
        <th mat-header-cell *matHeaderCellDef>IGG</th>
        <td mat-cell *matCellDef="let element"> {{element.igg}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
        <td mat-cell *matCellDef="let element"> {{element.lastname | uppercase}} {{element.firstname | titlecase }}</td>
      </ng-container>

      <!-- E-mail Column -->
      <ng-container matColumnDef="eMail">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let element"> {{element.email}}</td>
      </ng-container>

      <!-- Affiliate Column -->
      <ng-container matColumnDef="affiliate">
        <th mat-header-cell *matHeaderCellDef>Affiliate</th>
        <td mat-cell *matCellDef="let element"> {{element.userAffiliate}}</td>
      </ng-container>

      <!-- Role Column -->
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Role</th>
        <td mat-cell *matCellDef="let element">{{element.role}}</td>
      </ng-container>

      <!-- status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element"> {{element.status}}</td>
      </ng-container>

        <!-- Last connection -->
        <ng-container matColumnDef="lastConnexion">
          <th mat-header-cell *matHeaderCellDef> Last Connecion</th>
          <td mat-cell *matCellDef="let element"> {{element.lastConnectionDate | date:'dd MMM yyyy'}}</td>
        </ng-container>

        <!-- User type -->
        <ng-container matColumnDef="userType">
          <th mat-header-cell *matHeaderCellDef> User Type</th>
          <td mat-cell *matCellDef="let element"> {{element.isExternal ? 'External' : 'Internal'}}</td>
        </ng-container>

      <!-- view Column -->
      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button (click)="openPreviousRoles(element)" mat-icon-button matTooltip="Activity Log" aria-label="alert details">
            <mat-icon>
              visibility
            </mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- edit Column -->
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button [disabled]="isDisabled" (click)="editUser(element)" mat-icon-button matTooltip="Edit User" aria-label="alert details">
            <mat-icon>
              edit
            </mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [length]="pageLength$ | async"
                   [pageSize]=10
                   [pageSizeOptions]=[10,20]
                   (page)="onPageChange($event)">
    </mat-paginator>
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
      <p>Data is loading, please wait. </p>
    </ngx-spinner>
  `
})
export class UsersTableComponent implements OnInit{
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() listUsersSort: EventEmitter<any> = new EventEmitter();
  @Output() pageChange: EventEmitter<any> = new EventEmitter();
  @Input() resetPageIndex: Observable<void>;
  @Input() listOfAffiliates: string[];
  usersList$ = this.sb.usersListObservable;
  pageLength$ = this.sb.usersListPaginatorObservable;
  isDisabled = this.sb.getFieldStatus();
  displayedColumns: string[] = ['igg', 'name', 'eMail', 'userType','affiliate', 'role', 'status', 'lastConnexion', 'view', 'edit'];

  constructor(private sb: AppSandboxService, public dialog: MatDialog){

  }
  ngOnInit(): void {
    this.resetPageIndex?.subscribe(() => {
      this.paginator.pageIndex = 0;
    });
  }

  onPageChange(event?: PageEvent): void {
    this.pageChange.emit({
      offset: event.pageIndex * 10,
      limit: event.pageSize,
    });
  }

  sortData(sort: Sort): void {
    sort.active = sort.active === 'name' ? 'user_name' : sort.active;
    if(this.paginator){
      this.paginator.pageSize = 0;
    }
    this.listUsersSort.emit({
      offset: 0,
      limit: 10,
      sortKey: sort.direction && sort.active ? sort.active : '',
      sortOrder: sort.direction ? sort.direction.toUpperCase() : 'DESC'
    });
  }

  editUser(element: Partial<User>): void{
    this.dialog.open(EditUserComponent,
    {data: {
      user: element ,
      listOfAffiliates: this.listOfAffiliates
    }});
  }

  openPreviousRoles(element: Partial<User>): void {
    this.dialog.open(PreviousRolesComponent, {
      data: element, minWidth: '45vw'
    });
  }

}

import { Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { applyOnKeys, snakeToCamel } from '../../tools';
import { BlackListedBins, NetworkParameters, UpdateBlackListedBins, UpdateNetworkParamerters, UpdateBlackListedBinsNote } from 'src/modules/types/interfaces';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class NetworkParametersBlacklistedBinsService {

  apiUrl = environment.apiUrl;
  data;
  sub;
  constructor(private http: HttpClient, private snackBar: SnackBarService,
              private spinnerService: NgxSpinnerService) { }
  // get blacklisted-bins
  fetchBlackListedBins(): Observable<BlackListedBins[]>{
    return  this.http.get<BlackListedBins[]>(`${this.apiUrl}/blacklisted-bin`).pipe(
      map(bins => {
      this.snackBar.openSnackBar('Blacklisted bins list fetched successfully', 'success');
      return applyOnKeys(bins, snakeToCamel);
    }), catchError(error => {
      this.snackBar.openSnackBar('Failed to load blacklisted bins list', 'error');
      return of([]);
    }));
  }

  // post status change for blacklisted-bins
  postStatusChange(parameters: UpdateBlackListedBins): Observable<any>{
    return this.http.post<UpdateBlackListedBins>(`${this.apiUrl}/update_blacklisted_bin`, parameters);
  }

  // fetch network-parameters section -1
  fetchNetworkParamerters(): Observable<NetworkParameters[]>{
    return this.http.get<NetworkParameters[]>(`${this.apiUrl}/network_parameters`).pipe(
      map(network => {
      this.snackBar.openSnackBar('Network parameters list fetched successfully', 'success');
      return applyOnKeys(network, snakeToCamel);
    }), catchError(error => {
      this.snackBar.openSnackBar('Failed to load network list', 'error');
      return of([]);
    }));
  }

  updateNetworkParamerters(parameters: UpdateNetworkParamerters): Observable<any>{
    return this.http.post<UpdateNetworkParamerters>(`${this.apiUrl}/update_network_parameters`, parameters);
  }

  updateBlackListedBinsNote(parameters: UpdateBlackListedBinsNote): Observable<any>{
    return this.http.post<UpdateBlackListedBinsNote>(`${this.apiUrl}/update_blacklisted_bin`, parameters);
  }

  addNewBin(parameters: {bin: string, note: string|null}): Observable<any>{
    return this.http.post(`${this.apiUrl}/add-blacklisted-bin`, parameters);
  }

  exportNetworkBlacklistedBins(): void {
    this.spinnerService.show().then();
    this.http.get(`${this.apiUrl}/export_blacklisted_network_bin`).subscribe(response => {
      const blob_name = JSON.parse(JSON.stringify(response)).blob_name;
      this.sub = timer(0, 10000).pipe(
        map(() => {
          this.http.get(`${this.apiUrl}/export_status?task_id=${blob_name}`, {
            responseType: 'json'
          }).subscribe((res) => {
            if (res) {
              this.data = JSON.parse(JSON.stringify(res));
              if (this.data.status === 200) {
                const export_csv_url = this.data.export_csv_url;
                window.open(export_csv_url, '_self');
                this.sub.unsubscribe();
                this.spinnerService.hide().then();
              }
            }
          }, (error) => {
            this.spinnerService.hide();
            this.snackBar.openSnackBar('Failed to export', 'error');
            this.sub.unsubscribe();
          });
        })).subscribe();
    }, error => {
      this.spinnerService.hide().then();
      this.snackBar.openSnackBar('Failed to export', 'error');
    });

  }


  deleteBin(id: string): Observable<any>{
   return this.http.delete(`${this.apiUrl}/delete-bin?id=${id}`);
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FilterParameters, RulesConfiguration } from 'src/modules/types/interfaces';
import { applyOnKeys, camelToSnake, snakeToCamel } from '../../tools';
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class RulesConfigurationService {

  apiUrl = environment.apiUrl;
  sub;
  data;
  constructor(private http: HttpClient, private snackBar: SnackBarService,
              private spinnerService: NgxSpinnerService) { }

  fetchRulesConfigurations(parameter: FilterParameters): Observable<RulesConfiguration[]>{
    return  this.http.post(`${this.apiUrl}/rules_configuration`, parameter).pipe(
      map(rules => {
      this.snackBar.openSnackBar('Rules configuration loaded successfully', 'success');
      return applyOnKeys(rules, snakeToCamel);
    }), catchError(error => {
      this.snackBar.openSnackBar('Failed to load rules configuration', 'error');
      return of([]);
    }));
  }


  createRulesConfigurations(parameter): Observable<RulesConfiguration[]>{
    this.spinnerService.show();
    return  this.http.post(`${this.apiUrl}/add_rules_configuration`, applyOnKeys( parameter, camelToSnake)).pipe(
      map(rules => {
      this.spinnerService.hide();
      this.snackBar.openSnackBar('Rules configuration created successfully', 'success');
      return applyOnKeys(rules, snakeToCamel);
    }), catchError(error => {
      this.spinnerService.hide();
      this.snackBar.openSnackBar('Failed to created rule configuration', 'error');
      return of([]);
    }));
  }


  editRulesConfigurations(parameter): Observable<RulesConfiguration[]>{
    this.spinnerService.show();
    return this.http.post(`${this.apiUrl}/add_rules_configuration`, applyOnKeys( parameter, camelToSnake)).pipe(
      map(rules => {
      this.spinnerService.hide();
      this.snackBar.openSnackBar('Rules configuration updated successfully', 'success');
      return applyOnKeys(rules, snakeToCamel);
    }), catchError(error => {
      this.spinnerService.hide();
      this.snackBar.openSnackBar('Failed to update rule configuration', 'error');
      return of([]);
    }));
  }


  deleteRulesConfigurations(parameter): Observable<RulesConfiguration[]>{
    this.spinnerService.show();
    return  this.http.post(`${this.apiUrl}/add_rules_configuration`, applyOnKeys( parameter, camelToSnake)).pipe(
      map(rules => {
        this.spinnerService.hide();
        this.snackBar.openSnackBar('Rules configuration deleted successfully', 'success');
        return applyOnKeys(rules, snakeToCamel);
    }), catchError(error => {
      this.spinnerService.hide();
      this.snackBar.openSnackBar('Failed to delete rule configuration', 'error');
      return of([]);
    }));
  }

  exportRuleConfiguration(parameter: {rulesName: string, status: string, type: string, scoreMin: number,
  scoreMax: any, productFamily: string, supportType: string }): void{
    this.spinnerService.show().then();
    this.http.post(`${this.apiUrl}/export_rules_config`, applyOnKeys(parameter, camelToSnake), {
      responseType: 'json'
    }).subscribe(response => {
      const task_id = JSON.parse(JSON.stringify(response)).task_id;
      this.sub = timer(0, 10000).pipe(
        map(() => {
          this.http.get(`${this.apiUrl}/export_status?task_id=${task_id}`, {
            responseType: 'json'
          }).subscribe((res) => {
            if (res) {
              this.data = JSON.parse(JSON.stringify(res));
              if (this.data.status === 200) {
                const export_csv_url = this.data.export_csv_url;
                window.open(export_csv_url, '_self');
                this.sub.unsubscribe();
                this.spinnerService.hide().then();
              }
            }
          }, (error) => {
            this.spinnerService.hide();
            this.snackBar.openSnackBar('Failed to export', 'error');
            this.sub.unsubscribe();
          });
        })).subscribe();
    }, error => {
      this.spinnerService.hide().then();
    });
  }

}

const isObject = (o) => {
  return o === Object(o) && !Array.isArray(o) && typeof o !== 'function';
};

export const applyOnKeys = (obj, fct) => {
  if (isObject(obj)) {
    const n = {};
    Object.keys(obj)
      .forEach((k: string) => {
        n[fct(k)] = applyOnKeys(obj[k], fct);
      });

    return n;
  } else if (Array.isArray(obj)) {
    return obj.map((i) => {
      return applyOnKeys(i, fct);
    });
  }

  return obj;
};

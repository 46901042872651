import {Action, State, StateContext} from '@ngxs/store';
import {Auth} from '../../types/interfaces/auth';
import {Injectable} from '@angular/core';
import {Login, Logout} from '../actions/auth';

@State<Auth[]>({
  name: 'auth',
  defaults: []
})
@Injectable()
export class AuthState {

  @Action(Login)
  login(ctx: StateContext<Auth>, {payload}: Login): void {
    ctx.setState(payload);
  }


  @Action(Logout)
  logout(ctx: StateContext<Auth>): void {
    ctx.setState({
      token: null,
    });
  }
}

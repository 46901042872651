import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'kyt-landing',
  styleUrls: ['./landing.container.scss'],
  template: `
    <h3 class="welcome">Welcome</h3>

    <section>
      <article>
        <a class="alerts-label" (click)="toAlertsList()">
          <img class="alerts-img"
               src="../../../../assets/images/alert-analysis.png"
               alt="image of the alert analysis button"/>
        </a>
      </article>
      <article>
        <a class="blacklist-label" routerLink="blacklist">
          <img class="alerts-img"
               alt="image of the blacklist analysis button"
               src="../../../../assets/images/black-list.png"/>
        </a>
      </article>
    </section>
  `
})
export class LandingContainer {
  constructor(public router: Router) {
  }

  toAlertsList(): void {
    this.router.navigate(['cards-list']);
    localStorage.clear();
  }
}

import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, of, timer} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {applyOnKeys, snakeToCamel} from '../../tools';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { queryBuilder } from '../../tools/queryBuilder';

@Injectable({
  providedIn: 'root'
})
export class LnForceService {

  apiUrl = environment.apiUrl;
  sub: any;
  data: any;
  constructor(private http: HttpClient, private snackBar: SnackBarService,
              private spinnerService: NgxSpinnerService) { }


  getLnForce({limit, offset, pan, code_complet, start_date, end_date, sort_order}): Observable<any>{
    return this.http.get(`${this.apiUrl}/ln_force_list?offset=${offset}&limit=${limit}&pan=${pan}&code_complet=${code_complet}&start_date=${start_date}&end_date=${end_date}&sort_order=${sort_order}`)
    .pipe(
      map(micaProducts => {
        return applyOnKeys(micaProducts, snakeToCamel);
      }), catchError(error => {
        return of([]);
      })
    );
  }

  fetchPageLength({pan, code_complet, start_date, end_date}): Observable<any>{
    return this.http.get(`${this.apiUrl}/ln_force_paginator?pan=${pan}&code_complet=${code_complet}&start_date=${start_date}&end_date=${end_date}`);
  }


  postLnForce(parameters, id): Observable<any>{
    return this.http.post(`${this.apiUrl}/update_lnforce_indicator?id=${id}`, parameters)
    .pipe(
      map(value => {
        return applyOnKeys(value, snakeToCamel);
      }), catchError(error => {
        return of([]);
      })
    );
  }

  export(filters: {limit, offset, pan, code_complet, start_date, end_date, sort_order} ): void {
    this.spinnerService.show().then();
    this.http.get(`${this.apiUrl}/export_ln_force?${queryBuilder(filters)}`).subscribe(response => {
      const blobName = JSON.parse(JSON.stringify(response)).task_id;
      this.sub = timer(0, 10000).pipe(
        map(() => {
          this.http.get(`${this.apiUrl}/export_status?task_id=${blobName}`, {
            responseType: 'json'
          }).subscribe((res) => {
            if (res) {
              this.data = JSON.parse(JSON.stringify(res));
              if (this.data.status === 200) {
                const export_csv_url = this.data.export_csv_url;
                window.open(export_csv_url, '_self');
                this.sub.unsubscribe();
                this.spinnerService.hide().then();
              }
            }
          }, (error) => {
            this.spinnerService.hide();
            this.snackBar.openSnackBar('Failed to export', 'error');
            this.sub.unsubscribe();
          });
        })).subscribe();
    }, error => {
      this.spinnerService.hide().then();
      this.snackBar.openSnackBar('Failed to export', 'error');
    });

  }

}

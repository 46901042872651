import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'kyt-table',
  template: `
  <section>
   <table mat-table [dataSource]="duplicateData">
      <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
        <th mat-header-cell *matHeaderCellDef> {{getColumnName(column)}} </th>
        <td mat-cell *matCellDef="let element" [innerHTML]="getElement(element[column], column)"></td>
      </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator *ngIf="data && isPaginatorEnabled" [length]="data.length"
                   [pageSize]=5
                   [pageSizeOptions]=[5,10]
                   (page)="onPageChange($event)">
    </mat-paginator>
  <section>
  `,
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnChanges{
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() displayedColumns: string[];
  @Input() data: any;
  @Input() alias: any;
  @Input() isPaginatorEnabled: boolean = true;
  @Input() callBackObjects;
  duplicateData = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data) {
      this.duplicateData = [];
      const limit = this.data.length <= 5 ? this.data.length : 5;
      for (let i = 0; i < limit; i++) {
        this.duplicateData.push(this.data[i]);
      }
    }
  }

getColumnName(columnName: string): string{
  return this.alias[columnName] ? this.alias[columnName] : columnName.replace(/([A-Z])/g, ' $1').toUpperCase().trim();
}

getElement(element: any, column: string): SafeHtml{
  if (this.callBackObjects?.[column]){
      return this.callBackObjects[column](element);
  }else{
    return element;
  }
}

onPageChange(event?: PageEvent): void {
  this.duplicateData = [];
  const limit = this.data.length < (event.pageIndex * event.pageSize) + event.pageSize ?
    this.data.length : (event.pageIndex * event.pageSize) + event.pageSize;
  for (let i = event.pageIndex * event.pageSize; i < limit; i++) {
    this.duplicateData.push(this.data[i]);
  }
}

}

import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { NetworkParameters } from 'src/modules/types/interfaces';
import { FetchBlacklistedNetworkParameters } from '../actions/blacklisted-network-param.action';

@State<NetworkParameters>({
    name: 'blacklistedNetworks',
    defaults: {} as null
})

@Injectable({
    providedIn: 'root'
})

export class BlacklistedNetworkParametersState {
    @Action(FetchBlacklistedNetworkParameters)
    getBlacklistedNetworks(ctx: StateContext<NetworkParameters[]>, {payload}: FetchBlacklistedNetworkParameters): void{
      ctx.setState({...payload});
    }
}

import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import { CognitoConfig } from 'src/modules/types/interfaces';
import Amplify from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public appConfig: CognitoConfig = environment.config;
  private env = environment;
  constructor() { }

  async loadAppConfig() {
      localStorage.setItem('userPoolId', this.appConfig.userPoolId)
      localStorage.setItem('clientId', this.appConfig.clientId)
      localStorage.setItem("suburl", this.appConfig.serviceBase);
      localStorage.setItem("baseurl", this.appConfig.baseURL);
      let cognitoObj = {
        userPoolId: localStorage.getItem('userPoolId'),
        userPoolWebClientId: localStorage.getItem('clientId'),
        mandatorySignIn: true,
        oauth: {
          domain: this.appConfig.domainUrl,
          scope: ['openid', 'email', 'aws.cognito.signin.user.admin'],
          redirectSignIn: this.appConfig.redirectBaseUrl + "home",
          redirectSignOut: this.appConfig.redirectBaseUrl,
          responseType: 'code',
          options: {
            AdvancedSecurityDataCollectionFlag: false
          }
        }
      };

      Amplify.configure({
        Auth: cognitoObj
      });

  }

  getConfig(): CognitoConfig{
    return this.appConfig;
  }

}

import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {AlertInformation} from '../../types/interfaces';
import {FetchAlertDetails} from '../actions/alert-infos.actions';

@State<AlertInformation>({
  name: 'alertDetails',
  defaults: {} as null
})

@Injectable({
  providedIn: 'root'
})
export class AlertsDetailsState {
  @Action(FetchAlertDetails)
  getAlertInformation(ctx: StateContext<AlertInformation>, {payload}: FetchAlertDetails): void {
    ctx.setState({...payload});
  }
}

import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {TransactionsArcyList} from '../../types/interfaces/black-list';
import {FetchTransactionsArcy} from '../actions/transactions-arcy.actions';

@State<TransactionsArcyList[]>({
  name: 'transactionsArcy',
  defaults: []
})

@Injectable({
  providedIn: 'root'
})
export class TransactionsArcyState {
  @Action(FetchTransactionsArcy)
  getTransactionsArcy(ctx: StateContext<TransactionsArcyList[]>, {payload}: FetchTransactionsArcy): void {
    ctx.setState([...payload]);
  }
}

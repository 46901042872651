import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {TransactionsArcyList, TransactionsArcyParams} from '../../../types/interfaces';
import {catchError, map} from 'rxjs/operators';
import {applyOnKeys, snakeToCamel} from '../../tools';
import { SnackBarService } from '../snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionsArcyService {

  apiUrl = environment.apiUrl;
  loadTransctionArcy = true;

  constructor(private http: HttpClient, private snackBar: SnackBarService) {
  }

  fetchTransactionsArcyList(param: TransactionsArcyParams): Observable<TransactionsArcyList[]> {
    return this.http.post<TransactionsArcyList[]>(`${this.apiUrl}/transactions_arcy_list`, param)
      .pipe(
        map(transactionsArcy => {
          this.snackBar.openSnackBar('Transactions arcy list fetched successfully', 'success');
          return applyOnKeys(transactionsArcy, snakeToCamel);
        }), catchError(error => {
          this.snackBar.openSnackBar('Failed to load transactions arcy list', 'error');
          return of([]);
        })
      );
  }

  fetchPageLength(param: TransactionsArcyParams): Observable<number>{
    return this.http.post<number>(`${this.apiUrl}/transactions_arcy_paginator`, param);
  }

}

import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  private config: MatSnackBarConfig;

  constructor(private snackBar: MatSnackBar) {
    this.config = new MatSnackBarConfig();
    this.config.panelClass = ['snack-bar-wrapper'];
    this.config.duration = 3000;
  }

  /**
   * Method: Opens snackBar
   * @param message: Message of snackBar
   * @param status: SnackBar status
   */
  openSnackBar(message: string, status: string): void {
    if (status) {
      this.config.panelClass = ['snack-bar-wrapper', status];
    }
    this.snackBar.open(message, 'x', this.config);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { applyOnKeys, snakeToCamel } from '../../tools';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  apiUrl = environment.apiUrl;
  constructor(private http: HttpClient, private spinnerService: NgxSpinnerService,
              private snackBar: SnackBarService) {
}

getAlertsTreatment(igg: string): Observable<any[]>{
  this.spinnerService.show();
  return this.http.get(`${this.apiUrl}/alerts_treatment?igg=${igg}`).pipe(
    map(alerts => {
      this.spinnerService.hide();
      this.snackBar.openSnackBar('Alerts insights loaded successfully', 'success');
      return applyOnKeys(alerts, snakeToCamel);
    }), catchError(error => {
      this.spinnerService.hide();
      this.snackBar.openSnackBar('Failed to load insights', 'error');
      return of([]);
    })
  );
}

}

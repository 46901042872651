import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Input, EventEmitter, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { AppSandboxService } from 'src/modules/app/app-sandbox.service';
import { Location } from '@angular/common';

interface TreeNode {
  label: string;
  icon: string;
  path: string | null;
  index: number;
  children?: TreeNode[];
}

interface ParametersFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'kyt-side-nav-tree',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
              <!-- This is the tree node template for leaf nodes -->
              <mat-tree-node
                *matTreeNodeDef="let node"
                matTreeNodePadding
                [ngClass]="{'selected-tab': selectedIndex === node.selectedIndex}"
                (click)="tabSelected(node)"
              >
                <!-- use a disabled button to provide padding for tree leaf -->
                <mat-icon>{{ node.icon }}</mat-icon
                >{{ node.name }}
              </mat-tree-node>
              <!-- This is the tree node template for expandable nodes -->
              <mat-tree-node
                *matTreeNodeDef="let node; when: hasChild"
                matTreeNodePadding
                [ngClass]="{
                  'selected-tab': selectedIndex === node.selectedIndex      }"
                (click)="selectedIndex = node.selectedIndex"
                matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name"
              >
                <mat-icon>{{node.icon}}</mat-icon>
                {{ node.name }}
                <div class="btn-toggle-wrapper">
                  <button
                  (click)="selectedIndex = node.selectedIndex"
                  [ngClass]="{
                  'selected-tab': selectedIndex === node.selectedIndex      }"
                    mat-icon-button
                    matTreeNodeToggle
                    [attr.aria-label]="'Toggle ' + node.name"
                  >
                    <mat-icon class="mat-icon-rtl-mirror">
                      {{
                        treeControl.isExpanded(node)
                          ? 'expand_less'
                          : 'expand_more'
                      }}
                    </mat-icon>
                  </button>
                </div>
              </mat-tree-node>
            </mat-tree>
    `,
  styleUrls: ['./side-nav-tree.component.scss']
})
export class SideNavTreeComponent implements OnInit {
  @Input() treeData: TreeNode;
  @Input() selectedIndex: number;

  @Output() tabClicked: EventEmitter<TreeNode> = new EventEmitter();

  user = this.sb.currentUser?.getValue();

  constructor(private sb: AppSandboxService, private location: Location) { }

  ngOnInit(): void {
    this.dataSource.data = [this.treeData];
  }

  treeControl = new FlatTreeControl<ParametersFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  private transformer = (node: TreeNode, level: number) => {
    return {
      expandable: !!node?.children && node?.children.length > 0,
      name: node?.label,
      icon: node?.icon,
      selectedIndex: node?.index,
      path: node?.path,
      level,
    };
  }

  dataSource = new MatTreeFlatDataSource(
    this.treeControl,
    new MatTreeFlattener(
      this.transformer,
      (node) => node.level,
      (node) => node.expandable,
      (node) => node?.children
    )
  );

  hasChild = (_: number, node: ParametersFlatNode) => node.expandable;

  tabSelected(tab: any): void{
    this.selectedIndex = tab.selectedIndex;
    this.tabClicked.emit({icon: tab.icon, index: tab.selectedIndex, label: tab.name, path: tab.path});
  }
}

import {Component} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'kyt-logout',
  template: `
    <p>
      To log off from Digital P@ss you have to quit your browser or go to the Digital P@ss portal:
      <a href="{{logoutUrl}}">Digital P@ss Portal</a>
    </p>
  `,
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {
  logoutUrl: string = environment.logoutUri;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MappingCode } from 'src/modules/types/interfaces';
import { applyOnKeys, snakeToCamel } from '../../tools';
import { SnackBarService } from '../snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class MappingCodeService {
  apiUrl = environment.apiUrl;
  constructor(private http: HttpClient, private snackBar: SnackBarService) { }

  fetchMappingCodes(parameters: MappingCode): Observable<MappingCode[]>{
    return  this.http.get(`${this.apiUrl}/bin_ep_list?offset=${parameters.offset}&limit=${parameters.limit}`).pipe(
      map(value => {
      this.snackBar.openSnackBar('Mapping BIN/EP code loaded successfully', 'success');
      return applyOnKeys(value, snakeToCamel);
    }), catchError(error => {
      this.snackBar.openSnackBar('Failed to load mapping BIN/EP code', 'error');
      return of([]);
    }));
  }

  fetchPageLength(): Observable<any>{
    return this.http.get(`${this.apiUrl}/bin_ep_paginator`);
  }

  postMappingCodes(parameters: MappingCode): Observable<MappingCode[]>{
    return  this.http.post(`${this.apiUrl}/update_bin_ep`, parameters).pipe(
      map(value => {
      this.snackBar.openSnackBar('Mapping BIN/EP code updated successfully', 'success');
      return applyOnKeys(value, snakeToCamel);
    }), catchError(error => {
      this.snackBar.openSnackBar('Failed to update mapping BIN/EP code', 'error');
      return of([]);
    }));
  }

}

import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {ExcludeMicaParams, MicaProductParams} from '../../types/interfaces/configuration';
import {FetchExcludeMicaParamsList} from '../actions/exclude-mica-param.actions';

@State<ExcludeMicaParams[]>({
  name: 'listExcludeMicaParams',
  defaults: []
})

@Injectable({
  providedIn: 'root'
})
export class ExcludeMicaParamsState {
  @Action(FetchExcludeMicaParamsList)
  getAlertsParams(ctx: StateContext<MicaProductParams>, {payload}: FetchExcludeMicaParamsList): void {
    ctx.setState({...payload});
  }
}

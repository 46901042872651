import { DatePipe } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { AppSandboxService } from 'src/modules/app/app-sandbox.service';
import { AudtiFilters } from 'src/modules/types/interfaces/auditability';

@Component({
  selector: 'kyt-auditability-filters',
  template: `
  <mat-accordion>
      <mat-expansion-panel class="mat-elevation-z0" (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                           hideToggle="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <label>Filter</label>
          </mat-panel-title>
          <mat-panel-description class="mat-desc">
            <mat-icon *ngIf="!panelOpenState">add</mat-icon>
            <mat-icon *ngIf="panelOpenState">remove</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="filters">
        <div class="search">
            <mat-form-field class='srchFormF' appearance="outline">
              <mat-label>User</mat-label>
              <input matInput type="text" [(ngModel)]="filter.username" (keydown.enter)="searchByUsername()">
              <mat-icon class="search-icon" (click)="searchByUsername()">search</mat-icon>
            </mat-form-field>
            <mat-form-field class='siteCodeFromF' appearance="outline">
              <mat-label>Card Number</mat-label>
              <input matInput type="text" [(ngModel)]="filter.pcomNumber" (keydown.enter)="searchByPcomNumber()">
              <mat-icon class="search-icon" (click)="searchByPcomNumber()">search</mat-icon>
            </mat-form-field>
        </div>
          <div>
            <mat-form-field color="accent" appearance="outline">
              <mat-label>Alert date</mat-label>
              <mat-date-range-input [rangePicker]="cdPicker" [max]="currentDate$|async">
                <input matStartDate [value]="startDate$|async" (dateChange)="setAlertStartDate($event)">
                <input matEndDate [value]="endDate$|async" (dateChange)="onAlertStartDateChange($event)">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="cdPicker"></mat-datepicker-toggle>
              <mat-date-range-picker #cdPicker></mat-date-range-picker>
            </mat-form-field>
            <span class="btn-container">
            <div class="alert-export">
               <button class="btn" mat-flat-button [matMenuTriggerFor]="exportMenu">
                  <mat-icon>save_alt</mat-icon>
                  Export Data
                  <mat-icon *ngIf="!!showExport" (click)="showExport=false;">keyboard_arrow_down</mat-icon>
                  <mat-icon *ngIf="!showExport" (click)="showExport=true;">keyboard_arrow_up</mat-icon>
                </button>
                <mat-menu #exportMenu="matMenu">
                  <button mat-menu-item (click)="exportAuditDetails(true)">Export with filters</button>
                  <button mat-menu-item (click)="exportAuditDetails(false)">Export the whole data</button>
                </mat-menu>
              </div>
            <button class="btn" mat-flat-button (click)="clearFilters()"> Clear all the filters</button>
            </span>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  `,
  styleUrls: ['./auditability-filters.component.scss']
})
export class AuditabilityFiltersComponent implements OnInit {
  currentDate$: BehaviorSubject<string> = new BehaviorSubject<string>(
    this.datePipe.transform(new Date(), 'yyyy-MM-dd')
  );
  startDate$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  endDate$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  @Input() filter: AudtiFilters = {alertStartDate: null, alertEndDate: null, limit:10,offset:0,pcomNumber:null,username: null};
  @Output() loadFilter = new EventEmitter();
  panelOpenState: boolean;
  showExport = true;
  constructor(private datePipe: DatePipe, private sb: AppSandboxService) { }

  ngOnInit(): void {
    this.getLastSevenDays();
    this.loadFilters();
  }

  loadFilters(): void{
    this.loadFilter.emit();
  }

  searchByPcomNumber(): void{
    this.filter.pcomNumber = this.filter.pcomNumber.trim();
    if ( this.filter.pcomNumber !== ''){
      this.loadFilters();
    }
  }
  searchByUsername(): void{
    this.filter.username = this.filter.username.trim();
    if ( this.filter.username !== ''){
      this.loadFilters();
    }
    }

  setAlertStartDate(date: MatDatepickerInputEvent<unknown>): void{
    this.startDate$.next(this.datePipe.transform(`${date.value}`, 'yyyy-MM-dd'));
    this.filter.alertStartDate = this.startDate$.getValue();
  }

  onAlertStartDateChange(date: MatDatepickerInputEvent<unknown>): void {
    if (date.value) {
      this.endDate$.next(this.datePipe.transform(`${date.value}`, 'yyyy-MM-dd'));
      this.filter.alertEndDate = this.endDate$.getValue();
      this.loadFilters();
    }
  }

  exportAuditDetails(arg: boolean): void {
    if (arg){
      // export with filters
      this.sb.exportAuditDetails(this.filter);
    }else{
      // export without filters
      this.sb.exportAuditDetails({alertStartDate: this.LastSevenDays, alertEndDate: this.currentDate$.getValue(), pcomNumber: null,
                                  username: null, limit: 10, offset: 0});
    }
  }

  getLastSevenDays(): void {
    const currentDate = moment();
    const lastMonth = currentDate.subtract(7, 'days');
    this.endDate$.next(this.currentDate$.getValue());
    this.startDate$.next(lastMonth.format('YYYY-MM-DD'));
    if(this.filter){
      this.filter.alertStartDate = this.startDate$.getValue();
      this.filter.alertEndDate = this.endDate$.getValue();
    }
  }


  public get LastSevenDays(): string {
    const currentDate = moment();
    const lastMonth = currentDate.subtract(7, 'days');
    return lastMonth.format('YYYY-MM-DD');
  }


  clearFilters(): void{
    this.filter.alertStartDate = null;
    this.filter.alertEndDate = null;
    this.filter.limit = 10;
    this.filter.offset = 0;
    this.filter.pcomNumber = null;
    this.filter.username = null;
    this.startDate$.next(null);
    this.endDate$.next(null);
    this.getLastSevenDays();
    this.loadFilters();
  }

}

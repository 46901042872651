import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'kyt-export',
  template: `
                <button class="btn-export" mat-flat-button [matMenuTriggerFor]="exportMenu">
                  <mat-icon>save_alt</mat-icon>
                  Export Data
                  <mat-icon *ngIf="!!showExport" (click)="showExport=false;">keyboard_arrow_down</mat-icon>
                  <mat-icon *ngIf="!showExport" (click)="showExport=true;">keyboard_arrow_up</mat-icon>
                </button>
                <mat-menu #exportMenu="matMenu">
                  <button mat-menu-item (click)="export(true)">Export with filters</button>
                  <button mat-menu-item (click)="export(false)">Export the whole data</button>
                </mat-menu>
  `,
  styleUrls: ['./export.component.scss']
})
export class ExportComponent {
  @Input() isDisabled = false;
  @Output() eventExport: EventEmitter<boolean> = new EventEmitter<boolean>();
  showExport = true;

  export(withFilters: boolean): void{
    this.eventExport.emit(withFilters);
  }

}

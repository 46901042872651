import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, of, timer} from 'rxjs';
import {MicaProductList} from '../../../types/interfaces';
import {catchError, map} from 'rxjs/operators';
import {applyOnKeys, camelToSnake, snakeToCamel} from '../../tools';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from '../snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class MicaProductService {

  apiUrl = environment.apiUrl;
  sub;
  data;

  constructor(private http: HttpClient, private spinnerService: NgxSpinnerService,
              private snackBar: SnackBarService) {
  }

  fetchMicaProductList(offset: number, limit: number, searchKey: string): Observable<MicaProductList[]> {
    return this.http.get<MicaProductList[]>(`${this.apiUrl}/mica-products-list?offset=${offset}&limit=${limit}&search_key=${searchKey}`)
      .pipe(
        map(micaProducts => {
          return applyOnKeys(micaProducts, snakeToCamel);
        }), catchError(error => {
          return of([]);
        })
      );
  }

  fetchNumberOfMicaProducts(searchKey: string): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}/mica-products-paginator?search_key=${searchKey}`);
  }

  editMicaProduct(parameters): Observable<MicaProductList[]>{
    this.spinnerService.show();
    const param = applyOnKeys(parameters, camelToSnake);
    return this.http.put(`${this.apiUrl}/update-mica-product`, param)
      .pipe(
        map(micaProducts => {
          this.spinnerService.hide();
          this.snackBar.openSnackBar('Successfully updated mica product', 'success');
          return applyOnKeys(micaProducts, snakeToCamel);
        }), catchError(error => {
          this.spinnerService.hide();
          this.snackBar.openSnackBar('Failed to update mica product', 'error');
          return of([]);
        })
      );
  }

  createMicaProduct(parameters): Observable<MicaProductList[]>{
    this.spinnerService.show();
    const param = applyOnKeys(parameters, camelToSnake);
    return this.http.post(`${this.apiUrl}/update-mica-product`, param)
      .pipe(
        map(micaProducts => {
          this.spinnerService.hide();
          this.snackBar.openSnackBar('Successfully created mica product', 'success');
          return applyOnKeys(micaProducts, snakeToCamel);
        }), catchError(error => {
          this.spinnerService.hide();
          this.snackBar.openSnackBar('Failed to create mica product', 'error');
          return of([]);
        })
      );
  }

  deleteMicaProduct(id: string): Observable<MicaProductList[]>{
    this.spinnerService.show();
    return this.http.delete(`${this.apiUrl}/update-mica-product?id=${id}`)
      .pipe(
        map(micaProducts => {
          this.spinnerService.hide();
          this.snackBar.openSnackBar('Successfully deleted mica product', 'success');
          return applyOnKeys(micaProducts, snakeToCamel);
        }), catchError(error => {
          this.spinnerService.hide();
          this.snackBar.openSnackBar('Failed to delete mica product', 'error');
          return of([]);
        })
      );
  }

  exportMicaProducts(parameter: {searchKey: string}): void{
    this.spinnerService.show().then();
    this.http.get(`${this.apiUrl}/export_mica_product?search_key=${parameter.searchKey}`, {
      responseType: 'json'
    }).subscribe(response => {
      const task_id = JSON.parse(JSON.stringify(response)).task_id;
      this.sub = timer(0, 10000).pipe(
        map(() => {
          this.http.get(`${this.apiUrl}/export_status?task_id=${task_id}`, {
            responseType: 'json'
          }).subscribe((res) => {
            if (res) {
              this.data = JSON.parse(JSON.stringify(res));
              if (this.data.status === 200) {
                const export_csv_url = this.data.export_csv_url;
                window.open(export_csv_url, '_self');
                this.sub.unsubscribe();
                this.spinnerService.hide().then();
              }
            }
          }, (error) => {
            this.spinnerService.hide();
            this.snackBar.openSnackBar('Failed to export', 'error');
            this.sub.unsubscribe();
          });
        })).subscribe();
    }, error => {
      this.spinnerService.hide().then();
    });
  }

}

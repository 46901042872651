import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppSandboxService } from 'src/modules/app/app-sandbox.service';
import { ActivityLog, User } from 'src/modules/types/interfaces';

@Component({
  selector: 'kyt-previous-roles',
  styleUrls: ['./previous-roles.component.scss'],
  template: `
  <section class="user-container">
  <h2 mat-dialog-title>User Activity Log
          <span><mat-icon [mat-dialog-close]="true">close</mat-icon></span>
  </h2>
    <table *ngIf="logs.length > 0; else noData" mat-table
           [dataSource]="logs"
           class="mat-elevation-z0">
      <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef>Log Date & Time</th>
        <td mat-cell *matCellDef="let element"> {{element.timestamp | date:'MMM dd yyyy, HH:mm'}} </td>
      </ng-container>

      <ng-container matColumnDef="currentRole">
        <th mat-header-cell *matHeaderCellDef>Current Role</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'changed': element.comparator.includes('currentRole')}"> {{element.currentRole }} </td>
      </ng-container>

      <ng-container matColumnDef="affiliate">
        <th mat-header-cell *matHeaderCellDef>Affiliate</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'changed': element.comparator.includes('affiliate')}"> {{element.currentUserAffiliate}} </td>
      </ng-container>

      <ng-container matColumnDef="modifiedByUser">
        <th mat-header-cell *matHeaderCellDef>Modified By User</th>
        <td mat-cell *matCellDef="let element"> {{element.modifiedByUser}} </td>
      </ng-container>

      <ng-container matColumnDef="deactivated">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'changed': element.comparator.includes('deactivated')}"> {{element.deactivated ? 'Deactivated' : 'Activated' }} </td>
      </ng-container>

      <ng-container matColumnDef="emailSubscribed">
        <th mat-header-cell *matHeaderCellDef>Email Status</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'changed': element.comparator.includes('emailSubscribed')}"> {{element.emailSubscribed ? 'Subscribed' : 'Unsubscribed' }} </td>
      </ng-container>

      <ng-container matColumnDef="previousRole">
        <th mat-header-cell *matHeaderCellDef>Previous Role</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'changed': element.comparator.includes('previousRole')}"> {{element.previousRole}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <ng-template #noData>
      <div class="no-data">
        <p>No recent activity found!</p>
      </div>
    </ng-template>
  </section>
  `,
})
export class PreviousRolesComponent implements OnInit {

  logs = [];
  displayedColumns: string[] = [
    'timestamp',
    'previousRole',
    'currentRole',
    'affiliate',
    'emailSubscribed',
    'deactivated',
    'modifiedByUser'
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: Partial<User>, private dialogRef: MatDialogRef<PreviousRolesComponent>,
              private sb: AppSandboxService) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.sortData(this.data.activityLog);
  }

  sortData(logsHistory: ActivityLog[]): void {
    if (logsHistory?.length > 0) {
      if ((logsHistory[0].currentRole === logsHistory[0].previousRole
          || logsHistory[0].previousRole === '-' ) && this.data.dateOfCreation !== ''){
        const userCreationLog = Object.assign({}, logsHistory[0]);
        userCreationLog.previousRole = '-';
        this.logs[0] = {...userCreationLog, comparator: []};
      }
      for (let index = 1; index < logsHistory.length; index++) {
        const comparator = this.activityComparator(logsHistory[index - 1], logsHistory[index]);
        this.logs.push({...logsHistory[index], comparator});
      }
      this.logs.reverse();
    }
  }

  activityComparator(current: any, previous: any): string[]{
    const keys = Object.keys(current);
    return keys.filter(key => current[key] !== previous[key]);
  }

}

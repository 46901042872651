import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { applyOnKeys, camelToSnake, snakeToCamel } from '../../tools';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { AlertStatus } from 'src/modules/types/interfaces/alert';

@Injectable({
  providedIn: 'root'
})
export class AlertsFollowUpService {

  apiUrl = environment.apiUrl;
  data;
  sub;
  paginatorList = [];
  constructor(private http: HttpClient, private spinnerService: NgxSpinnerService, private snackBar: SnackBarService) { }

  getAlertsFollowUp({ limit, offset, startDate, endDate, oppoStartDate,
    oppoEndDate, pcomNumber, searchKey, affiliate, alertType, alertStatus,alertSource,
    statusAffiliate, productFamily, sortKey, sortOrder, priority,
    actionAffiliate, canalContact, fraudType, cardStatus, affiliateCommentStartDate,
    affiliateCommentEndDate, minAmount, maxAmount, igg }): Observable<any> {
    return this.http.get(`${this.apiUrl}/alerts-follow-up?offset=${offset}&limit=${limit}&start_date=${startDate}&end_date=${endDate}&oppo_start_date=${oppoStartDate}&oppo_end_date=${oppoEndDate}&pcom_number=${pcomNumber}&search_key=${searchKey}&affiliate=${affiliate}&alert_type=${alertType}&alert_status=${alertStatus}&status_affiliate=${statusAffiliate}&product_family=${productFamily}&sort_key=${sortKey}&sort_order=${sortOrder}&priority=${priority}&container=cards&action_affiliate=${actionAffiliate}&canal_contact=${canalContact}&fraud_type=${JSON.stringify(fraudType)}&pcom_status_ref_label_en=${cardStatus}&alert_source=${alertSource}&affiliate_comment_start_date=${affiliateCommentStartDate}&affiliate_comment_end_date=${affiliateCommentEndDate}&min_amount=${minAmount}&max_amount=${maxAmount}&igg=${igg}`).pipe(
      map(alertDetails => applyOnKeys(alertDetails, snakeToCamel))
    );
  }

  getAlertsFollowUpPaginator({ limit, offset, startDate, endDate, oppoStartDate, oppoEndDate,
    pcomNumber, searchKey, affiliate, alertType, alertStatus, statusAffiliate, affiliateCommentStartDate,
    affiliateCommentEndDate, productFamily, priority, actionAffiliate, canalContact, alertSource,
    fraudType, cardStatus, minAmount, maxAmount, igg}): Observable<any> {
    return this.http.get(`${this.apiUrl}/alerts-follow-up-paginator?start_date=${startDate}&end_date=${endDate}&oppo_start_date=${oppoStartDate}&oppo_end_date=${oppoEndDate}&pcom_number=${pcomNumber}&search_key=${searchKey}&affiliate=${affiliate}&alert_type=${alertType}&alert_status=${alertStatus}&status_affiliate=${statusAffiliate}&product_family=${productFamily}&priority=${priority}&container=cards&action_affiliate=${actionAffiliate}&canal_contact=${canalContact}&fraud_type=${JSON.stringify(fraudType)}&pcom_status_ref_label_en=${cardStatus}&alert_source=${alertSource}&affiliate_comment_start_date=${affiliateCommentStartDate}&affiliate_comment_end_date=${affiliateCommentEndDate}&min_amount=${minAmount}&max_amount=${maxAmount}&igg=${igg}`);
  }

  postAlertsFollowUp(parameters: any, alertUid: string, filter: any): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.post(`${this.apiUrl}/update_follow_up_details?alert-uid=${alertUid}&container=cards`, applyOnKeys({ ...parameters, param: filter }, camelToSnake)).pipe(
      map(alertDetails => applyOnKeys(alertDetails, snakeToCamel)));
  }



  getBadgeFollowUp({ limit, offset, startDate, endDate, oppoStartDate,
    oppoEndDate, pcomNumber, searchKey, affiliate, alertType, alertStatus,
    statusAffiliate, productFamily, sortKey, sortOrder, priority, actionAffiliate,
    canalContact, fraudType, cardStatus, affiliateCommentStartDate,
    affiliateCommentEndDate, minAmount, maxAmount}): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${this.apiUrl}/alerts-follow-up?offset=${offset}&limit=${limit}&start_date=${startDate}&end_date=${endDate}&oppo_start_date=${oppoStartDate}&oppo_end_date=${oppoEndDate}&pcom_number=${pcomNumber}&search_key=${searchKey}&affiliate=${affiliate}&alert_type=${alertType}&alert_status=${alertStatus}&status_affiliate=${statusAffiliate}&product_family=${productFamily}&sort_key=${sortKey}&sort_order=${sortOrder}&priority=${priority}&container=badges&action_affiliate=${actionAffiliate}&canal_contact=${canalContact}&fraud_type=${JSON.stringify(fraudType)}&pcom_status_ref_label_en=${cardStatus}&affiliate_comment_start_date=${affiliateCommentStartDate}&affiliate_comment_end_date=${affiliateCommentEndDate}&min_amount=${minAmount}&max_amount=${maxAmount}`).pipe(
      map(alertDetails => applyOnKeys(alertDetails, snakeToCamel))
    );
  }

  getBadgeFollowUpPaginator({ limit, offset, startDate, endDate, oppoStartDate,
    oppoEndDate, pcomNumber, searchKey, affiliate,
    alertType, alertStatus, statusAffiliate, productFamily,
    priority, actionAffiliate, canalContact, fraudType, cardStatus,
    affiliateCommentStartDate, affiliateCommentEndDate, minAmount, maxAmount }): Observable<any> {
    return this.http.get(`${this.apiUrl}/alerts-follow-up-paginator?start_date=${startDate}&end_date=${endDate}&oppo_start_date=${oppoStartDate}&oppo_end_date=${oppoEndDate}&pcom_number=${pcomNumber}&search_key=${searchKey}&affiliate=${affiliate}&alert_type=${alertType}&alert_status=${alertStatus}&status_affiliate=${statusAffiliate}&product_family=${productFamily}&priority=${priority}&container=badges&action_affiliate=${actionAffiliate}&canal_contact=${canalContact}&fraud_type=${JSON.stringify(fraudType)}&pcom_status_ref_label_en=${cardStatus}&affiliate_comment_start_date=${affiliateCommentStartDate}&affiliate_comment_end_date=${affiliateCommentEndDate}&min_amount=${minAmount}&max_amount=${maxAmount}`);
  }


  postBadgeFollowUp(parameters: any, alertUid: string, filters: any): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.post(`${this.apiUrl}/update_follow_up_details?alert-uid=${alertUid}&container=badges`, applyOnKeys({ ...parameters, param: filters }, camelToSnake)).pipe(
      map(alertDetails => applyOnKeys(alertDetails, snakeToCamel)));
  }

  downloadCards({ startDate, endDate, oppoStartDate, oppoEndDate,
    pcomNumber, searchKey, affiliate, alertType, alertStatus, alertSource,
    statusAffiliate, productFamily, priority, actionAffiliate,
    canalContact, fraudType, cardStatus,
    affiliateCommentStartDate, affiliateCommentEndDate, minAmount, maxAmount, igg }): void {
    this.spinnerService.show().then();
    this.http.get(`${this.apiUrl}/export_alerts_follow_up?start_date=${startDate}&end_date=${endDate}&oppo_start_date=${oppoStartDate}&oppo_end_date=${oppoEndDate}&pcom_number=${pcomNumber}&search_key=${searchKey}&affiliate=${affiliate}&alert_type=${alertType}&alert_status=${alertStatus}&alert_source=${alertSource}&status_affiliate=${statusAffiliate}&product_family=${productFamily}&priority=${priority}&action_affiliate=${actionAffiliate}&canal_contact=${canalContact}&fraud_type=${JSON.stringify(fraudType)}&pcom_status_ref_label_en=${cardStatus}&affiliate_comment_start_date=${affiliateCommentStartDate}&affiliate_comment_end_date=${affiliateCommentEndDate}&min_amount=${minAmount}&max_amount=${maxAmount}&igg=${igg}&container=cards`, {
      responseType: 'json'
    }
    ).subscribe(response => {
      const task_id = JSON.parse(JSON.stringify(response)).task_id;
      const blobName = JSON.parse(JSON.stringify(response)).blob_name;
      this.sub = timer(0, 10000).pipe(
        map(() => {
          this.http.get(`${this.apiUrl}/export_alerts_follow_up_status?task_id=${task_id}&blob_name=${blobName}`, {
            responseType: 'json'
          }
          ).subscribe((res) => {
            if (res) {
              this.data = JSON.parse(JSON.stringify(res));
              if (this.data.status === 200) {
                const export_csv_url = this.data.export_csv_url;
                window.open(export_csv_url, '_self');
                this.sub.unsubscribe();
                this.spinnerService.hide().then();
              }
            }
          });
        })
      ).subscribe();
    }, error => {
      this.spinnerService.hide().then();
    });
  }

  downloadBadge({ startDate, endDate, oppoStartDate,
    oppoEndDate, pcomNumber, searchKey, affiliate,
    alertType, alertStatus, statusAffiliate, productFamily,
    priority, actionAffiliate, canalContact, fraudType, cardStatus,
    affiliateCommentStartDate, affiliateCommentEndDate, minAmount, maxAmount }): void {
    this.spinnerService.show().then();
    this.http.get(`${this.apiUrl}/export_alerts_follow_up?start_date=${startDate}&end_date=${endDate}&oppo_start_date=${oppoStartDate}&oppo_end_date=${oppoEndDate}&pcom_number=${pcomNumber}&search_key=${searchKey}&affiliate=${affiliate}&alert_type=${alertType}&alert_status=${alertStatus}&status_affiliate=${statusAffiliate}&product_family=${productFamily}&priority=${priority}&action_affiliate=${actionAffiliate}&canal_contact=${canalContact}&fraud_type=${JSON.stringify(fraudType)}&pcom_status_ref_label_en=${cardStatus}&&affiliate_comment_start_date=${affiliateCommentStartDate}&affiliate_comment_end_date=${affiliateCommentEndDate}&min_amount=${minAmount}&max_amount=${maxAmount}&container=badges`, {
      responseType: 'json'
    }
    ).subscribe(response => {
      const task_id = JSON.parse(JSON.stringify(response)).task_id;
      const blobName = JSON.parse(JSON.stringify(response)).blob_name;
      this.sub = timer(0, 10000).pipe(
        map(() => {
          this.http.get(`${this.apiUrl}/export_alerts_follow_up_status?task_id=${task_id}&blob_name=${blobName}`, {
            responseType: 'json'
          }
          ).subscribe((res) => {
            if (res) {
              this.data = JSON.parse(JSON.stringify(res));
              if (this.data.status === 200) {
                const export_csv_url = this.data.export_csv_url;
                window.open(export_csv_url, '_self');
                this.sub.unsubscribe();
                this.spinnerService.hide().then();
              }
            }
          });
        })
      ).subscribe();
    }, error => {
      this.spinnerService.hide().then();
    });
  }

  getClientsPrecision({ offset, limit, pcomNumber, search }): Observable<any> {
    return this.http.get(`${this.apiUrl}/client_precision?offset=${offset}&limit=${limit}&search=${search}&pcom_number=${pcomNumber}`).pipe(
      map(precisionDetails => applyOnKeys(precisionDetails, snakeToCamel)),
      catchError(error => {
        this.snackBar.openSnackBar('Failed to load Client Precision', 'error');
        return of([]);
      }));
  }

  getClientsPrecisionPaginator({ pcomNumber, search }): Observable<any> {
    return this.http.get(`${this.apiUrl}/client_precision_paginator?&search=${search}&pcom_number=${pcomNumber}`).pipe(
      map(precisionDetails => applyOnKeys(precisionDetails, snakeToCamel))
    );
  }

  postClientPrecisionDetails(parameters): Observable<any> {
    return this.http.post(`${this.apiUrl}/update_client_precision`, applyOnKeys(parameters, camelToSnake)).pipe(
      map(precisionDetails => applyOnKeys(precisionDetails, snakeToCamel)),
      catchError(error => {
        this.snackBar.openSnackBar('Failed to update Client Precision Details', 'error');
        return of([]);
      }));
  }

  updateAlertStatus(alertUid: string, statusObj: AlertStatus, container: string, filter: any): Observable<{ error: string }> {
    if (statusObj.alertStatus === 'New' || statusObj.alertStatus === 'New (PA)') {
      this.paginatorList.forEach((e): any => {
        e.data += 1;
      });
    }
    else {
      this.paginatorList.forEach((e) => {
        e.data -= 1;
      });
    }

    localStorage.setItem('paginatorList', JSON.stringify(this.paginatorList));
    // tslint:disable-next-line:max-line-length
    return this.http.post<{ error: string }>(`${this.apiUrl}/update-alert-status?alert=${alertUid}&container=${container}`, JSON.stringify(applyOnKeys({ ...statusObj, param: filter }, camelToSnake))).pipe(
      map((status: { error: string }) => {
        return applyOnKeys(status, snakeToCamel);
      })
    );
  }

  sendMail(container: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/email_notification?container=${container}`).pipe(
      map(res => applyOnKeys(res, snakeToCamel)),
      catchError(error => {
        return of({ error: 'Failed to send email' });
      }));
  }

  updateAffiliateComment(alertId: string, param: { userName: string, comment: string, igg: string, param: any },
                         container: 'cards' | 'badges'): Observable<any> {
    this.spinnerService.show();
    return this.http.post(`${this.apiUrl}/update_affiliate_comment?alert=${alertId}&container=${container}`,
                           applyOnKeys(param, camelToSnake)).pipe(
      map(response =>
       {
         this.spinnerService.hide();
         this.snackBar.openSnackBar('Comment updated successfully', 'success');
         return applyOnKeys(response, snakeToCamel);
       }
      ),
      catchError(error => {
        this.spinnerService.hide();
        this.snackBar.openSnackBar('Failed to update comment', 'error');
        return of([]);
      }));
  }

}


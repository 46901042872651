import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {ListUsersParams} from '../../types/interfaces';
import {FetchUserParamsList} from '../actions/users-param.action';

@State<ListUsersParams[]>({
  name: 'listUsersParams',
  defaults: []
})

@Injectable({
  providedIn: 'root'
})
export class UserParamsState {
  @Action(FetchUserParamsList)
  getAlertsParams(ctx: StateContext<ListUsersParams>, {payload}: FetchUserParamsList): void {
    ctx.setState({...payload});
  }
}

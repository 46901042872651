import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {MicaProductParams} from '../../types/interfaces/configuration';
import {FetchMicaProductParamsList} from '../actions/mica-product-param-actions';

@State<MicaProductParams[]>({
  name: 'listMicaProductParams',
  defaults: []
})

@Injectable({
  providedIn: 'root'
})
export class MicaProductParamsState {
  @Action(FetchMicaProductParamsList)
  getAlertsParams(ctx: StateContext<MicaProductParams>, {payload}: FetchMicaProductParamsList): void {
    ctx.setState({...payload});
  }
}

import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {Alert} from '../../types/interfaces';
import {FetchAlerts} from '../actions/alerts.actions';

@State<Alert[]>({
  name: 'alerts',
  defaults: []
})

@Injectable({
  providedIn: 'root'
})
export class AlertsState {
  @Action(FetchAlerts)
  getAlerts(ctx: StateContext<Alert[]>, {payload}: FetchAlerts): void {
    ctx.setState([...payload]);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { applyOnKeys, snakeToCamel } from '../tools';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from './snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class ParameterModelsSaturationService {

  apiUrl = environment.apiUrl;
  data;
  sub;
  constructor(private http: HttpClient, private snackBar: SnackBarService,
              private spinnerService: NgxSpinnerService) { }

  getParameterDetails(): Observable<any>{
    return this.http.get(`${this.apiUrl}/model_parameters`).pipe(
      map(value => {
        return applyOnKeys(value, snakeToCamel);
      })
    );
  }

  postParameterSaturation(parameters: any): Observable<any>{
    return this.http.post(`${this.apiUrl}/update_model_parameters`, parameters).pipe(
      map(value => {
        return applyOnKeys(value, snakeToCamel);
      })
    );
  }

  exportParameterSaturation(): void {
    this.spinnerService.show().then();
    this.http.get(`${this.apiUrl}/export_model_parameters`).subscribe(response => {
      const blob_name = JSON.parse(JSON.stringify(response)).blob_name;
      this.sub = timer(0, 10000).pipe(
        map(() => {
          this.http.get(`${this.apiUrl}/export_status?task_id=${blob_name}`, {
            responseType: 'json'
          }).subscribe((res) => {
            if (res) {
              this.data = JSON.parse(JSON.stringify(res));
              if (this.data.status === 200) {
                const export_csv_url = this.data.export_csv_url;
                window.open(export_csv_url, '_self');
                this.sub.unsubscribe();
                this.spinnerService.hide().then();
              }
            }
          }, (error) => {
            this.spinnerService.hide();
            this.snackBar.openSnackBar('Failed to export', 'error');
            this.sub.unsubscribe();
          });
        })).subscribe();
    }, error => {
      this.spinnerService.hide().then();
      this.snackBar.openSnackBar('Failed to export', 'error');
    });

  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, timer } from 'rxjs';
import { Alert, AlertFilters, AlertStatus, ListAlertsParams } from '../../../types/interfaces';
import { environment } from '../../../../environments/environment';
import { applyOnKeys, snakeToCamel, camelToSnake } from '../../tools';
import { map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  apiUrl = environment.apiUrl;
  paginatorList = [];
  alertStatusChangeCounter = 0;
  data;
  sub;

  constructor(private http: HttpClient, private spinnerService: NgxSpinnerService) {
  }

  fetchAlertsList(arg: AlertFilters): Observable<Alert[]> {
    return this.http.get<Alert[]>(`${this.apiUrl}/list-cards?start_date=${arg.startDate}&end_date=${arg.endDate}&type=${arg.alertType}&product_family_code=${arg.productFamily}&alert_source=${arg.alertSource}&country=${arg.issuer}&status=${arg.status}&status_affiliate=${arg.statusAffiliate}&offset=${arg.offset}` +
      `&limit=${arg.limit}&sort_key=${arg.sortKey}&sort_order=${arg.sortOrder}&search_key=${arg.searchKey}&search_site_code=${arg.searchSiteCode}&pcom_number=${arg.pcomNumber}&min_score=${arg.minScore}&max_score=${arg.maxScore}&min_amount=${arg.minAmt}&max_amount=${arg.maxAmt}&min_ml_score=${arg.minMlScore}&max_ml_score=${arg.maxMlScore}`+
      `&min_transactions=${arg.minTransactions}&max_transactions=${arg.maxTransactions}&min_rules=${arg.minRules}&max_rules=${arg.maxRules}`)
      .pipe(
        map(alerts => {
          return applyOnKeys(alerts, snakeToCamel);
        })
      );
  }

  fetchNumberOfAlerts(parameters: AlertFilters): Observable<number> {
    const url = `${this.apiUrl}/paginator?start_date=${parameters.startDate}&end_date=${parameters.endDate}&type=${parameters.alertType}&product_family_code=${parameters.productFamily}&alert_source=${parameters.alertSource}` +
      `&country=${parameters.issuer}&status=${parameters.status}&status_affiliate=${parameters.statusAffiliate}&search_key=${parameters.searchKey}&search_site_code=${parameters.searchSiteCode}&pcom_number=${parameters.pcomNumber}`+
      `&min_score=${parameters.minScore}&max_score=${parameters.maxScore}&min_amount=${parameters.minAmt}&max_amount=${parameters.maxAmt}&min_ml_score=${parameters.minMlScore}&max_ml_score=${parameters.maxMlScore}`+
      `&min_transactions=${parameters.minTransactions}&max_transactions=${parameters.maxTransactions}&min_rules=${parameters.minRules}&max_rules=${parameters.maxRules}`;
    return this.http.get<number>(url);
  }

  updateAlertStatus(alertUid: string, statusObj: AlertStatus, container: string): Observable<{ error: string }> {
    if (statusObj.alertStatus === 'New' || statusObj.alertStatus === 'New (PA)') {
      this.paginatorList.forEach((e): any => {
        e.data += 1;
      });
    }
    else {
      this.paginatorList.forEach((e) => {
        e.data -= 1;
      });
    }

    localStorage.setItem('paginatorList', JSON.stringify(this.paginatorList));
    // tslint:disable-next-line:max-line-length
    return this.http.post<{ error: string }>(`${this.apiUrl}/update-alert-status?alert=${alertUid}&container=${container}`, applyOnKeys(statusObj, camelToSnake)).pipe(
        map((status: { error: string }) => {
          return applyOnKeys(status, snakeToCamel);
        })
      );
  }


  updateStatusAffiliate(alertUid: string, parameters: any, container: string): Observable<{ error: string }> {
    // tslint:disable-next-line:max-line-length
    return this.http.post<{ error: string }>(`${this.apiUrl}/update-status-affiliate?alert=${alertUid}&container=${container}`, applyOnKeys(parameters, camelToSnake)).pipe(
        map((status: { error: string }) => {
          return applyOnKeys(status, snakeToCamel);
        })
      );
  }

  // Badge Alerts
  fetchBadgeAlerts(parameters: ListAlertsParams): Observable<Alert[]> {
    return this.http.get<Alert[]>(`${this.apiUrl}/list_badge?start_date=${parameters.startDate}&end_date=${parameters.endDate}&type=${parameters.alertType}&product_family_code=${parameters.productFamily}&country=${parameters.issuer}&status=${parameters.status}&status_affiliate=${parameters.statusAffiliate}&offset=${parameters.offset}` +
      `&limit=${parameters.limit}&sort_key=${parameters.sortKey}&sort_order=${parameters.sortOrder}&search_key=${parameters.searchKey}&search_site_code=${parameters.searchSiteCode}&pcom_number=${parameters.pcomNumber}&min_score=${parameters.minScore}&max_score=${parameters.maxScore}&min_amount=${parameters.minAmt}&max_amount=${parameters.maxAmt}`)
      .pipe(
        map(alerts => {
          return applyOnKeys(alerts, snakeToCamel);
        })
      );
  }

  fetchNumberOfBadgeAlerts(parameters: ListAlertsParams): Observable<number> {
    const url = `${this.apiUrl}/list_badge_paginator?start_date=${parameters.startDate}&end_date=${parameters.endDate}&type=${parameters.alertType}&product_family_code=${parameters.productFamily}` +
      `&country=${parameters.issuer}&status=${parameters.status}&status_affiliate=${parameters.statusAffiliate}&search_key=${parameters.searchKey}&search_site_code=${parameters.searchSiteCode}&pcom_number=${parameters.pcomNumber}&min_score=${parameters.minScore}&max_score=${parameters.maxScore}&min_amount=${parameters.minAmt}&max_amount=${parameters.maxAmt}`;

    return this.http.get<number>(url);
  }

  exportBadge(startDate: string, endDate: string, country: string, status: string, statusAffiliate: string, type: string,
              productFamily: string, searchKey: string, searchSiteCode: string, pcomNumber: string, minScore: number, maxScore: number,
              minAmt: number, maxAmt: number): void {
    this.spinnerService.show().then();
    country = country === ' ' ? 'all' : country;
    type = type === ' ' ? 'all' : type;
    productFamily = productFamily === ' ' ? 'all' : productFamily;
    searchKey = searchKey ? searchKey : 'all';
    searchSiteCode = searchSiteCode ? searchSiteCode : 'all';
    pcomNumber = pcomNumber ? pcomNumber : '';
    minScore = minScore ? minScore : 0;
    maxScore = maxScore ? maxScore : 0;
    minAmt = minAmt ? minAmt : 0;
    maxAmt = maxAmt ? maxAmt : 0;
    this.http.get(`${this.apiUrl}/export_badges?start_date=${startDate}&end_date=${endDate}&country=${country}&status=${status}&status_affiliate=${statusAffiliate}&type=${type}&product_family_code=${productFamily}&search_key=${searchKey}&search_site_code=${searchSiteCode}&pcom_number=${pcomNumber}&min_score=${minScore}` +
      `&max_score=${maxScore}&min_amount=${minAmt}&max_amount=${maxAmt}`, {
      responseType: 'json'
    }
    ).subscribe(response => {
      const task_id = JSON.parse(JSON.stringify(response)).task_id;

      this.sub = timer(0, 10000).pipe(
        map(() => {
          this.http.get(`${this.apiUrl}/export_badges_status?task_id=${task_id}`, {
            responseType: 'json'
          }
          ).subscribe((res) => {
            if (res) {
              this.data = JSON.parse(JSON.stringify(res));
              if (this.data.status === 200) {
                const export_csv_url = this.data.export_csv_url;
                window.open(export_csv_url, '_self');
                this.sub.unsubscribe();
                this.spinnerService.hide().then();
              }
            }
          });
        })
      ).subscribe();
    }, error => {
      this.spinnerService.hide().then();
    });
  }

}

 // BEGIN-NOSCAN
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './helpers/auth.guard';
import {LandingContainer} from './containers/landing/landing.container';
import {LogoutComponent} from './components/logout/logout.component';
import {AdminComponent} from './containers/admin/admin.component';
import { HomepageComponent } from './containers/homepage/homepage.component';
import { AuditabilityComponent } from './containers/auditability/auditability.component';

const routes: Routes = [
  {
    path: 'landing',
    component: LandingContainer,
    canActivate: [AuthGuard],
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'kyt',
    loadChildren: () => import('../app/containers/kyt/kyt.module').then((module) => module.KytModule) ,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'black-list',
    loadChildren: () => import('../app/containers/black-list/blacklist.module').then((module) => module.BlacklistModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'administration',
    component: AdminComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'auditability',
    component: AuditabilityComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'configuration',
    loadChildren: () => import('../app/containers/configuration/configuration.module').then((module) => module.ConfigurationModule) ,
    canActivate: [AuthGuard],
  },
  {
    path: 'alerts-follow-up',
    loadChildren: () => import('../app/containers/alerts-follow-up/follow-up.module').then((module) => module.FollowUpModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'home',
    component: HomepageComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'login',
  //   component: LoginContainer
  // }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
 // END-NOSCAN
import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {AlertFilters, ListAlertsParams} from '../../types/interfaces';
import {FetchParamsList} from '../actions/list-params.actions';

@State<ListAlertsParams[]>({
  name: 'listAlertsParams',
  defaults: []
})

@Injectable({
  providedIn: 'root'
})
export class ListParamsState {
  @Action(FetchParamsList)
  getAlertsParams(ctx: StateContext<AlertFilters>, {payload}: FetchParamsList): void {
    ctx.setState({...payload});
  }
}

import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {FetchExcludeMicaPageLength} from '../actions/exclude-mica-paginator.actions';

@State<number[]>({
  name: 'excludeMicaPaginator',
  defaults: []
})

@Injectable({
  providedIn: 'root'
})
export class ExcludeMicaPaginatorState {
  @Action(FetchExcludeMicaPageLength)
  getPageLength(ctx: StateContext<number>, {payload}: FetchExcludeMicaPageLength): void {
    ctx.setState(payload);
  }
}

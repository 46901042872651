import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {ExcludeMicaList} from '../../types/interfaces/configuration';
import {FetchExcludeMica} from '../actions/exclude-mica.actions';

@State<ExcludeMicaList[]>({
  name: 'excludeMica',
  defaults: []
})

@Injectable({
  providedIn: 'root'
})
export class ExcludeMicaState {
  @Action(FetchExcludeMica)
  getExcludeMica(ctx: StateContext<ExcludeMicaList[]>, {payload}: FetchExcludeMica): void {
    ctx.setState([...payload]);
  }
}

import { Component, Input } from '@angular/core';
import { AlertsFollowUpService } from '../../services/alerts-follow-up/alerts-follow-up.service';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { AlertDetailsComponent } from './alert-details/alert-details.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'kyt-send-mail',
  template: `
    <div class="spinner-wrapper">
    <button [disabled]="enableSpinner" mat-flat-button  (click)="sendMail()">
    <mat-icon> email</mat-icon>
        Send Mail
    </button>
        <mat-spinner *ngIf="enableSpinner" diameter="30"></mat-spinner>
    </div>
  `,
  styleUrls: ['./send-mail.component.scss']
})
export class SendMailComponent {
  enableSpinner: boolean;
  @Input() container: string;
  constructor(private alertFollowupService: AlertsFollowUpService, private snackBar: SnackBarService,
              private matDialog: MatDialog) { }

  sendMail(): void{
    this.enableSpinner = true;
    this.alertFollowupService.sendMail(this.container).subscribe((e) => {
      if (e?.success){
        this.matDialog.open(AlertDetailsComponent, { data: e, width: '14vw' } );
        this.snackBar.openSnackBar(e?.success, 'success');
      }else{
        this.snackBar.openSnackBar(e?.error, 'error');
      }
      this.enableSpinner = false;
    }, );
  }

}

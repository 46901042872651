import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {FetchNetworkCodePageLength} from '../actions/network-code-paginator.actions';

@State<number[]>({
  name: 'NetworkCodePaginator',
  defaults: []
})

@Injectable({
  providedIn: 'root'
})
export class NetworkCodePaginatorState {
  @Action(FetchNetworkCodePageLength)
  getPageLength(ctx: StateContext<number>, {payload}: FetchNetworkCodePageLength): void {
    ctx.setState(payload);
  }
}

import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ListUsersParams, User } from '../../../types/interfaces';
import { AppSandboxService } from '../../app-sandbox.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateUserComponent } from './create-user/create-user.component';

@Component({
  selector: 'kyt-admin',
  styleUrls: ['./admin.component.scss'],
  template: `
    <div class="title-wrapper">
      <span class="title"><h1>Manage Users</h1></span>
      <span class="add-btn">
        <button (click)="createNewUser()" mat-flat-button>
          <mat-icon>add</mat-icon>
          Add user
      </button></span>
    </div>
    <kyt-users-filter [listOfAffiliates]="listOfAffiliates" (export)="export($event.isFilterApplied)" (loadFilter)="loadFilters($event)"></kyt-users-filter>
    <kyt-users-table [listOfAffiliates]="listOfAffiliates" (listUsersSort)="updateUsersList($event)" (pageChange)="pageChange($event)" [resetPageIndex]="resetPageIndex.asObservable()"></kyt-users-table>

    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
      <p>Data is loading, please wait. </p>
    </ngx-spinner>
  `
})
export class AdminComponent implements OnInit {
  listOfAffiliates = ['TF', 'TK', 'E4', 'ALL'];
  usersList$: Observable<User[]> = this.sb.usersList$;
  pageLength$: Observable<number> = this.sb.userPageLength$;
  resetPageIndex: Subject<void> = new Subject<void>();
  parameters: ListUsersParams = {
    offset: 0,
    limit: 10,
    sortKey: '',
    sortOrder: '',
    searchKey: 'all',
    role: 'all',
    status: 'all',
    userAffiliate: [],
    isExternal: null
  };

  constructor(private sb: AppSandboxService, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.loadUsers();
    this.sb.eventEmitterObservalble?.subscribe(() => {
      this.loadUsers();
    });
  }

  loadUsers(): void{
    this.sb.listUsers(this.parameters);
    this.sb.numberOfUsers(this.parameters);
  }

  createNewUser(): void {
    this.dialog.open(CreateUserComponent, {data: {listOfAffiliates: this.listOfAffiliates}});
  }

  updateUsersList({sortKey, sortOrder}): void {
    this.parameters.sortKey = sortKey;
    this.parameters.sortOrder = sortOrder;
    this.parameters.offset = 0;
    this.loadUsers();
  }

  pageChange({limit, offset}): void {
    this.parameters.limit = limit;
    this.parameters.offset = offset;
    this.loadUsers();
  }

  loadFilters({searchKey, role, status, userAffiliate, isExternal}): void {
    this.parameters.searchKey = searchKey;
    this.parameters.status = status;
    this.parameters.role = role;
    this.parameters.offset = 0;
    this.parameters.userAffiliate = userAffiliate;
    this.parameters.isExternal = isExternal;
    this.resetPageIndex.next();
    this.loadUsers();
  }

  export(isFilterApplied: boolean): void{
    if (isFilterApplied){
      this.sb.exportAdministrationDetails(this.parameters);
    }else if (!isFilterApplied){
      const parameters = {
        sortKey: 'all',
        sortOrder: '',
        searchKey: 'all',
        role: 'all',
        status: 'all',
        userAffiliate: [],
        isExternal: null
      };
      this.sb.exportAdministrationDetails(parameters);
    }
  }

}

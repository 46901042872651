import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommentsConfiguration } from 'src/modules/types/interfaces';
import { applyOnKeys, snakeToCamel } from '../../tools';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from '../snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class CommentsConfigurationService {
  apiUrl = environment.apiUrl;
  sub;
  data;

  constructor(private http: HttpClient, private snackBar: SnackBarService,
              private spinnerService: NgxSpinnerService) { }

  getCommentsConfiguration(parameter: string): Observable<CommentsConfiguration[]>{
    return this.http.get(`${this.apiUrl}/comments_configuration?search_key=${parameter}`).pipe(
      map(value => {
        return applyOnKeys(value, snakeToCamel);
      }), catchError(error => {
        return of([]);
      })
    );
  }


  updateCommentsConfiguration(parameters: {rule_name: string; fr_comments: string; en_comments}): Observable<CommentsConfiguration[]>{
    return this.http.post(`${this.apiUrl}/update_comments_configuration`, parameters).pipe(
      map(value => {
        return applyOnKeys(value, snakeToCamel);
      }), catchError(error => {
        return of([]);
      })
    );
  }

exportCommentsConfiguration(parameter: {searchKey: string}): void{
    this.spinnerService.show();
    this.http.get(`${this.apiUrl}/export_comments_config?search_key=${parameter.searchKey}`, {
      responseType: 'json'
    }).subscribe(response => {
      const task_id = JSON.parse(JSON.stringify(response)).task_id;
      this.sub = timer(0, 10000).pipe(
        map(() => {
          this.http.get(`${this.apiUrl}/export_status?task_id=${task_id}`, {
            responseType: 'json'
          }).subscribe((res) => {
            if (res) {
              this.data = JSON.parse(JSON.stringify(res));
              if (this.data.status === 200) {
                const export_csv_url = this.data.export_csv_url;
                window.open(export_csv_url, '_self');
                this.sub.unsubscribe();
                this.spinnerService.hide().then();
              }
            }
          }, (error) => {
            this.spinnerService.hide();
            this.snackBar.openSnackBar('Failed to export', 'error');
            this.sub.unsubscribe();
          });
        })).subscribe();
    }, error => {
      this.spinnerService.hide().then();
    });
  }


}

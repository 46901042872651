import { Component, Output, ViewChild, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { AppSandboxService } from 'src/modules/app/app-sandbox.service';

@Component({
  selector: 'kyt-users-filter',
  template: `
 <mat-accordion>
      <mat-expansion-panel class="mat-elevation-z0" (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                           hideToggle="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <label>
              Search and Filters
            </label>
          </mat-panel-title>
          <mat-panel-description class="mat-desc">
            <mat-icon *ngIf="!panelOpenState">add</mat-icon>
            <mat-icon *ngIf="panelOpenState">remove</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="search">
            <mat-form-field appearance="outline">
              <mat-label>Search by IGG or Name</mat-label>
              <input autocomplete="off" matInput type="text" [(ngModel)]="searchKey" (keydown.enter)="search()">
              <mat-icon (click)="search()" class="search-icon">search</mat-icon>
            </mat-form-field>
        </div>
       <div class="filters">
         <div class="role">
             <mat-form-field appearance="outline">
               <mat-label>Role</mat-label>
               <mat-select #roleSelect (selectionChange)="onRoleChange($event)" [formControl]="roles"
                           multiple>
                 <div class="mat-option">
                   <span class="select-left" (click)="selectAllRoles()">Select All</span> /  <span class="select-right" (click)="deselectAllRoles()">Deselect All</span>
                 </div>
                 <mat-option *ngFor="let role of userRoles" [value]="role">{{role}}</mat-option>
               </mat-select>
             </mat-form-field>
           </div>
           <div class="status">
             <mat-form-field appearance="outline">
               <mat-label>Status</mat-label>
               <mat-select #statusSelect (selectionChange)="onStatusChange($event)" [formControl]="status"
                           multiple>
                  <div class="mat-option">
                    <span class="select-left" (click)="selectAllStatus()">Select All</span> /  <span class="select-right" (click)="deselectAllStatus()">Deselect All</span>
                  </div>
                  <mat-option value="Active">Active</mat-option>
                  <mat-option value="Deactivated">Deactivated</mat-option>
               </mat-select>
             </mat-form-field>
           </div>
           <div class="status">
             <mat-form-field appearance="outline">
               <mat-label>Affiliate</mat-label>
               <mat-select #affiliate (selectionChange)="onAffiliateChange($event)" [formControl]="userAffiliate"
                           multiple>
                  <div class="mat-option">
                    <span class="select-left" (click)="selectAllAffiliates()">Select All</span> /  <span class="select-right" (click)="deselectAllAffiliates()">Deselect All</span>
                  </div>
                  <ng-container *ngFor="let affiliate of listOfAffiliates">
                    <mat-option [value]="affiliate">{{affiliate}} </mat-option>
                  </ng-container>
               </mat-select>
             </mat-form-field>
           </div>
           <div class="status">
             <mat-form-field appearance="outline">
               <mat-label>User Type</mat-label>
               <mat-select #userType (selectionChange)="onUserTypeChange($event)" [value]="userType.value">
                           <mat-option [value]="false">Internal</mat-option>
                           <mat-option [value]="true">External </mat-option>
               </mat-select>
             </mat-form-field>
           </div>
          </div>
          <div class="btn-wrapper">
            <button class="btn" mat-flat-button [matMenuTriggerFor]="exportMenu">
                   <mat-icon>save_alt</mat-icon>
                   Export Data
                   <mat-icon *ngIf="!!showExport" (click)="showExport=false;">keyboard_arrow_down</mat-icon>
                   <mat-icon *ngIf="!showExport" (click)="showExport=true;">keyboard_arrow_up</mat-icon>
                 </button>
                 <mat-menu #exportMenu="matMenu">
                   <button mat-menu-item (click)="exportDetails(true)">Export with filters</button>
                   <button mat-menu-item (click)="exportDetails(false)">Export the whole data</button>
             </mat-menu>
             <button class="btn" mat-flat-button (click)="clearFilters()">
               Clear all the filters
             </button>
          </div>
      </mat-expansion-panel>
 </mat-accordion>

  `,
  styleUrls: ['./users-filter.component.scss']
})
export class UsersFilterComponent {
  @ViewChild('roleSelect') roleSelect: MatSelect;
  @ViewChild('userType') userTypeSelect: MatSelect;
  @ViewChild('statusSelect') statusSelect: MatSelect;
  @Input() listOfAffiliates: string[];
  @Output() loadFilter: EventEmitter<any> =  new EventEmitter();
  @Output() export: EventEmitter<any> =  new EventEmitter();
  roles = new FormControl([]);
  rolesAllSelected = false;
  status = new FormControl([]);
  statusAllSelected = false;
  userAffiliate = new FormControl([]);
  userType = new FormControl(null);
  panelOpenState: boolean;
  searchKey = '';
  showExport = true;
  userRoles = ['Administrator', 'Reader', 'Fraud Analyst', 'Affiliate_TF', 'Affiliate_TK' ,
              'Affiliate_TN' , 'Affiliate_TB' , 'Affiliate_TL', 'Affiliate_TD', 'Armatis', 'Analyst ML'];

  constructor(private sb: AppSandboxService) { }

  search(): void {
    if (this.searchKey && this.searchKey.trim() !== '') {
      this.searchKey = this.searchKey.trim();
      this.loadFilters();
    }
  }

  onRoleChange(event: any): void {
    this.loadFilters();
  }

  selectAllRoles(): void {
    this.roles.setValue(this.userRoles);
    this.loadFilters();
  }

  deselectAllRoles(): void {
    this.roles.setValue([]);
    this.loadFilters();
  }

  selectAllStatus(): void {
    this.status.setValue(['Active', 'Deactivated']);
    this.loadFilters();
  }

  selectAllAffiliates(): void {
    this.userAffiliate.setValue([...this.listOfAffiliates]);
    this.loadFilters();
  }

  deselectAllAffiliates(): void {
    this.userAffiliate.setValue([]);
    this.loadFilters();
  }

  deselectAllStatus(): void {
    this.status.setValue([]);
    this.loadFilters();
  }

  onStatusChange(event: any): void {
    this.loadFilters();
  }

  onAffiliateChange(event: any): void {
    this.loadFilters();
  }

  onUserTypeChange(event: any): void {
    this.userType.setValue(event.value);
    this.loadFilters();
  }


  loadFilters(): void{
  const parameters = {
    searchKey: this.searchKey.trim() !== '' ? this.searchKey : 'all',
    role: this.roles.value.length > 0 ? this.roles.value : 'all',
    status:  this.status.value.length > 0 ? this.status.value : 'all',
    userAffiliate: this.userAffiliate.value,
    isExternal: this.userType.value,
  };
  this.loadFilter.emit(parameters);
  }

  clearFilters(): void {
    this.roles.setValue([]);
    this.status.setValue([]);
    this.userAffiliate.setValue([]);
    this.userType.setValue(null);
    this.searchKey = '';
    this.userTypeSelect.value = null;
    this.loadFilters();
  }

  exportDetails(isFilterApplied): void{
    this.export.emit({isFilterApplied});
  }

}

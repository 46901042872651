import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { Audit, AudtiFilters } from 'src/modules/types/interfaces/auditability';
import { Observable, of, timer } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { applyOnKeys, snakeToCamel } from '../../tools';

@Injectable({
  providedIn: 'root'
})
export class AuditabilityService {
  apiUrl = environment.apiUrl;
  sub: any;
  data: any;
  constructor(private http: HttpClient, private snackBar: SnackBarService,
              private spinnerService: NgxSpinnerService) { }

  getAuditDetails(parameter: AudtiFilters): Observable<Audit[]>{
    this.spinnerService.show();
    return this.http.get(`${this.apiUrl}/auditability?alert_start_date=${parameter.alertStartDate}&alert_end_date=${parameter.alertEndDate}&limit=${parameter.limit}&offset=${parameter.offset}&username=${parameter.username}&pcom_number=${parameter.pcomNumber}`).pipe(
      map(value => {
        this.spinnerService.hide();
        this.snackBar.openSnackBar('Audit details loaded successfully', 'success');
        return applyOnKeys(value, snakeToCamel);
      }), catchError(error => {
        this.spinnerService.hide();
        this.snackBar.openSnackBar('Failed to load audit details', 'error');
        return of([]);
      })
    );
  }

  getAuditDetailsPaginator(parameter: AudtiFilters): Observable<number>{
    this.spinnerService.show();
    return this.http.get(`${this.apiUrl}/auditability_paginator?alert_start_date=${parameter.alertStartDate}&alert_end_date=${parameter.alertEndDate}&limit=${parameter.limit}&offset=${parameter.offset}&username=${parameter.username}&pcom_number=${parameter.pcomNumber}`).pipe(
      map(value => {
        this.spinnerService.hide();
        return applyOnKeys(value, snakeToCamel);
      }), catchError(error => {
        this.spinnerService.hide();
        return of(0);
      })
    );
  }

  exportAuditDetails(filter: AudtiFilters): void{
      this.spinnerService.show().then();
      this.http.get(`${this.apiUrl}/export_audit?alert_start_date=${filter.alertStartDate}&alert_end_date=${filter.alertEndDate}&pcom_number=${filter.pcomNumber}&username=${filter.username}`, {
        responseType: 'json'
      }
      ).subscribe(response => {
        const task_id = JSON.parse(JSON.stringify(response)).task_id;
        const blobName = JSON.parse(JSON.stringify(response)).blob_name;
        this.sub = timer(0, 10000).pipe(
          map(() => {
            this.http.get(`${this.apiUrl}/export_status?task_id=${task_id}&blob_name=${blobName}`, {
              responseType: 'json'
            }
            ).subscribe((res) => {
              if (res) {
                this.data = JSON.parse(JSON.stringify(res));
                if (this.data.status === 200) {
                  const export_csv_url = this.data.export_csv_url;
                  window.open(export_csv_url, '_self');
                  this.sub.unsubscribe();
                  this.spinnerService.hide().then();
                }
              }
            });
          })
        ).subscribe();
      }, error => {
        this.spinnerService.hide().then();
      });
  }

  }

import { Component, OnInit } from '@angular/core';
import { AppSandboxService } from '../../app-sandbox.service';
import { PageEvent } from '@angular/material/paginator';
import { AudtiFilters } from 'src/modules/types/interfaces/auditability';

@Component({
  selector: 'kyt-auditability',
  template: `
  <section>
   <!-- title -->
   <div class="title-wrapper">
              <span class="title">
                <h1>Auditability</h1>
              </span>
      </div>
      <!-- filter component -->
      <div>
         <kyt-auditability-filters (loadFilter)="loadAuditDetails()" [(filter)]="filter"></kyt-auditability-filters>
      </div>
      <!-- table -->
      <div class="table-container table-container-1 mat-elevation-z8">
        <table mat-table [dataSource]="auditDetails$ | async">
          <ng-container matColumnDef="user">
                  <th mat-header-cell *matHeaderCellDef>User</th>
                  <td mat-cell *matCellDef="let element">{{element.userName}}</td>
          </ng-container>

          <ng-container matColumnDef="cardNumber">
                  <th mat-header-cell *matHeaderCellDef>Pcom number</th>
                  <td mat-cell *matCellDef="let element">{{element.pcomNumber}}</td>
          </ng-container>

          <ng-container matColumnDef="alertDate">
                  <th mat-header-cell *matHeaderCellDef>Alert Date</th>
                  <td mat-cell *matCellDef="let element">{{element.alertDay}}</td>
          </ng-container>

          <ng-container matColumnDef="lastStatus">
                  <th mat-header-cell *matHeaderCellDef>Last status</th>
                  <td mat-cell *matCellDef="let element">{{element.previousAlertStatus}}</td>
          </ng-container>

          <ng-container matColumnDef="newStatus">
                  <th mat-header-cell *matHeaderCellDef>New status</th>
                  <td mat-cell *matCellDef="let element">{{element.alertStatus}}</td>
          </ng-container>

          <ng-container matColumnDef="fraudTeamComment">
                  <th mat-header-cell *matHeaderCellDef>Fraud team comment</th>
                  <td mat-cell *matCellDef="let element">{{element.fraudTeamComment}}</td>
          </ng-container>

          <ng-container matColumnDef="affiliateComment">
                  <th mat-header-cell *matHeaderCellDef>Affiliate comment</th>
                  <td mat-cell *matCellDef="let element">{{element.affiliateComment}}</td>
          </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <!-- paginator -->
      <mat-paginator [length]="auditDetailsPaginator$ | async"
                   [pageSize]="10"
                   [pageSizeOptions]=[10,20]
                   (page)="onPageChange($event)">
    </mat-paginator>
  </section>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
        <p style="color:#fff;">Data is loading, please wait. </p>
</ngx-spinner>
  `,
  styleUrls: ['./auditability.component.scss']
})
export class AuditabilityComponent implements OnInit {

  constructor(private sb: AppSandboxService) { }
  filter: AudtiFilters = {
    alertStartDate: null,
    alertEndDate: null,
    pcomNumber: null,
    username: null,
    limit: 10,
    offset: 0
  };
  displayedColumns: string[] = ['user', 'cardNumber', 'alertDate', 'lastStatus', 'newStatus', 'fraudTeamComment', 'affiliateComment'];
  auditDetails$ = this.sb.auditDetailsObservable;
  auditDetailsPaginator$ = this.sb.auditDetailsPaginatorObservable;

  ngOnInit(): void { }

  loadAuditDetails(): void{
    this.sb.getAuditDetails(this.filter);
    this.sb.getAuditDetailsPaginator(this.filter);
  }

  onPageChange(event?: PageEvent): void {
    this.filter.limit = event.pageSize;
    this.filter.offset = event.pageIndex * event.pageSize;
    this.loadAuditDetails();
  }

}

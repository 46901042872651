import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {NetworkCodeList} from '../../types/interfaces/configuration';
import {FetchNetworkCode} from '../actions/network-code.actions';

@State<NetworkCodeList[]>({
  name: 'networkCode',
  defaults: []
})

@Injectable({
  providedIn: 'root'
})
export class NetworkCodeState {
  @Action(FetchNetworkCode)
  getNetwokCode(ctx: StateContext<NetworkCodeList[]>, {payload}: FetchNetworkCode): void {
    ctx.setState([...payload]);
  }
}

import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {MicaProductList} from '../../types/interfaces/configuration';
import {FetchMicaProduct} from '../actions/mica-product.actions';

@State<MicaProductList[]>({
  name: 'micaProduct',
  defaults: []
})

@Injectable({
  providedIn: 'root'
})
export class MicaProductState {
  @Action(FetchMicaProduct)
  getMicaProduct(ctx: StateContext<MicaProductList[]>, {payload}: FetchMicaProduct): void {
    ctx.setState([...payload]);
  }
}

import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of, timer } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { applyOnKeys, camelToSnake, snakeToCamel } from '../../tools';
import { MappingNetworkCodes, NetworkCodeList } from '../../../types/interfaces/configuration';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from '../snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class NetworkCodeService {

  apiUrl = environment.apiUrl;
  data;
  sub;

  constructor(private http: HttpClient, private spinnerService: NgxSpinnerService,
              private snackBar: SnackBarService) {
  }
  fetchNetworkCodeList(offset: number, limit: number, searchKey: string, networkCode: string): Observable<NetworkCodeList[]> {
    return this.http.get<NetworkCodeList[]>(`${this.apiUrl}/network-code-list?offset=${offset}&limit=${limit}&search_key=${searchKey}&network_code=${networkCode}`)
      .pipe(
        map(networkCodes => {
          return applyOnKeys(networkCodes, snakeToCamel);
        }), catchError(error => {
          return of([]);
        })
      );
  }

  fetchNumberOfNetworkCodes(searchKey: string, networkCode: string): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}/network-code-paginator?search_key=${searchKey}&network_code=${networkCode}`);
  }

  fetchCodeList(): Observable<any> {
    return this.http.get<any[]>(`${this.apiUrl}/network-code`).pipe(
      map(networkCodes => {
        return applyOnKeys(networkCodes, snakeToCamel);
      })
    );
  }

  postNewNetworkMappingCode(param): Observable<MappingNetworkCodes> {
    this.spinnerService.show();
    return this.http.post(`${this.apiUrl}/update_network_code`, applyOnKeys(param, camelToSnake))
      .pipe(
        map(networkCodes => {
          this.spinnerService.hide();
          this.snackBar.openSnackBar('Successfully created network mapping code', 'success');
          return applyOnKeys(networkCodes, snakeToCamel);
        }), catchError(error => {
          this.spinnerService.hide();
          this.snackBar.openSnackBar('Failed to create network mapping code', 'error');
          return of([]);
        })
      );
  }

  editNetworkMappingCode(param): Observable<MappingNetworkCodes> {
    this.spinnerService.show();
    param = applyOnKeys(param, camelToSnake);
    return this.http.put(`${this.apiUrl}/update_network_code`, param)
      .pipe(
        map(networkCodes => {
          this.spinnerService.hide();
          this.snackBar.openSnackBar('Successfully updated network mapping code', 'success');
          return applyOnKeys(networkCodes, snakeToCamel);
        }), catchError(error => {
          this.spinnerService.hide();
          this.snackBar.openSnackBar('Failed to update network mapping code', 'error');
          return of([]);
        })
      );
  }

  deleteNetworkMappingCode(param: { id: string, tollCompanyCode: string }): Observable<MappingNetworkCodes> {
    this.spinnerService.show();
    return this.http.delete(`${this.apiUrl}/update_network_code?id=${param.id}&toll_company_code=${param.tollCompanyCode}`)
      .pipe(
        map(networkCodes => {
          this.spinnerService.hide();
          this.snackBar.openSnackBar('Successfully deleted network mapping code', 'success');
          return applyOnKeys(networkCodes, snakeToCamel);
        }), catchError(error => {
          this.spinnerService.hide();
          this.snackBar.openSnackBar('Failed to delete network mapping code', 'error');
          return of([]);
        })
      );
  }

  exportNetworkCodes(parameter: { searchKey: string, networkCode: string }): void {
    this.spinnerService.show().then();
    this.http.get(`${this.apiUrl}/export_network_code?search_key=${parameter.searchKey}&network_code=${parameter.networkCode}`, {
      responseType: 'json'
    }).subscribe(response => {
      const task_id = JSON.parse(JSON.stringify(response)).task_id;
      this.sub = timer(0, 10000).pipe(
        map(() => {
          this.http.get(`${this.apiUrl}/export_status?task_id=${task_id}`, {
            responseType: 'json'
          }).subscribe((res) => {
            if (res) {
              this.data = JSON.parse(JSON.stringify(res));
              if (this.data.status === 200) {
                const export_csv_url = this.data.export_csv_url;
                window.open(export_csv_url, '_self');
                this.sub.unsubscribe();
                this.spinnerService.hide().then();
              }
            }
          }, (error) => {
            this.spinnerService.hide();
            this.snackBar.openSnackBar('Failed to export', 'error');
            this.sub.unsubscribe();
          });
        })).subscribe();
    }, error => {
      this.spinnerService.hide().then();
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { CognitoService } from '../services/cognito/cognito.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public cognitoService: CognitoService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Insert ID Token from Digital Pass in API request
    if (request.url.startsWith(`${environment.apiUrl}`)) {
      return this.cognitoService.getAccessToken().pipe(
        switchMap(token => { // use transformation operator that maps to an Observable<T>
          const newRequest = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          });
  
          return next.handle(newRequest);
        })
      );      
    } else {
      return;
    }
  }
}

import { HttpParams } from '@angular/common/http';
import { applyOnKeys } from './objects.tool';
import { camelToSnake } from './strings.tool';

export const queryBuilder = (parameter) => {
    const snakeCaseParam = applyOnKeys(parameter, camelToSnake);
    let httpParams = new HttpParams();
    Object.keys(snakeCaseParam).forEach(key => {
        httpParams = httpParams.set(key, snakeCaseParam[key]);
    });
    return httpParams;
};

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MachineLearning, MachineLearningFilters } from 'src/modules/types/interfaces/machine-learning';
import { applyOnKeys, camelToSnake, snakeToCamel } from '../../tools';
import { SnackBarService } from '../snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class MachineLearningService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private snackBar: SnackBarService,
              private spinnerService: NgxSpinnerService) { }

  getMachineLearningDetails(parameters: MachineLearningFilters): Observable<MachineLearning[]>{
    this.spinnerService.show();
    return this.http.post(`${this.apiUrl}/machine_learning`, applyOnKeys(parameters, camelToSnake)).pipe(
      map(value => {
        this.spinnerService.hide();
        this.snackBar.openSnackBar('Data loaded successfully', 'success');
        return applyOnKeys(value, snakeToCamel);
      }), catchError(error => {
        this.spinnerService.hide();
        this.snackBar.openSnackBar('Failed to load data', 'error');
        return of([]);
      })
    );
  }

  getMachineLearningPaginator(parameters: MachineLearningFilters): Observable<number>{
    return this.http.post(`${this.apiUrl}/machine_learning_paginator`, applyOnKeys(parameters, camelToSnake)).pipe(
      map(value => {
        this.spinnerService.hide();
        return applyOnKeys(value, snakeToCamel);
      }), catchError(error => {
        this.spinnerService.hide();
        return of([]);
      })
    );
  }

  async getMachineLearningAlertDetails(alertUuid: string): Promise<any> {
      this.spinnerService.show();
      return await this.http.get(`${this.apiUrl}/pcom-alert?alert-uuid=${alertUuid}&container=machine_learning`).toPromise()
      .then(response => {
        this.snackBar.openSnackBar('Alert Details loaded successfully', 'success');
        return applyOnKeys(response, snakeToCamel);
      }).catch((error) => {
        this.snackBar.openSnackBar('Failed to load details', 'error');
        console.error(error?.error || error || 'Unexpected error');
        return [];
      }).finally(() => this.spinnerService.hide());
    }
}


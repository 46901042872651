import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, of, timer} from 'rxjs';
import {ExcludeMicaList} from '../../../types/interfaces';
import {catchError, map} from 'rxjs/operators';
import {applyOnKeys, snakeToCamel} from '../../tools';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from '../snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class ExcludeMicaService {
  apiUrl = environment.apiUrl;
  sub;
  data;

  constructor(private http: HttpClient, private spinnerService: NgxSpinnerService,
              private snackBar: SnackBarService) {
  }

  fetchExcludeMicaList(offset: number, limit: number, searchKey: string): Observable<ExcludeMicaList[]> {
    return this.http.get<ExcludeMicaList[]>(`${this.apiUrl}/exclu-mica-list?offset=${offset}&limit=${limit}&search_key=${searchKey}`)
      .pipe(
        map(micaList => {
          return applyOnKeys(micaList, snakeToCamel);
        }), catchError(error => {
          return of([]);
        })
      );
  }

  fetchNumberOfExcludeMica(searchKey: string): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}/exclu-mica-paginator?search_key=${searchKey}`);
  }

  createExcludeMica(parameters: {id: string, card_type: string, comments: string}): Observable<ExcludeMicaList[]>{
    this.spinnerService.show();
    return this.http.post<ExcludeMicaList[]>(`${this.apiUrl}/create-exclud-mica-list`, parameters)
      .pipe(
        map(micaList => {
          this.snackBar.openSnackBar('Successfully added in exclude mica', 'success');
          return applyOnKeys(micaList, snakeToCamel);
        }), catchError(error => {
          this.snackBar.openSnackBar('Failed to add in exclude mica', 'error');
          return of([]);
        })
      );
  }

  editExcludeMica(parameters: {id: string, card_type: string, comments: string}): Observable<ExcludeMicaList[]>{
    this.spinnerService.show();
    return this.http.post<ExcludeMicaList[]>(`${this.apiUrl}/edit-exclud-mica-list`, parameters)
      .pipe(
        map(micaList => {
          this.snackBar.openSnackBar('Successfully updated in exclude mica', 'success');
          return applyOnKeys(micaList, snakeToCamel);
        }), catchError(error => {
          this.snackBar.openSnackBar('Failed to edit in exclude mica', 'error');
          return of([]);
        })
      );
  }

  exportExcludeMicaProducts(parameter: {searchKey: string}): void{
    this.spinnerService.show();
    this.http.get(`${this.apiUrl}/export_exclude_mica?search_key=${parameter.searchKey}`, {
      responseType: 'json'
    }).subscribe(response => {
      const task_id = JSON.parse(JSON.stringify(response)).task_id;
      this.sub = timer(0, 10000).pipe(
        map(() => {
          this.http.get(`${this.apiUrl}/export_status?task_id=${task_id}`, {
            responseType: 'json'
          }).subscribe((res) => {
            if (res) {
              this.data = JSON.parse(JSON.stringify(res));
              if (this.data.status === 200) {
                const export_csv_url = this.data.export_csv_url;
                window.open(export_csv_url, '_self');
                this.sub.unsubscribe();
                this.spinnerService.hide();
              }
            }
          }, (error) => {
            this.spinnerService.hide();
            this.snackBar.openSnackBar('Failed to export', 'error');
            this.sub.unsubscribe();
          });
        })).subscribe();
    }, error => {
      this.spinnerService.hide();
    });
  }


}

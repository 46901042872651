import { Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { applyOnKeys, snakeToCamel } from '../../tools';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { ImportCardpro } from 'src/modules/types/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ImportCardproService {
 apiUrl = environment.apiUrl;
 data;
 sub;

 constructor(private http: HttpClient, private spinnerService: NgxSpinnerService,
             private snackBar: SnackBarService) {
}

  getImportCardProDetails(parameters: {limit: number, offset: number}): Observable<ImportCardpro[]>{
    this.spinnerService.show();
    return this.http.get(`${this.apiUrl}/oppo_forced?offset=${parameters.offset}&limit=${parameters.limit}`).pipe(
      map(cardpro => {
        this.spinnerService.hide();
        this.snackBar.openSnackBar('Cardpro details loaded successfully', 'success');
        return applyOnKeys(cardpro, snakeToCamel);
      }), catchError(error => {
        this.spinnerService.hide();
        this.snackBar.openSnackBar('Failed to load cardpro details', 'error');
        return of([]);
      })
    );
  }

  getImportCardProPaginator(): Observable<number>{
    this.spinnerService.show();
    return this.http.get(`${this.apiUrl}/oppo_forced_paginator`).pipe(
      map(cardpro => {
        this.spinnerService.hide();
        // this.snackBar.openSnackBar('Cardpro details loaded successfully', 'success');
        return applyOnKeys(cardpro, snakeToCamel);
      }), catchError(error => {
        this.spinnerService.hide();
        // this.snackBar.openSnackBar('Failed to load cardpro details', 'error');
        return of(0);
      })
    );
  }

  exportCardProDetails(format: string): void {
    this.spinnerService.show();
    this.http.get(`${this.apiUrl}/arch_export_status?task_id=blacklist/oppo_force_output.${format}`).pipe(
      map((cardpro: {status: number, export_csv_url: string }) => {
        if (cardpro.status === 200){
           window.open(cardpro.export_csv_url, '_self');
        }
        this.spinnerService.hide();
      }), catchError(error => {
        this.spinnerService.hide();
        this.snackBar.openSnackBar('Failed to export', 'error');
        throw error;
      })
    ).subscribe();
  }
}

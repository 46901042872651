import { Component } from '@angular/core';

@Component({
  selector: 'kyt-not-allowed',
  template: `
    <mat-icon>warning</mat-icon>
    <span>
      <h1>Sorry, You are Not Allowed to Access This Page</h1>
    </span>
  `,
  styleUrls: ['./not-allowed.component.scss']
})
export class NotAllowedComponent {
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TransactionsArcyParams } from 'src/modules/types/interfaces';

@Injectable({
  providedIn: 'root'
})
export class TransactionArcyExportService {

  apiUrl = environment.apiUrl;
  sub;
  data;

  constructor(private http: HttpClient, private spinnerService: NgxSpinnerService) {
  }

  downloadCards(parameters: TransactionsArcyParams): void {
    this.spinnerService.show().then();
    this.http.post(`${this.apiUrl}/export_transactions_arcy`, parameters).subscribe(response => {
      const task_id = JSON.parse(JSON.stringify(response)).task_id;
      const blob_name = JSON.parse(JSON.stringify(response)).blob_name;
      this.sub = timer(0, 10000).pipe(
        map(() => {
          this.http.get(`${this.apiUrl}/export_transactions_arcy_status?task_id=${task_id}&blob_name=${blob_name}`, {
            responseType: 'json'
          }
          ).subscribe((res) => {
            if (res) {
              this.data = JSON.parse(JSON.stringify(res));
              if (this.data.status === 200) {
                const export_csv_url = this.data.export_csv_url;
                window.open(export_csv_url, '_self');
                this.sub.unsubscribe();
                this.spinnerService.hide().then();
              }
            }
          });
        })
      ).subscribe();
    }, error => {
      this.spinnerService.hide().then();
    });
  }
}

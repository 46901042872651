import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Login } from '../state-management/actions/auth';
import {
  Alert,
  AlertCommentObj,
  AlertInformation,
  AlertStatus,
  Auth,
  BlackListedBins,
  CurrentUser,
  CustomerFactorisation,
  CustomerFactorisationList,
  ExcludeMicaList,
  ExcludeMicaParams,
  GenericStatus,
  ListAlertsParams,
  ListUsersParams,
  MicaProductList,
  MicaProductParams,
  NetworkCodeList,
  NetworkCodeParams,
  NetworkParameters,
  ParameterSaturation,
  Transaction,
  TransactionsArcyList,
  TransactionsArcyParams,
  User,
  ListLenght,
  ListLengthParameters,
  LnForce,
  RulesConfiguration,
  FilterParameters,
  DnaConfiguration,
  MappingCode,
  CommentsConfiguration,
  BadgeInformation,
  FilterCustomerByNetworks,
  CustomerByNetworks,
  AlertFilters,
  ProvidersConfigurationFilters,
  ListLengthFilters
} from '../types/interfaces';
import { AlertsService } from './services/alerts/alerts.service';
import { FetchAlerts } from '../state-management/actions/alerts.actions';
import { AlertsState } from '../state-management/states/alerts.state';
import { TransactionsState } from '../state-management/states/transactions.state';
import { AlertDetailsService } from './services/alert-details/alert-details.service';
import { FetchAlertDetails } from '../state-management/actions/alert-infos.actions';
import { AlertsDetailsState } from '../state-management/states/alert-information.state';
import { ListParamsState } from '../state-management/states/list-params.state';
import { FetchParamsList } from '../state-management/actions/list-params.actions';
import { FetchPageLength } from '../state-management/actions/paginator.actions';
import { PaginatorState } from '../state-management/states/paginator.state';
import { FetchCurrentUser } from '../state-management/actions/current-user.actions';
import { CurrentUserState } from '../state-management/states/current-user.state';
import { UsersState } from '../state-management/states/users.state';
import { UserPaginatorState } from '../state-management/states/user-paginator.state';
import { UserParamsState } from '../state-management/states/user-params.state';
import { FetchUserParamsList } from '../state-management/actions/users-param.action';
import { ExportTransactionsService } from './services/export-transactions/export-transactions.service';
import { ExportCardsService } from './services/export-cards/export-cards.service';
import { CurrentUserService } from './services/current-user/current-user.service';
import { UsersListService } from './services/users-list/users-list.service';
import { NetworkCodeService } from './services/network-code/network-code.service';
import { FetchNetworkCode } from '../state-management/actions/network-code.actions';
import { FetchNetworkCodePageLength } from '../state-management/actions/network-code-paginator.actions';
import { FetchNetworkCodeParamsList } from '../state-management/actions/network-code-param.actions';
import { NetworkCodeState } from '../state-management/states/network-code.state';
import { NetworkCodeParamsState } from '../state-management/states/network-code-params.state';
import { NetworkCodePaginatorState } from '../state-management/states/network-code-paginator.state';
import { MicaProductService } from './services/mica-product/mica-product.service';
import { MicaProductState } from '../state-management/states/mica-produc.state';
import { MicaProductParamsState } from '../state-management/states/mica-product-params.state';
import { MicaProductPaginatorState } from '../state-management/states/mica-product-paginator.state';
import { FetchMicaProduct } from '../state-management/actions/mica-product.actions';
import { FetchMicaProductPageLength } from '../state-management/actions/mica-product-paginator.actions';
import { FetchMicaProductParamsList } from '../state-management/actions/mica-product-param-actions';
import { ExcludeMicaService } from './services/exclude-mica/exclude-mica.service';
import { ExcludeMicaState } from '../state-management/states/exclude-mica.state';
import { ExcludeMicaParamsState } from '../state-management/states/exclude-mica-params.state';
import { ExcludeMicaPaginatorState } from '../state-management/states/exclude-mica-paginator.state';
import { FetchExcludeMica } from '../state-management/actions/exclude-mica.actions';
import { FetchExcludeMicaPageLength } from '../state-management/actions/exclude-mica-paginator.actions';
import { FetchExcludeMicaParamsList } from '../state-management/actions/exclude-mica-param.actions';
import { TransactionsArcyService } from './services/transactions-arcy/transactions-arcy.service';
import { TransactionsArcyState } from '../state-management/states/transactions-arcy.state';
import { TransactionsArcyParamsState } from '../state-management/states/transactions-arcy-paginator.state';
import { FetchTransactionsArcy } from '../state-management/actions/transactions-arcy.actions';
import { FetchTransactionsArcyParamsList } from '../state-management/actions/transactions-arcy-paginator-actions';
import { NetworkParametersBlacklistedBinsService } from './services/network-parameters-blacklisted-bins/network-parameters-blacklisted-bins.service';
import { FetchBlackListedBins } from '../state-management/actions/blacklisted-bins.actions';
import { BlackListedBinsState } from '../state-management/states/blacklisted-bins.state';
import { TransactionArcyExportService } from './services/transaction-arcy-export/transaction-arcy-export.service';
import { FetchBlacklistedNetworkParameters } from '../state-management/actions/blacklisted-network-param.action';
import { BlacklistedNetworkParametersState } from '../state-management/states/blacklisted-network-param.state';
import { AlertsFollowUpService } from './services/alerts-follow-up/alerts-follow-up.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from './services/snack-bar/snack-bar.service';
import { AlertsFollowUp, ClientPrecision } from '../types/interfaces/alerts-followup';
import { CustomerFactorisationService } from './services/customer-factorisation/customer-factorisation.service';
import { ParameterModelsSaturationService } from './services/parameter-models-saturation.service';
import { ListLengthService } from './services/list-length/list-length.service';
import { LnForceService } from './services/ln-force/ln-force.service';
import { RulesConfigurationService } from './services/rules-configuration/rules-configuration.service';
import { DnaConfigurationService } from './services/dna-configuration/dna-configuration.service';
import { MappingCodeService } from './services/mapping-code/mapping-code.service';
import { CommentsConfigurationService } from './services/comments-configuration/comments-configuration.service';
import { ACCESS } from './tools/access';
import { ImportCardproService } from './services/import-cardpro/import-cardpro.service';
import { HomeService } from './services/home/home.service';
import { CustomerByNetworksService } from './services/customer-by-networks/customer-by-networks.service';
import { BlacklistParametersService } from './services/blacklist-parameters/blacklist-parameters.service';
import { Audit, AudtiFilters } from '../types/interfaces/auditability';
import { AuditabilityService } from './services/auditability/auditability.service';
import { MachineLearning, MachineLearningFilters } from '../types/interfaces/machine-learning';
import { MachineLearningService } from './services/machine-learning/machine-learning.service';
import { ProvidersConfigurationService } from './services/providers-configuration/providers-configuration.service';
import { CognitoService } from './services/cognito/cognito.service';
import { ConfigService } from './services/config/config.service';

@Injectable({
  providedIn: 'root'
})

export class AppSandboxService {
  @Select(AlertsState) alertsList$: Observable<Alert[]>;
  @Select(TransactionsState) transactionsList$: Observable<Transaction[]>;
  @Select(AlertsDetailsState) alertsDetails$: Observable<AlertInformation>;
  @Select(ListParamsState) listParams$: Observable<ListAlertsParams>;
  @Select(PaginatorState) pageLength$: Observable<number>;
  @Select(CurrentUserState) currentUser$: Observable<CurrentUser>;
  @Select(UsersState) usersList$: Observable<User[]>;
  @Select(UserParamsState) userParams$: Observable<ListUsersParams>;
  @Select(UserPaginatorState) userPageLength$: Observable<number>;
  @Select(NetworkCodeState) networkCodeList$: Observable<NetworkCodeList[]>;
  @Select(NetworkCodeParamsState) networkCodeParams$: Observable<NetworkCodeParams>;
  @Select(NetworkCodePaginatorState) networkCodePageLength$: Observable<number>;
  @Select(MicaProductState) micaProductList$: Observable<MicaProductList[]>;
  @Select(MicaProductParamsState) micaProductParams$: Observable<MicaProductParams>;
  @Select(MicaProductPaginatorState) micaProductPageLength$: Observable<number>;
  @Select(ExcludeMicaState) excludeMicaList$: Observable<ExcludeMicaList[]>;
  @Select(ExcludeMicaParamsState) excludeMicaParams$: Observable<ExcludeMicaParams>;
  @Select(ExcludeMicaPaginatorState) excludeMicaPageLength$: Observable<number>;
  @Select(TransactionsArcyState) transactionsArcyList$: Observable<TransactionsArcyList[]>;
  @Select(TransactionsArcyParamsState) transactionsArcyParams$: Observable<number>;
  @Select(BlackListedBinsState) blackListedBins$: Observable<BlackListedBins>;
  @Select(BlacklistedNetworkParametersState) blackListedNetworkds$: Observable<NetworkParameters>;

  constructor(private store: Store,
              private alertsService: AlertsService,
              private alertDetailsService: AlertDetailsService,
              private exportTransactionsService: ExportTransactionsService,
              private exportCardsService: ExportCardsService,
              private currentUserService: CurrentUserService,
              private usersService: UsersListService,
              private networkCodeService: NetworkCodeService,
              private micaProductService: MicaProductService,
              private excludeMicaService: ExcludeMicaService,
              private transactionsArcyService: TransactionsArcyService,
              private networkParamsBins: NetworkParametersBlacklistedBinsService,
              private transactionArcyExportService: TransactionArcyExportService,
              private alertsFollowUpService: AlertsFollowUpService,
              private spinnerService: NgxSpinnerService,
              private snackBarService: SnackBarService,
              private cfService: CustomerFactorisationService,
              private parameterSatuationServices: ParameterModelsSaturationService,
              private listLengthService: ListLengthService,
              private lnForceService: LnForceService,
              private ruleConfigService: RulesConfigurationService,
              private dnaConfigService: DnaConfigurationService,
              private mappingCodeService: MappingCodeService,
              private commentsConfigService: CommentsConfigurationService,
              private cardProService: ImportCardproService,
              private homeService: HomeService,
              private customerByNetworkService: CustomerByNetworksService,
              private blacklistParameterServices: BlacklistParametersService,
              private auditabilityService: AuditabilityService,
              private machineLearningService: MachineLearningService,
              private providersConfigService: ProvidersConfigurationService,
              private cognitoService: CognitoService,
              private configService: ConfigService) {
  }

  alertsFollowUp: BehaviorSubject<AlertsFollowUp[]> = new BehaviorSubject([]);
  alertsFollowUpObservable = this.alertsFollowUp.asObservable();

  alertsFollowUpPaginator: BehaviorSubject<number> = new BehaviorSubject(null);
  alertsFollowUpPaginatorObservalble = this.alertsFollowUpPaginator.asObservable();


  badgeFollowUp: BehaviorSubject<AlertsFollowUp[]> = new BehaviorSubject([]);
  badgeFollowUpObservable = this.badgeFollowUp.asObservable();

  badgeFollowUpPaginator: BehaviorSubject<number> = new BehaviorSubject(null);
  badgeFollowUpPaginatorObservalble = this.badgeFollowUpPaginator.asObservable();


  customerFactorisationPaginator: BehaviorSubject<number> = new BehaviorSubject(0);
  customerFactorisationpPaginatorObservalble = this.customerFactorisationPaginator.asObservable();

  customerFactorisation: BehaviorSubject<CustomerFactorisation[]> = new BehaviorSubject(null);
  customerFactorisationObservable = this.customerFactorisation.asObservable();

  parameterSaturation: BehaviorSubject<ParameterSaturation> = new BehaviorSubject(null);
  parameterSaturationObservable = this.parameterSaturation.asObservable();

  listLenght: BehaviorSubject<ListLenght[]> = new BehaviorSubject(null);
  listLenghtObservable = this.listLenght.asObservable();

  lnForce: BehaviorSubject<LnForce[]> = new BehaviorSubject(null);
  lnForceObservalbe = this.lnForce.asObservable();

  lnForcePaginator: BehaviorSubject<number> = new BehaviorSubject(0);
  lnForcePaginatorObservalbe = this.lnForcePaginator.asObservable();

  rulesConfiguration: BehaviorSubject<RulesConfiguration[]> = new BehaviorSubject(null);
  rulesConfigurationObservable = this.rulesConfiguration.asObservable();

  dnaConfiguration: BehaviorSubject<DnaConfiguration[]> = new BehaviorSubject(null);
  dnaConfigurationObservable = this.dnaConfiguration.asObservable();

  mappingCode: BehaviorSubject<MappingCode[]> = new BehaviorSubject(null);
  mappingCodeObservable = this.mappingCode.asObservable();

  mappingCodePaginator: BehaviorSubject<any> = new BehaviorSubject(0);
  mappingCodePaginatorObservable = this.mappingCodePaginator.asObservable();

  clientsPrecision: BehaviorSubject<ClientPrecision[]> = new BehaviorSubject(null);
  clientsPrecisionObservable = this.clientsPrecision.asObservable();

  clientsPrecisionPaginator: BehaviorSubject<any> = new BehaviorSubject(0);
  clientsPrecisionPaginatorObservable = this.clientsPrecisionPaginator.asObservable();

  badgeList: BehaviorSubject<Alert[]> = new BehaviorSubject(null);
  badgeListObservable = this.badgeList.asObservable();

  badgeListPaginator: BehaviorSubject<any> = new BehaviorSubject(0);
  badgeListPaginatorObservable = this.badgeListPaginator.asObservable();

  currentUser: BehaviorSubject<CurrentUser> = new BehaviorSubject(null);
  userDetailAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);

  commentsConfiguration: BehaviorSubject<CommentsConfiguration[]> = new BehaviorSubject([]);
  commentsConfigurationObservable = this.commentsConfiguration.asObservable();

  usersList: BehaviorSubject<User[]> = new BehaviorSubject([]);
  usersListObservable = this.usersList.asObservable();

  usersListPaginator: BehaviorSubject<number> = new BehaviorSubject(0);
  usersListPaginatorObservable = this.usersListPaginator.asObservable();

  clientRefDna: BehaviorSubject<object> = new BehaviorSubject(null);
  clientRefDnaObservable = this.clientRefDna.asObservable();

  // void emitter to trigger after edit of user detials
  eventEmitter: Subject<void> = new Subject();
  eventEmitterObservalble = this.eventEmitter.asObservable();

  // used in config network code mapping filter
  networkCodeList: BehaviorSubject<any> = new BehaviorSubject([]);
  networkCodeListObservable = this.networkCodeList.asObservable();

  badgeDetails: BehaviorSubject<BadgeInformation> = new BehaviorSubject(null);
  badgeDetailsObservable = this.badgeDetails.asObservable();

  importCardsPro: BehaviorSubject<any> = new BehaviorSubject(null);
  importCardProObservable = this.importCardsPro.asObservable();

  importCardsProPagintaor: BehaviorSubject<number> = new BehaviorSubject(0);
  importCardProPaginatorObservable = this.importCardsProPagintaor.asObservable();

  isFieldDisabled = false;
  allForOneRoles = ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'];

  alertInsight: BehaviorSubject<any> = new BehaviorSubject(null);
  alertInsightObservable = this.alertInsight.asObservable();


  customerByNetworks: BehaviorSubject<CustomerByNetworks[]> = new BehaviorSubject(null);
  customerByNetworksObservable = this.customerByNetworks.asObservable();

  customerByNetworksPaginator: BehaviorSubject<number> = new BehaviorSubject(0);
  customerByNetworksPaginatorObservable = this.customerByNetworksPaginator.asObservable();

  blacklistPipeline: BehaviorSubject<any> = new BehaviorSubject(null);
  blacklistPipelineObservable = this.blacklistPipeline.asObservable();

  blackListParameters: BehaviorSubject<any> = new BehaviorSubject(null);
  blackListParametersObservable = this.blackListParameters.asObservable();

  auditDetails: BehaviorSubject<Audit[]> = new BehaviorSubject(null);
  auditDetailsObservable = this.auditDetails.asObservable();

  auditDetailsPaginator: BehaviorSubject<number> = new BehaviorSubject(0);
  auditDetailsPaginatorObservable = this.auditDetailsPaginator.asObservable();

  machineLearningDetails: BehaviorSubject<MachineLearning[]> = new BehaviorSubject(null);
  machineLearningObservable = this.machineLearningDetails.asObservable();

  machineLearningPaginator: BehaviorSubject<number> = new BehaviorSubject(0);
  machineLearningPaginatorObservable = this.machineLearningPaginator.asObservable();

  machineLearningAlertDetails: BehaviorSubject<any> = new BehaviorSubject(null);
  machineLearningAlertDetailsObservable = this.machineLearningAlertDetails.asObservable();


  providersConfiguration: BehaviorSubject<ProvidersConfigurationFilters[]> = new BehaviorSubject(null);
  providersConfigurationObservable = this.providersConfiguration.asObservable();

  providersConfigurationPaginator: BehaviorSubject<number> = new BehaviorSubject(0);
  providersConfigurationPaginatorObservable = this.providersConfigurationPaginator.asObservable();


  setLoginToken(auth: Auth): void {
    this.store.dispatch(new Login(auth));
  }

  affiliateMapping = {
    Affiliate_TF: 'TF',
    Affiliate_TB: 'TB',
    Affiliate_TD: 'TD',
    Affiliate_TK: 'TK',
    Affiliate_TN: 'TN',
    Affiliate_TL: 'TL'
  };
  reverseAffiliateMapping = {
    TF: 'Affiliate_TF',
    TB: 'Affiliate_TB',
    TD: 'Affiliate_TD',
    TK: 'Affiliate_TK',
    TN: 'Affiliate_TN',
    TL: 'Affiliate_TL'
  };


  listAlerts(parameter: AlertFilters): Observable<Alert[]> {
    return this.alertsService.fetchAlertsList(parameter).pipe(
        tap(alerts => this.store.dispatch(new FetchAlerts(alerts)))
      );
  }

  numberOfAlerts(parameters: AlertFilters): void {
    this.alertsService.fetchNumberOfAlerts(parameters)?.pipe(tap(numberOfAlerts =>
      this.store.dispatch(new FetchPageLength(numberOfAlerts)))).subscribe();

  }

  updateAlertsStatus(alertUid: string, alertStatus: AlertStatus,
    container: 'cards' | 'badges' | 'machine_learning'): Observable<{ error: string }> {
    return this.alertsService.updateAlertStatus(alertUid, alertStatus, container);
  }

  updateAffiliateAlertStatus(alertUid: string, parameters: any,
    container: 'cards' | 'badges' | 'machine_learning'): Observable<{ error: string }> {
    return this.alertsService.updateStatusAffiliate(alertUid, parameters, container);
  }

  updateAlertsStatusFollowUp(alertUid: string, alertStatus: AlertStatus, container: string, filter: any): Observable<{ error: string }> {
    return this.alertsFollowUpService.updateAlertStatus(alertUid, alertStatus, container, filter);
  }

  listAlertDetails(alertUuid: string, pcomNumber: string, container: string): Observable<AlertInformation> {
    return this.alertDetailsService.fetchAlertDetails(alertUuid, pcomNumber, container).pipe(
      tap((alertDetails: AlertInformation) => this.store.dispatch(new FetchAlertDetails(alertDetails)))
    );
  }

  fetchParamsList(listParams: AlertFilters): void {
    this.store.dispatch(new FetchParamsList(listParams)).subscribe();
  }

  updateAlertComment(alertUid: string, commentObj: AlertCommentObj, pcomNumber: string,
    container: 'cards' | 'badges' | 'machine_learning'): Observable<GenericStatus> {
    return this.alertDetailsService.updateComment(alertUid, commentObj, container).pipe(
      tap(response => response && this.listAlertDetails(alertUid, pcomNumber, container))
    );
  }

  exportTransactions(alertUid: string, date: string,
    container: 'cards' | 'badges' | 'machine_learning'): void {
    this.exportTransactionsService.downloadTransactions(alertUid, date, container);
  }

  exportCards(filters: AlertFilters): void {
    this.exportCardsService.downloadCards(filters);
  }

  fetchUserDetails(igg: string): Observable<CurrentUser> {
    const data = from(this.currentUserService.fetchUserDetails(igg));
    return data.pipe(
      tap((currentUser: CurrentUser) => this.store.dispatch(new FetchCurrentUser(currentUser)))
    );
  }

  listUsers({ offset, limit, sortKey, sortOrder, searchKey, role, status, userAffiliate, isExternal }): void {
    this.spinnerService.show();
    this.usersService.fetchUsersList(offset, limit, sortKey, sortOrder, searchKey, role, status, userAffiliate, isExternal).subscribe((users) => {
      this.usersList.next(users);
    }, (error) => { },
      () => this.loadingAndToasterService('List of users loaded sucsessfully', 'success'));
  }

  numberOfUsers({ searchKey, role, status, userAffiliate, isExternal }): void {
    this.usersService.fetchNumberOfUsers(searchKey, role, status, userAffiliate, isExternal).subscribe((value) => {
      this.usersListPaginator.next(value);
    });
  }

  createUser(parameters: {
    firstname: string, lastname: string, email: string, role: string,
    status: string, igg: string, user_affiliate: []
  }): void {
    this.spinnerService.show();
    this.usersService.createUser(parameters).subscribe(() => this.loadingAndToasterService('User created successfully', 'success'));
  }

  fetchUserParamsList(userParams: ListUsersParams): void {
    this.store.dispatch(new FetchUserParamsList(userParams)).subscribe();
  }

  listNetworkCode(offset: number, limit: number, searchKey: string, networkCode: string): Observable<NetworkCodeList[]> {
    return this.networkCodeService.fetchNetworkCodeList(offset, limit, searchKey, networkCode).pipe(
      tap(networkCodeList => this.store.dispatch(new FetchNetworkCode(networkCodeList)))
    );
  }

  numberOfNetworkCode(searchKey: string, networkCode: string): void {
    this.networkCodeService.fetchNumberOfNetworkCodes(searchKey, networkCode).pipe(tap(numberOfNetworkCode =>
      this.store.dispatch(new FetchNetworkCodePageLength(numberOfNetworkCode)))).subscribe();

  }

  editMappingNetworkCode(param): void {
    this.networkCodeService.editNetworkMappingCode(param).subscribe((value) => {
      this.networkCodeList.next(value.networkCode);
      this.store.dispatch(new FetchNetworkCode(value.networkCodeList));
    });
  }

  createMappingNetworkCode(param): void {
    this.networkCodeService.postNewNetworkMappingCode(param).subscribe((value) => {
      this.networkCodeList.next(value.networkCode);
      this.store.dispatch(new FetchNetworkCode(value.networkCodeList));
    });
  }

  deleteMappingNetworkCode(param): void {
    this.networkCodeService.deleteNetworkMappingCode(param).subscribe((value) => {
      this.networkCodeList.next(value.networkCode);
      this.store.dispatch(new FetchNetworkCode(value.networkCodeList));
    });
  }

  fetchNetworkCodeParamsList(networkCodeParams: NetworkCodeParams): void {
    this.store.dispatch(new FetchNetworkCodeParamsList(networkCodeParams)).subscribe();
  }

  fetchCodeList(): void {
    this.networkCodeService.fetchCodeList().subscribe((value) => {
      this.networkCodeList.next(value);
    });
  }

  listMicaProduct(offset: number, limit: number, searchKey: string): Observable<MicaProductList[]> {
    return this.micaProductService.fetchMicaProductList(offset, limit, searchKey).pipe(
      tap(micaProductList => this.store.dispatch(new FetchMicaProduct(micaProductList)))
    );
  }

  numberOfMicaProduct(searchKey: string): void {
    this.micaProductService.fetchNumberOfMicaProducts(searchKey).pipe(tap(numberOfMicaProduct =>
      this.store.dispatch(new FetchMicaProductPageLength(numberOfMicaProduct)))).subscribe();

  }

  editMicaProduct(param): void {
    this.micaProductService.editMicaProduct(param).pipe(
      tap(micaProductList => this.store.dispatch(new FetchMicaProduct(micaProductList)))
    ).subscribe();
    this.numberOfMicaProduct(param.searchKey);
  }

  createMicaProduct(param): void {
    this.micaProductService.createMicaProduct(param).pipe(
      tap(micaProductList => this.store.dispatch(new FetchMicaProduct(micaProductList)))
    ).subscribe(() => this.numberOfMicaProduct(param.searchKey));
  }

  deleteMicaProduct(id: string): void {
    this.micaProductService.deleteMicaProduct(id).pipe(
      tap(micaProductList => this.store.dispatch(new FetchMicaProduct(micaProductList)))
    ).subscribe(() => this.numberOfMicaProduct('all'));
  }

  fetchMicaProductParamsList(micaProductParams: MicaProductParams): void {
    this.store.dispatch(new FetchMicaProductParamsList(micaProductParams)).subscribe();
  }

  listExcludeMica(offset: number, limit: number, searchKey: string): Observable<ExcludeMicaList[]> {
    return this.excludeMicaService.fetchExcludeMicaList(offset, limit, searchKey).pipe(
      tap(micaList => this.store.dispatch(new FetchExcludeMica(micaList)))
    );
  }

  numberOfExcludeMica(searchKey: string): void {
    this.excludeMicaService.fetchNumberOfExcludeMica(searchKey).pipe(tap(numberOfExcludeMica =>
      this.store.dispatch(new FetchExcludeMicaPageLength(numberOfExcludeMica)))).subscribe();

  }

  fetchExcludeMicaParamsList(excludeMicaParams: ExcludeMicaParams): void {
    this.store.dispatch(new FetchExcludeMicaParamsList(excludeMicaParams)).subscribe();
  }

  listTransactionsArcy(param: TransactionsArcyParams): Observable<TransactionsArcyList[]> {
    return this.transactionsArcyService.fetchTransactionsArcyList(param).pipe(
      tap(transactionsArcyList => this.store.dispatch(new FetchTransactionsArcy(transactionsArcyList)))
    );
  }

  fetchTransactionsArcyPaginator(transactionsArcyPaginator: TransactionsArcyParams): void {
    this.transactionsArcyService.fetchPageLength(transactionsArcyPaginator).pipe(
      tap(length => this.store.dispatch(new FetchTransactionsArcyParamsList(length)))).subscribe();
  }

  blackListedBinsList(): Observable<BlackListedBins[]> {
    return this.networkParamsBins.fetchBlackListedBins().pipe(
      tap(binsList => this.store.dispatch(new FetchBlackListedBins(binsList))));
  }

  exportTransactionArcyCards(parameters: TransactionsArcyParams): void {
    this.transactionArcyExportService.downloadCards(parameters);
  }

  blacklistedNetwork(): Observable<NetworkParameters[]> {
    return this.networkParamsBins.fetchNetworkParamerters().pipe(
      tap(network => this.store.dispatch(new FetchBlacklistedNetworkParameters(network))));
  }

  setAlertsFollowUp(parameter): void {
    parameter.igg = this.currentUser.getValue().igg;
    this.alertsFollowUpService.getAlertsFollowUp(parameter).subscribe((value) => {
      this.alertsFollowUp.next(value);
    }, (error) => this.loadingAndToasterService('Failed to load data', 'error'),
      () => this.loadingAndToasterService('Alerts details fetched successfully', 'success'));
  }

  setAlertsFollowUpPaginator(parameter): void {
    parameter.igg = this.currentUser.getValue().igg;
    this.alertsFollowUpService.getAlertsFollowUpPaginator(parameter).subscribe((value) => {
      this.alertsFollowUpPaginator.next(value);
    });
  }

  setAlertsFollowUpFields(param: any, alertId: string, filter: any): void {
    this.spinnerService.show();
    this.alertsFollowUpService.postAlertsFollowUp(param, alertId, filter).subscribe((value) =>
      this.alertsFollowUp.next(value),
      (error) => this.loadingAndToasterService('Failed to update the fields', 'error'),
      () => this.loadingAndToasterService('Fields updated successfully', 'success'));
  }

  setAffiliateCommentCards(alertId: string, param: { userName: string, comment: string, igg: string, param: any },
    container: 'cards' | 'badges'): void {
    this.alertsFollowUpService.updateAffiliateComment(alertId, param, container).subscribe((resp) => {
      this.alertsFollowUp.next(resp);
    });
  }

  alertsFollowUpExportCards(parameter): void {
    parameter.igg = this.currentUser.getValue().igg;
    this.alertsFollowUpService.downloadCards(parameter);
  }

  setBadgeFollowUp(parameter): void {
    this.alertsFollowUpService.getBadgeFollowUp(parameter).subscribe((value) => {
      this.badgeFollowUp.next(value);
    }, (error) => this.loadingAndToasterService('Failed to load data', 'error'),
      () => this.loadingAndToasterService('Badge details fetched successfully', 'success'));
  }

  setBadgeFollowUpPaginator(parameter): void {
    this.alertsFollowUpService.getBadgeFollowUpPaginator(parameter).subscribe((value) => {
      this.badgeFollowUpPaginator.next(value);
    });
  }

  setBadgeFollowUpFields(param: any, alertId: string, filters: any): void {
    this.spinnerService.show();
    this.alertsFollowUpService.postBadgeFollowUp(param, alertId, filters).subscribe((value) => {
      this.badgeFollowUp.next(value);
    },
      (error) => this.loadingAndToasterService('Failed to update the fields', 'error'),
      () => this.loadingAndToasterService('Fields updated successfully', 'success'));
  }

  setAffiliateCommentBadges(alertId: string, param: { userName: string, comment: string, igg: string, param: any },
    container: 'cards' | 'badges'): void {
    this.alertsFollowUpService.updateAffiliateComment(alertId, param, container).subscribe((resp) => {
      this.badgeFollowUp.next(resp);
    });
  }

  badgeFollowUpExportCards(parameter): void {
    this.alertsFollowUpService.downloadBadge(parameter);
  }

  loadingAndToasterService(msg: string, status: string): void {
    this.spinnerService.hide();
    this.snackBarService.openSnackBar(msg, status);
  }

  getCustomerFactorisation(pcomNumber: string, client: string,
    startDate: string, endDate: string, offset = 0, limit = 10, sortOrder = 'DESC'): void {
    this.spinnerService.show();
    this.getCustomerFactorisationPaginator(pcomNumber, client, startDate, endDate);
    this.cfService.getFactorisationDetails(offset, limit, sortOrder, pcomNumber, client, startDate, endDate).subscribe((value: any) => {
      this.customerFactorisation.next(value);
    },
      (error) => this.loadingAndToasterService('Failed to load factorisation details', 'error'),
      () => this.loadingAndToasterService('Factorisation details loaded successfully', 'success'));
  }

  getCustomerFactorisationPaginator(pcomNumber: string, client: string, startDate: string, endDate: string): void {
    this.cfService.getFactorisationPaginator(pcomNumber, client, startDate, endDate).subscribe((value) => {
      this.customerFactorisationPaginator.next(value);
    });
  }

  factoriseClientList(parameters: CustomerFactorisationList): void {
    this.spinnerService.show();
    this.cfService.factoriseClients(parameters).subscribe((value: any) => {
      this.customerFactorisation.next(value);
    }, (error) => this.loadingAndToasterService('Failed to load factorisation details', 'error'),
      () => this.loadingAndToasterService('Factorisation details loaded successfully', 'success'));
  }

  factorisationDeleteClientList(parameters: string[]): void {
    this.spinnerService.show();
    this.cfService.deleteClients(parameters).subscribe((value: any) => {
      if (value.length > 0) { this.customerFactorisation.next(value); } else {
        this.customerFactorisation.next(this.customerFactorisation.getValue());
      }
    });
  }

  getParameterModelSaturationDetails(): void {
    this.spinnerService.show();
    this.parameterSatuationServices.getParameterDetails().subscribe((value: ParameterSaturation) => {
      this.parameterSaturation.next(value);
    }, (error) => this.loadingAndToasterService('Failed to load paramétrage modèle de saturation details', 'error'),
      () => this.loadingAndToasterService('Paramétrage modèle de saturation loaded successfully', 'success'));
  }

  setParameterModelSaturationDetails(parameter: any): void {
    this.spinnerService.show();
    this.parameterSatuationServices.postParameterSaturation(parameter).subscribe(value => {
      this.parameterSaturation.next(value);
    }, (error) => this.loadingAndToasterService('Failed to update paramétrage modèle de saturation fields', 'error'),
      () => this.loadingAndToasterService('Paramétrage modèle de saturation fields updated successfully', 'success'));
  }

  deleteParamerterSaturationFields(parameter: any): void {
    this.spinnerService.show();
    this.parameterSatuationServices.postParameterSaturation(parameter).subscribe(value => {
      this.parameterSaturation.next(value);
    }, (error) => this.loadingAndToasterService('Failed to delete paramétrage modèle de saturation fields', 'error'),
      () => this.loadingAndToasterService('Paramétrage modèle de saturation feild deleted successfully', 'success'));
  }

  getListLenghtDetails(parameters: ListLengthFilters): void {
    this.spinnerService.show();
    this.listLengthService.getListLength(parameters).subscribe((value: ListLenght[]) => {
      this.listLenght.next(value);
    });
  }

  postListLengthDetails(parameter: ListLengthParameters): void {
    this.spinnerService.show();
    this.listLengthService.postListLenght(parameter).subscribe((value: ListLenght[]) => {
      this.listLenght.next(value);
    }, (error) => this.loadingAndToasterService('Failed to update', 'error'),
      () => this.loadingAndToasterService('Updated successfully', 'success'));
  }


  deleteListLengthDetails(networkLib: string): void {
    this.spinnerService.show();
    this.listLengthService.deleteListLength(networkLib).subscribe((value: ListLenght[]) => {
      this.listLenght.next(value);
    }, (error) => this.loadingAndToasterService('Failed to delete', 'error'),
      () => this.loadingAndToasterService('Deleted successfully', 'success'));
  }

  markReadAlertDetails(uid: string, read: boolean, container: 'cards' | 'badges' | 'machine_learning'): void {
    this.alertDetailsService.markAlertDetail(uid, read, container).subscribe((value) => {
    }, (error) => this.loadingAndToasterService('Failed to Update', 'error'));
  }

  getLnForceDetails(param): void {
    this.spinnerService.show();
    this.lnForceService.getLnForce(param).subscribe((e) => {
      this.lnForce.next(e);
    }, (error) => this.loadingAndToasterService('Failed to load LnForce', 'error'),
      () => this.loadingAndToasterService('Ln_Force loaded successfully', 'success'));
  }

  getLnForcePaginator(param): void {
    this.lnForceService.fetchPageLength(param).subscribe((e) => {
      this.lnForcePaginator.next(e);
    });
  }

  postLnForceDetails(param): void {
    this.spinnerService.show();
    this.lnForceService.postLnForce(param, param?.id).subscribe((e) => {
      this.lnForce.next(e);
    }, (error) => this.loadingAndToasterService('Failed to load LnForce', 'error'),
      () => this.loadingAndToasterService('Ln_Force updated successfully', 'success'));
  }

  getRulesConfiguration(parameter: FilterParameters): void {
    this.spinnerService.show();
    this.ruleConfigService.fetchRulesConfigurations(parameter).subscribe((e) => {
      this.rulesConfiguration.next(e);
    }, (error) => this.spinnerService.hide(),
      () => this.spinnerService.hide());
  }

  createRuleConfiguration(parameters): void {
    this.ruleConfigService.createRulesConfigurations(parameters).subscribe((e) => {
      this.rulesConfiguration.next(e);
    });
  }

  editRuleConfiguration(parameters): void {
    this.ruleConfigService.editRulesConfigurations(parameters).subscribe((e) => {
      this.rulesConfiguration.next(e);
    });
  }

  deleteRuleConfiguration(parameters): void {
    this.ruleConfigService.deleteRulesConfigurations(parameters).subscribe((e) => {
      this.rulesConfiguration.next(e);
    });
  }

  getDnaConfiguration(): void {
    this.spinnerService.show();
    this.dnaConfigService.fetchDnaConfigurations().subscribe((e) => {
      this.dnaConfiguration.next(e);
    }, (error) => this.spinnerService.hide(),
      () => this.spinnerService.hide());
  }

  updateDnaConfigComment(parameter: { dna_type_map: string, comment: string }): void {
    this.spinnerService.show();
    this.dnaConfigService.postDnaConfigComment(parameter).subscribe((e) => {
      this.dnaConfiguration.next(e);
    }, (error) => this.spinnerService.hide(),
      () => this.spinnerService.hide());
  }

  getMappingCode(parameters: MappingCode): void {
    this.spinnerService.show();
    this.mappingCodeService.fetchMappingCodes(parameters).subscribe((e) => {
      this.mappingCode.next(e);
    }, (error) => this.spinnerService.hide(),
      () => this.spinnerService.hide());

  }

  getMappingCodePaginator(): void {
    this.mappingCodeService.fetchPageLength().subscribe((e) => {
      this.mappingCodePaginator.next(e);
    });
  }

  updateMappingCode(parameters): void {
    this.spinnerService.show();
    this.mappingCodeService.postMappingCodes(parameters).subscribe((e) => {
      this.mappingCode.next(e);
    }, (error) => this.spinnerService.hide(),
      () => this.spinnerService.hide());
  }

  fetchClientsPrecision(parameters): void {
    this.spinnerService.show();
    this.alertsFollowUpService.getClientsPrecision(parameters).subscribe((e) => {
      this.clientsPrecision.next(e);
    }, (error) => this.spinnerService.hide(),
      () => this.spinnerService.hide());
  }

  fetchClientsPrecisionPaginator(parameters): void {
    this.alertsFollowUpService.getClientsPrecisionPaginator(parameters).subscribe((e) => {
      this.clientsPrecisionPaginator.next(e);
    });
  }

  updateClientPrecisionDetails(parameters): void {
    this.spinnerService.show();
    this.alertsFollowUpService.postClientPrecisionDetails(parameters).subscribe((e) => {
    }, (error) => this.loadingAndToasterService('Failed to update client precision', 'error'),
      () => this.loadingAndToasterService('Client precision successfully updated !', 'success'));
  }

  getListOfBadgeAlerts(parameters: ListAlertsParams): void {
    this.spinnerService.show();
    this.alertsService.fetchBadgeAlerts(parameters).subscribe((e) => {
      this.badgeList.next(e);
    }, (error) => this.loadingAndToasterService('Failed to load badge alerts', 'error'),
      () => this.loadingAndToasterService('Badge alerts loaded successfully!', 'success'));
  }

  getBageAlertsPaginator(parameters: ListAlertsParams): void {
    this.spinnerService.show();
    this.alertsService.fetchNumberOfBadgeAlerts(parameters).subscribe((e) => {
      this.badgeListPaginator.next(e);
    });
  }

  exportBadge(startDate: string, endDate: string, country: string, status: string, statusAffiliate: string, type: string,
    productFamily: string, searchKey: string, searchSiteCode: string, pcomNumber: string, minScore: number, maxScore: number,
    minAmt: number, maxAmt: number): void {
    this.alertsService.exportBadge(startDate, endDate, country, status, statusAffiliate, type, productFamily,
      searchKey, searchSiteCode, pcomNumber, minScore, maxScore, minAmt, maxAmt);
  }

  // deleteUser(id: string): void {
  //   this.spinnerService.show();
  //   this.usersService.removeUser(id).subscribe((e) => {
  //     this.snackBarService.openSnackBar('User removed successfully', 'success');
  //   },
  //     (error) => this.loadingAndToasterService('Failed to remove user', 'error'),
  //     () => {
  //       this.listUsers({
  //         limit: 10, offset: 0, role: 'all', searchKey: 'all', sortKey: '', sortOrder: '',
  //         status: 'all', userAffiliate: []
  //       });
  //       this.numberOfUsers({ role: 'all', searchKey: 'all', status: 'all', userAffiliate: [] });
  //     });
  // }

  updateUserDetails(user): void {
    this.spinnerService.show();
    this.usersService.updateUserDetails(user).subscribe((e) => { },
      (error) => this.loadingAndToasterService('Failed to update user details', 'error'),
      () => {
        this.loadingAndToasterService('User updated sucessfully', 'success');
        this.eventEmitter.next();
      });
  }

  updateLastConnection(igg: string): void {
    const today = new Date();
    const date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
    if (sessionStorage.getItem('lastConnection') === date) {
      return;
    } else {
      sessionStorage.setItem('lastConnection', date);
      this.usersService.updateLastConnection(igg);
    }
  }

  async canActivateCurrentUser(): Promise<boolean> {
   try {
     this.spinnerService.show();
     let igg;
     let gigya_emailId;
     await this.cognitoService.getUser().then(async (usr) => {
       if(usr.username.indexOf('DigitalPass_') !== -1 ) {
         igg = usr.username.split('DigitalPass_')[1];
       } else {
         igg = usr.username.split(this.configService.getConfig().gigya + '_')[1];
       }
     });
     if (this.userDetailAvailable.getValue() === false) {
       const data = await this.fetchUserDetails(igg).toPromise();
       
       if (data) {
         this.currentUser.next({ ...data, igg });
         this.userDetailAvailable.next(true);
         this.updateLastConnection(igg);
         this.spinnerService.hide();
         return true;
       } else {
         this.spinnerService.hide();
         return false;
       }
     } else {
       this.spinnerService.hide();
     }
     return this.userDetailAvailable.getValue();
   } catch (error) {
    this.spinnerService.hide();
    return false;
   }

  }

  getFieldStatus(): boolean {
    // changes to be made after internal routes are defined creating respective modules
    try {
      const access = ACCESS[document.location.pathname.split('/')[1]]
        .internal_routes.find((e) => e.route === document.location.pathname).readonly.includes(this.currentUser.getValue().role);
      if (typeof (access) === 'boolean') {
        this.isFieldDisabled = access;
        return this.isFieldDisabled;
      } else {
        this.isFieldDisabled = true;
        return this.isFieldDisabled;
      }
    } catch (error) {
      const access = ACCESS[document.location.pathname.replace(/[\/]/g, '')]
        .internal_routes[0][this.currentUser.getValue().role.replace(' ', '_').toLowerCase()]?.readonly;
      if (typeof (access) === 'boolean') {
        this.isFieldDisabled = access;
        return this.isFieldDisabled;
      } else {
        this.isFieldDisabled = true;
        return this.isFieldDisabled;
      }
    }

  }

  public get isHidden(): boolean {
    const currentRole = this.currentUser.getValue().role;
    if (['Affiliate_TF', 'Affiliate_TB', 'Affiliate_TD', 'Affiliate_TK', 'Affiliate_TN',
      'Affiliate_TL', 'Armatis'].includes(currentRole)) {
      return false;
    } else {
      return true;
    }
  }

  getCommentsConfiguration(param: string): void {
    this.spinnerService.show();
    this.commentsConfigService.getCommentsConfiguration(param).subscribe((e) => {
      this.commentsConfiguration.next(e);
    }, (error) => this.loadingAndToasterService('Failed to load comments configuration', 'error'),
      () => this.loadingAndToasterService('Comments Configuration loaded successfully', 'success'));
  }

  updateCommentsConfiguration(parameters): void {
    this.spinnerService.show();
    this.commentsConfigService.updateCommentsConfiguration(parameters).subscribe((e) => {
      this.commentsConfiguration.next(e);
    }, (error) => this.loadingAndToasterService('Failed to update comments', 'error'),
      () => this.loadingAndToasterService('Comments Configuration updated successfully', 'success'));
  }

  editExcludeMica(parameters): Observable<ExcludeMicaList[]> {
    return this.excludeMicaService.editExcludeMica(parameters).pipe(
      tap(micaList => this.store.dispatch(new FetchExcludeMica(micaList)))
    );
  }

  addExcludeMica(parameters): Observable<ExcludeMicaList[]> {
    return this.excludeMicaService.createExcludeMica(parameters).pipe(
      tap(micaList => this.store.dispatch(new FetchExcludeMica(micaList)))
    );
  }

  getBadgeDetails(alertUuid: string, pcomNumber: string): void {
    this.alertDetailsService.fetchBadgeDetails(alertUuid, pcomNumber)
      .subscribe((e) => {
        this.badgeDetails.next(e);
      });
  }

  exportConfigurationNetworkCode(parameters: { searchKey: string, networkCode: string }): void {
    this.networkCodeService.exportNetworkCodes(parameters);
  }

  exportConfigurationMicaProducts(parameters: { searchKey: string }): void {
    this.micaProductService.exportMicaProducts(parameters);
  }

  exportConfigurationExcludeMica(parameters: { searchKey: string }): void {
    this.excludeMicaService.exportExcludeMicaProducts(parameters);
  }

  exportConfigurationRulesConfiguration(parameters: {
    rulesName: string; status: string;
    type: string; scoreMin: number; scoreMax: any;
    productFamily: string; supportType: string;
  }): void {
    this.ruleConfigService.exportRuleConfiguration(parameters);
  }

  exportConfigurationComments(parameters: { searchKey: string }): void {
    this.commentsConfigService.exportCommentsConfiguration(parameters);
  }

  getClientReferentialDna(alertId: string, container: string): void {
    const clinetRefDna = this.alertDetailsService.getClientRefDna(alertId, container).subscribe((dna) => {
      this.clientRefDna.next(dna);
    }, () => clinetRefDna.unsubscribe());
  }

  getCardProDetails(parameters: { limit: number, offset: number }): void {
    const importCardProObservable = this.cardProService.getImportCardProDetails(parameters).subscribe((values) => {
      this.importCardsPro.next(values);
    }, () => importCardProObservable.unsubscribe());
  }

  getCardProPaginator(): void {
    const paginatorObservable = this.cardProService.getImportCardProPaginator().subscribe((value: number) => {
      this.importCardsProPagintaor.next(value);
    }, () => paginatorObservable.unsubscribe());
  }

  exportCardProDetails(format: string): void {
    this.cardProService.exportCardProDetails(format);
  }

  getAlertsTreatment(igg: string): void {
    const alertsTreatment = this.homeService.getAlertsTreatment(igg).subscribe((values) => {
      this.alertInsight.next(values);
    }, () => alertsTreatment.unsubscribe());
  }

  isStatusAnalystEditable(): boolean {
    const user = this.currentUser.getValue();
    const accessGranted = ['Administrator', 'Fraud Analyst', 'Analyst ML'];
    return !accessGranted.includes(user.role);
  }

  exportAdministrationDetails(parameters): void {
    this.usersService.export(parameters);
  }

  exportListLength(paramerters: ListLengthFilters): void {
    this.listLengthService.exportListLength(paramerters);
  }

  exportNetworkParametersBlacklistedBins(): void {
    this.networkParamsBins.exportNetworkBlacklistedBins();
  }

  exportParameterDeSaturation(): void {
    this.parameterSatuationServices.exportParameterSaturation();
  }

  getCustomerByNetworks(parameters: FilterCustomerByNetworks): void {
    const customerNetworks = this.customerByNetworkService.getCustomerByNetworks(parameters)
      .subscribe((value: CustomerByNetworks[]) => {
        this.customerByNetworks.next(value);
      }, () => customerNetworks.unsubscribe());
  }

  getCustomerByNetworksPaginator(parameters: FilterCustomerByNetworks): void {
    const cusomerNetworksPaginator = this.customerByNetworkService.getCustomerByNetworksPaginator(parameters)
      .subscribe((value: number) => {
        this.customerByNetworksPaginator.next(value);
      }, () => cusomerNetworksPaginator.unsubscribe());
  }

  getBlacklistPipelineDetails(): void {
    const blacklistPipeline = this.customerByNetworkService.getBlacklistPipelineDetails()
      .subscribe((value: any) => {
        this.blacklistPipeline.next(value);
      }, () => blacklistPipeline.unsubscribe());
  }

  exportForCustomerByNetworks(param: FilterCustomerByNetworks): void {
    this.customerByNetworkService.exportCustomerByNetworks(param);
  }

  getBlacklistParameters(): void {
    const blacklistObservable = this.blacklistParameterServices.getBlacklistParameters().subscribe((value) => {
      if (value) {
        this.blackListParameters.next(value);
      }
    }, () => { blacklistObservable.unsubscribe(); });
  }

  createBlacklistParameters(parameter: { 'new_param_key': string, 'new_param_value': string }): void {
    const blacklistObservable = this.blacklistParameterServices.createNewParameter(parameter).subscribe((value) => {
      if (value) {
        this.blackListParameters.next(value);
      }
    }, () => { blacklistObservable.unsubscribe(); });
  }

  editBlacklistParameters(parameter: { [key: string]: string }): void {
    const blacklistObservable = this.blacklistParameterServices.editParameter(parameter).subscribe((value) => {
      if (value) {
        this.blackListParameters.next(value);
      }
    }, () => { blacklistObservable.unsubscribe(); });
  }

  getAuditDetails(parameters: AudtiFilters): void {
    const auditDetails = this.auditabilityService.getAuditDetails(parameters).subscribe((values) => {
      if (values) {
        this.auditDetails.next(values);
      }
    }, () => { auditDetails.unsubscribe(); });
  }

  getAuditDetailsPaginator(parameters: AudtiFilters): void {
    const auditPaginator = this.auditabilityService.getAuditDetailsPaginator(parameters).subscribe((values) => {
      if (values) {
        this.auditDetailsPaginator.next(values);
      }
    }, () => { auditPaginator.unsubscribe(); });
  }

  exportAuditDetails(filter: AudtiFilters): void {
    this.auditabilityService.exportAuditDetails(filter);
  }

  getMachineLearningDetails(parameters: MachineLearningFilters): void {
    const machineLearning = this.machineLearningService.getMachineLearningDetails(parameters).subscribe((values) => {
      if (values) {
        this.machineLearningDetails.next(values);
      }
    }, () => { machineLearning.unsubscribe(); });
  }

  getMachineLearningPaginator(parameters: MachineLearningFilters): void {
    const paginator = this.machineLearningService.getMachineLearningPaginator(parameters).subscribe((values) => {
      if (values) {
        this.machineLearningPaginator.next(values);
      }
    }, () => { paginator.unsubscribe(); });
  }

  getMachineLearningAlertDetails(alertUuid: string): void {
    this.machineLearningService.getMachineLearningAlertDetails(alertUuid).then((value) => {
      this.machineLearningAlertDetails.next(value);
    });
  }


  getProvidersConfiguration(filters: ProvidersConfigurationFilters): void {
    this.providersConfigService.getProvidersConfiguration(filters).then((value) => {
      this.providersConfiguration.next(value);
    });
  }

  getProvidersConfigurationPaginator(filters: ProvidersConfigurationFilters): void {
    this.providersConfigService.getProvidersConfigurationPaginator(filters).then((value) => {
      this.providersConfigurationPaginator.next(value);
    });
  }

  createProvidersConfiguration(filters: ProvidersConfigurationFilters): void {
    this.providersConfigService.createProvidersConfiguration(filters).then((value) => {
      this.providersConfiguration.next(value);
    });
  }

  exportProvidersConfiguration(filters: ProvidersConfigurationFilters): void {
    this.providersConfigService.exportProvidersConfiguration(filters);
  }

  updateCustomerByNetworks(parameters: { id: string; factoriseCustomer: boolean; blockCustomer: boolean }): void {
    this.customerByNetworkService.updateCustomerByNetworks(parameters).then((value) => {
      // this.customerByNetworks.next(value);
    });
  }

  exportCustomerFactorisation(parameters: { client: string; pcomNumber: string, startDate: string, endDate: string }): void {
    this.cfService.export(parameters);
  }

  exportDnaConfiguration(): void {
    this.dnaConfigService.export();
  }

  exportLnForce(parameters): void {
    this.lnForceService.export(parameters);
  }

}

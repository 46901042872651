import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {User} from '../../types/interfaces';
import {FetchUsers} from '../actions/users.actions';

@State<User[]>({
  name: 'users',
  defaults: []
})

@Injectable({
  providedIn: 'root'
})
export class UsersState {
  @Action(FetchUsers)
  getUsers(ctx: StateContext<User[]>, {payload}: FetchUsers): void {
    ctx.setState([...payload]);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MaterialModule } from 'src/modules/material/material.module';
import { SideNavTreeComponent } from '../components/side-nav/side-nav-tree/side-nav-tree.component';
import { SideNavComponent } from '../components/side-nav/side-nav.component';
import { RouterModule } from '@angular/router';
import { NoCommaPipe } from '../pipes/no-comma';
import { SendMailComponent } from '../components/send-mail/send-mail.component';
import { ExportComponent } from '../components/export/export.component';
import { TableComponent } from '../components/table/table.component';

@NgModule({
  declarations: [
    SideNavComponent,
    SideNavTreeComponent,
    NoCommaPipe,
    SendMailComponent,
    ExportComponent,
    TableComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxSpinnerModule,
  ],
  exports: [CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule,
    SideNavComponent,
    SideNavTreeComponent,
    NoCommaPipe,
    SendMailComponent,
    ExportComponent,
    TableComponent
  ]
})
export class SharedModule { }

import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { Auth } from 'aws-amplify';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { switchMap } from 'rxjs/operators';

export interface IUser {
  email: string;
  password: string;
  name?: string;
}
@Injectable({
  providedIn: 'root'
})
export class CognitoService {

  public authenticationSubject: BehaviorSubject<any>;
  public emailAuthenticationSubject = new BehaviorSubject<any>(null);
  public showHeaderSubject = new BehaviorSubject<any>(true);
  public displayHeader = new BehaviorSubject<any>(false);
  public isUserNotCreated: boolean = true;
  serviceBase = '';
  baseUrl = '';

  constructor(
    public _http: HttpClient,
    private configService: ConfigService
  ) {
    setTimeout(() => {
      this.baseUrl = this.configService.getConfig()?.baseURL;
      this.serviceBase = this.configService.getConfig()?.serviceBase;
      if (this.serviceBase && this.baseUrl) {
        localStorage.setItem('suburl', this.serviceBase);
        localStorage.setItem('baseurl', this.baseUrl);
      }
    }, 2000);

    this.authenticationSubject = new BehaviorSubject<boolean>(false);
  }

  public digitalLogin() {
    return Auth.federatedSignIn({
      customProvider: this.configService.getConfig().digital,
    }).then((tempVar) => {
      this.authenticationSubject.next(true);
    });
  }

  public gigyaLogin() {
    return Auth.federatedSignIn({
      customProvider: this.configService.getConfig().gigya,
    }).then((val) => {
      this.authenticationSubject.next(true);
    });
  }

  public getAccessToken(): Observable<any> {
    return from(Auth.currentSession()).pipe(
      switchMap((session) => of(session.getIdToken().getJwtToken()))
    );
  }

  public signIn(user: IUser): Promise<any> {
    return Auth.signIn({username: user.email,password: user.password}).then((res) => {      
      this.authenticationSubject.next(true);
    });
  }

  public signOut(): Promise<any> {
    return Auth.signOut().then(() => {
      this.authenticationSubject.next(false);
      localStorage.clear();
    });
  }

  public isAuthenticated(): boolean {
    return this.authenticationSubject.value;
  }

  public async getUser(): Promise<any> {
    return await Auth.currentUserInfo();
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AppSandboxService } from 'src/modules/app/app-sandbox.service';

@Component({
  selector: 'kyt-create-user',
  template: `
  <section style="padding:14px;">
  <!-- <button mat-dialog-close class="mat-cancel-btn" mat-icon-button>
      <mat-icon>close icon</mat-icon>
    </button> -->
    <h1 mat-dialog-title style="font-size: 27px;">
      Create User
    </h1>
    <div class="form-wrapper">
        <form [formGroup]="form" (ngSubmit)="submitForm()">
        <div class="w-100">
          <span class="radio-btn">
            <mat-radio-group (change)="togglerUserType()" formControlName="isExternal" aria-label="Select an option">
              <mat-radio-button [value]="false">Internal User</mat-radio-button>
              <mat-radio-button [value]="true">External User</mat-radio-button>
            </mat-radio-group>
          </span>
        </div> 
        <div class="w-100">
          <span [ngStyle]="{'display': form.controls.isExternal.value ? 'none' : ''}" class="w-20">
              <mat-form-field appearance="outline">
                <mat-label>IGG</mat-label>
                <input formControlName="igg" autocomplete="off" matInput type="text">
              </mat-form-field>
            </span>
          <span class="w-50">
              <mat-form-field appearance="outline">
                <mat-label>First Name</mat-label>
                <input formControlName="firstName" autocomplete="off" matInput type="text">
              </mat-form-field>
            </span>
            <span class="w-50" style="padding: 0px !important;">
              <mat-form-field appearance="outline">
                <mat-label>Last Names:</mat-label>
                <input formControlName="lastName" autocomplete="off" matInput type="text">
              </mat-form-field>
            </span>
          </div>
          <span class="w-100">
            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input formControlName="email" autocomplete="off" matInput type="text">
              </mat-form-field>
          </span>

            <div class="w-100">
              <span class="w-50">
                <mat-form-field appearance="outline">
                  <mat-label>Role</mat-label>
                    <mat-select formControlName="role">
                      <mat-option *ngFor="let role of userRoles" [value]="role">
                          {{role}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
              </span>
               <span class="w-50">
                <mat-form-field appearance="outline">
                  <mat-label>Language</mat-label>
                    <mat-select formControlName="language">
                      <mat-option value="en">
                        English
                      </mat-option>
                      <mat-option value="fr">
                        French
                      </mat-option>
                    </mat-select>
                </mat-form-field>
              </span>

            <span class="w-50">
              <mat-form-field appearance="outline">
                  <mat-label>Affiliate</mat-label>
                    <mat-select formControlName="userAffiliate">
                      <ng-container *ngFor="let affiliate of data.listOfAffiliates">
                        <mat-option [value]="affiliate">
                          {{affiliate}}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                </mat-form-field>
            </span>
            </div>
            <div class="w-100"   style="padding-bottom:24px;">
              <span class="w-50">
                  <label for="status" style="padding-right:20px;">Status</label>
                  <mat-slide-toggle formControlName="status" #isChecked></mat-slide-toggle>
                  <label style="padding-left: 10px;color:red"> {{ isChecked._checked ? 'Active' : 'Deactivated'}}</label>
              </span>
              <span class="w-50">
                <label for="status" style="padding-right:20px;">Email Subscription</label>
                <mat-slide-toggle formControlName="emailSubscribe" #isSubscribed></mat-slide-toggle>
                <label style="padding-left: 10px;color:red"> {{ isSubscribed._checked ? 'Subscribed' : 'Unsubscribe'}}</label>
              </span>
            </div>
            <p *ngIf="form.invalid">*All the fields are mandatory</p>
            <div class="w-100" style="justify-content: flex-end !important;">
            <button mat-dialog-close mat-flat-button class="cancel-btn">Cancel</button>
              <button
                [disabled]="form.invalid || isDisabled"
                type="submit"
                mat-flat-button
                mat-dialog-close
                class="add-btn">
                Create User
              </button>
            </div>
        </form>
    </div>
  </section>
  `,
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  form: FormGroup;
  isChecked = true;
  isSubscribed = true;
  isDisabled = this.sb.getFieldStatus();
  userRoles = ['Administrator', 'Reader', 'Fraud Analyst', 'Affiliate_TF', 'Affiliate_TK' ,
  'Affiliate_TN' , 'Affiliate_TB' , 'Affiliate_TL', 'Affiliate_TD', 'Armatis', 'Analyst ML'];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<CreateUserComponent>,
              private sb: AppSandboxService) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      igg: new FormControl({value: '', disabled: this.isDisabled}, [Validators.required]),
      firstName: new FormControl({value: '', disabled: this.isDisabled}, [Validators.required]),
      lastName: new FormControl({value: '', disabled: this.isDisabled}, [Validators.required]),
      email: new FormControl({value: '', disabled: this.isDisabled}, [Validators.required, Validators.email]),
      userAffiliate: new FormControl({value: '', disabled: this.isDisabled}, [Validators.required]),
      role: new FormControl({value: '', disabled: this.isDisabled}, [Validators.required]),
      status: new FormControl({value: this.isChecked, disabled: this.isDisabled}, [Validators.required]),
      language: new FormControl({value: '', disabled: this.isDisabled}, [Validators.required]),
      emailSubscribe: new FormControl({value: this.isSubscribed, disabled: this.isDisabled}, [Validators.required]),
      isExternal: new FormControl({value: false, disabled: this.isDisabled}, [Validators.required]) 
    });
  }

  submitForm(): void {
    if (this.form.valid){
      const parameters = {
        firstname: this.form.value.firstName,
        lastname: this.form.value.lastName,
        email: this.form.value.email,
        user_affiliate: this.form.value.userAffiliate,
        role: this.form.value.role,
        status: this.form.value.status ? 'Active' : 'Deactivated',
        igg: this.form.value.igg,
        language: this.form.value.language,
        email_subscribe: this.form.value.emailSubscribe,
        modified_by: this.sb.currentUser.getValue().igg,
        modified_by_user: this.sb.currentUser.getValue().firstname + ' ' + this.sb.currentUser.getValue().lastname,
        is_external: this.form.value.isExternal
      };
      this.sb.createUser(parameters);
    }
  }

  togglerUserType(): void{
    const isExternal = this.form.controls.isExternal.value;
    if(isExternal){
      this.form.controls.igg.reset();
      this.form.controls.igg.disable();
    }else{
      this.form.controls.igg.enable();
    }
  }

}

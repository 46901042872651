import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DnaConfiguration } from 'src/modules/types/interfaces';
import { applyOnKeys, snakeToCamel } from '../../tools';
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class DnaConfigurationService {
  apiUrl = environment.apiUrl;
  sub: any;
  data: any;
  constructor(private http: HttpClient, private snackBar: SnackBarService,
              private spinnerService: NgxSpinnerService) { }


  fetchDnaConfigurations(): Observable<DnaConfiguration[]>{
    return  this.http.get(`${this.apiUrl}/dna_configuration`).pipe(
      map(value => {
      this.snackBar.openSnackBar('DNA configuration loaded successfully', 'success');
      return applyOnKeys(value, snakeToCamel);
    }), catchError(error => {
      this.snackBar.openSnackBar('Failed to load DNA configuration', 'error');
      return of([]);
    }));
  }

  postDnaConfigComment(param: {dna_type_map: string, comment: string}): Observable<DnaConfiguration[]>{
    return  this.http.post(`${this.apiUrl}/update_dna_comment`, param).pipe(
      map(value => {
      this.snackBar.openSnackBar('DNA commnet updated successfully', 'success');
      return applyOnKeys(value, snakeToCamel);
    }), catchError(error => {
      this.snackBar.openSnackBar('Failed to update DNA comment', 'error');
      return of([]);
    }));

  }

  export(): void{
    this.spinnerService.show().then();
    this.http.get(`${this.apiUrl}/export_dna_configuration`).subscribe(response => {
      const task_id = JSON.parse(JSON.stringify(response)).task_id;
      const blobName = JSON.parse(JSON.stringify(response)).blob_name;
      this.sub = timer(0, 10000).pipe(
        map(() => {
          this.http.get(`${this.apiUrl}/export_status?task_id=${task_id}&blob_name=${blobName}`, {
            responseType: 'json'
          }
          ).subscribe((res) => {
            if (res) {
              this.data = JSON.parse(JSON.stringify(res));
              if (this.data.status === 200) {
                const export_csv_url = this.data.export_csv_url;
                window.open(export_csv_url, '_self');
                this.sub.unsubscribe();
                this.spinnerService.hide().then();
              }
            }
          });
        })
      ).subscribe();
    }, error => {
      this.spinnerService.hide().then();
      this.snackBar.openSnackBar('Failed to export', 'error');
    });
}

}

import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {TransactionsArcyParams} from '../../types/interfaces/black-list';
import {FetchTransactionsArcyParamsList} from '../actions/transactions-arcy-paginator-actions';

@State<TransactionsArcyParams[]>({
  name: 'listTransactionsArcyParams',
  defaults: []
})

@Injectable({
  providedIn: 'root'
})
export class TransactionsArcyParamsState {
  @Action(FetchTransactionsArcyParamsList)
  getAlertsParams(ctx: StateContext<number>, {payload}: FetchTransactionsArcyParamsList): void {
    ctx.setState(payload);
  }
}

import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {FetchUserPageLength} from '../actions/users-paginator.actions';

@State<number[]>({
  name: 'UserPaginator',
  defaults: []
})

@Injectable({
  providedIn: 'root'
})
export class UserPaginatorState {
  @Action(FetchUserPageLength)
  getPageLength(ctx: StateContext<number>, {payload}: FetchUserPageLength): void {
    ctx.setState(payload);
  }
}

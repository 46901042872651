import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppSandboxService } from 'src/modules/app/app-sandbox.service';

@Component({
  selector: 'kyt-edit-user',
  template: `
      <section style="padding:14px;">
  <!-- <button mat-dialog-close class="mat-cancel-btn" mat-icon-button>
      <mat-icon>close icon</mat-icon>
    </button> -->
    <h1 mat-dialog-title style="font-size: 27px;">
      Edit User
    </h1>
    <div class="form-wrapper">
        <form [formGroup]="form" (ngSubmit)="submitForm(form.value)">
          <div class="w-100">
            <span class="w-50">
              <mat-form-field appearance="outline">
                <mat-label>First Name</mat-label>
                <input formControlName="firstname" autocomplete="off" matInput type="text">
              </mat-form-field>
            </span>
            <span class="w-50">
              <mat-form-field appearance="outline">
                <mat-label>Last Name</mat-label>
                <input formControlName="lastname" autocomplete="off" matInput type="text">
              </mat-form-field>
            </span>
          </div>
          <div class="w-100">
            <mat-form-field class="readonly" appearance="outline">
              <mat-label>Email</mat-label>
              <input formControlName="email" autocomplete="off" matInput type="text">
            </mat-form-field>
          </div>
            <div class="w-100">
              <span class="w-50">
                <mat-form-field appearance="outline">
                  <mat-label>Role</mat-label>
                    <mat-select formControlName="role">
                      <mat-option *ngFor="let role of userRoles" [value]="role">
                          {{role}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
              </span>
              <span class="w-50">
                <mat-form-field appearance="outline">
                  <mat-label>Language</mat-label>
                    <mat-select formControlName="language">
                      <mat-option value="en">
                        English
                      </mat-option>
                      <mat-option value="fr">
                        French
                      </mat-option>
                    </mat-select>
                </mat-form-field>
              </span>
              <span class="w-50">
                <mat-form-field appearance="outline">
                  <mat-label>Affiliate</mat-label>
                    <mat-select formControlName="userAffiliate">
                      <ng-container *ngFor="let affiliate of data.listOfAffiliates">
                        <mat-option [value]="affiliate">
                          {{affiliate}}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                </mat-form-field>
              </span>
            </div>
            <div class="w-100" style="padding-bottom:24px;">
              <span class="w-50">
                  <label for="status" style="padding-right:20px;">Status</label>
                  <mat-slide-toggle formControlName="status" #isChecked></mat-slide-toggle>
                  <label style="padding-left: 10px;color:red"> {{ isChecked._checked ? 'Active' : 'Deactivated'}}</label>
                 </span>
              <span class="w-50">
                <label for="status" style="padding-right:20px;">Email Subscription</label>
                <mat-slide-toggle formControlName="emailSubscribe" #isSubscribed></mat-slide-toggle>
                <label style="padding-left: 10px;color:red"> {{ isSubscribed._checked ? 'Subscribed' : 'Unsubscribe'}}</label>
              </span>
            </div>
            <p *ngIf="form.invalid">*All the fields are mandatory</p>
            <div class="w-100" style="justify-content: flex-end !important;">
            <button mat-dialog-close mat-flat-button class="cancel-btn">Cancel</button>
              <button
                [disabled]="form.invalid || isDisabled"
                type="submit"
                mat-flat-button
                mat-dialog-close
                class="add-btn">
                Edit User
              </button>
            </div>
        </form>
    </div>
  </section>
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
      <p>Data is loading, please wait. </p>
  </ngx-spinner>
  `,
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  form: FormGroup;
  userRoles = ['Administrator', 'Reader', 'Fraud Analyst', 'Affiliate_TF', 'Affiliate_TK' ,
              'Affiliate_TN' , 'Affiliate_TB' , 'Affiliate_TL', 'Affiliate_TD', 'Armatis', 'Analyst ML'];
  isDisabled = this.sb.getFieldStatus();
  initialFormValues: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<EditUserComponent>, private sb: AppSandboxService) {
    this.dialogRef.disableClose = true;
  }


  ngOnInit(): void {
      this.form = new FormGroup({
      firstname: new FormControl(this.data?.user?.firstname || '', [Validators.required]),
      lastname: new FormControl(this.data?.user?.lastname || '', [Validators.required]),
      email: new FormControl(this.data?.user?.email || '', [Validators.required, Validators.email]),
      role: new FormControl(this.data?.user?.role || '', [Validators.required]),
      status: new FormControl(this.data?.user?.status === 'Active' ? true : false , [Validators.required]),
      language: new FormControl(this.data?.user?.language || '', [Validators.required]),
      userAffiliate: new FormControl(this.data?.user?.userAffiliate || '', [Validators.required]),
      emailSubscribe: new FormControl(this.data?.user?.emailSubscribe === true ? true : false, [Validators.required])
    });
      this.initialFormValues = this.form.value;
      if (this.isDisabled){
      this.form.disable({ emitEvent: this.isDisabled });
    }
  }

  isFormChanged(): boolean{
    const formValues = this.form.value;
    for (const field in formValues){
      if (typeof(formValues[field]) === 'string'){
        if (formValues[field].trim() !== this.initialFormValues[field].trim()){
          return true;
        }
      }
      else if (formValues[field] !== this.initialFormValues[field]){
        return true;
      }
    }
    return false;
  }


  submitForm(event: any): void {
    const isFormChanged = this.isFormChanged();
    if (this.form.valid && this.isFormChanged()){
      const parameters = {
        id: this.data.user.id,
        firstname: this.form.value.firstname,
        lastname: this.form.value.lastname,
        email: this.form.value.email,
        user_affiliate: this.form.value.userAffiliate,
        role: this.form.value.role,
        status: event.status === true ? 'Active' : 'Deactivated',
        igg: this.data.user.igg,
        modified_by: this.sb.currentUser.getValue().igg,
        modified_by_user: this.sb.currentUser.getValue().firstname + ' ' + this.sb.currentUser.getValue().lastname,
        language: this.form.value.language,
        email_subscribe: this.form.value.emailSubscribe
      };
      this.sb.updateUserDetails(parameters);
    }else{
      this.dialogRef.close();
    }
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CustomerByNetworks, FilterCustomerByNetworks } from 'src/modules/types/interfaces';
import { applyOnKeys, camelToSnake, snakeToCamel } from '../../tools';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of, timer } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SnackBarService } from '../snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerByNetworksService {
  apiUrl = environment.apiUrl;
  sub: any;
  data: any;

  constructor(private http: HttpClient, private snackBar: SnackBarService,
              private spinnerService: NgxSpinnerService) { }

  getCustomerByNetworks(parameter: FilterCustomerByNetworks): Observable<CustomerByNetworks[]>{
    this.spinnerService.show();
    return this.http.post(`${this.apiUrl}/customer_by_network`, applyOnKeys(parameter, camelToSnake)).pipe(
      map(value => {
        this.spinnerService.hide();
        this.snackBar.openSnackBar('Customer by networks loaded successfully', 'success');
        return applyOnKeys(value, snakeToCamel);
      }), catchError(error => {
        this.spinnerService.hide();
        this.snackBar.openSnackBar('Failed to load customer by networks', 'error');
        return of([]);
      })
    );
  }

  getCustomerByNetworksPaginator(parameter: FilterCustomerByNetworks): Observable<number>{
    this.spinnerService.show();
    return this.http.post(`${this.apiUrl}/customer_by_network_paginator`, applyOnKeys(parameter, camelToSnake)).pipe(
      map(value => {
        this.spinnerService.hide();
        return applyOnKeys(value, snakeToCamel);
      }), catchError(error => {
        this.spinnerService.hide();
        return of([]);
      })
    );
  }

  getBlacklistPipelineDetails(): Observable<any>{
    this.spinnerService.show();
    return this.http.get(`${this.apiUrl}/pipeline_execution`).pipe(
      map(value => {
        this.spinnerService.hide();
        return applyOnKeys(value, snakeToCamel);
      }), catchError(error => {
        this.spinnerService.hide();
        return of([]);
      })
    );
  }


  exportCustomerByNetworks(filter: FilterCustomerByNetworks): void{
    this.spinnerService.show().then();
    this.http.post(`${this.apiUrl}/export_customer_by_network`, applyOnKeys(filter, camelToSnake)
    ).subscribe(response => {
      const task_id = JSON.parse(JSON.stringify(response)).task_id;
      const blobName = JSON.parse(JSON.stringify(response)).blob_name;
      this.sub = timer(0, 10000).pipe(
        map(() => {
          this.http.get(`${this.apiUrl}/export_status?task_id=${task_id}&blob_name=${blobName}`, {
            responseType: 'json'
          }
          ).subscribe((res) => {
            if (res) {
              this.data = JSON.parse(JSON.stringify(res));
              if (this.data.status === 200) {
                const export_csv_url = this.data.export_csv_url;
                window.open(export_csv_url, '_self');
                this.sub.unsubscribe();
                this.spinnerService.hide().then();
              }
            }
          });
        })
      ).subscribe();
    }, error => {
      this.spinnerService.hide().then();
    });
}


async updateCustomerByNetworks(parameters: { id: string; factoriseCustomer: boolean; blockCustomer: boolean}): Promise<any>{
  this.spinnerService.show();
  return await this.http.post(`${this.apiUrl}/update_cust_by_network`, applyOnKeys(parameters, camelToSnake)).toPromise()
  .then(response => {
    this.snackBar.openSnackBar('Updated successfulluy', 'success');
    return applyOnKeys(response, snakeToCamel);
  }).catch((error) => {
    this.snackBar.openSnackBar('Failed to update', 'error');
    console.error(error?.error || error || 'Unexpected error');
    return [];
  }).finally(() => this.spinnerService.hide());
}
}

import { APP_INITIALIZER, NgModule } from "@angular/core"
import { AppRoutingModule } from './app-routing.module';
import { AppContainer } from './containers/app/app.container';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../../environments/environment';
import { LandingContainer } from './containers/landing/landing.container';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './helpers/token.interceptor';
import { HeaderComponent } from './components/header/header.component';
import { LogoutComponent } from './components/logout/logout.component';
import { globalState } from '../state-management/global.state';
import { DatePipe } from '@angular/common';
import { LoginContainer } from './containers/login/login.container';
import { AdminComponent } from './containers/admin/admin.component';
import { UsersTableComponent } from './containers/admin/users-table/users-table.component';
import { UsersFilterComponent } from './containers/admin/users-filter/users-filter.component';
import { CreateUserComponent } from './containers/admin/create-user/create-user.component';
import { MappingCodeComponent } from './containers/black-list/components/mapping-code/mapping-code.component';
import { EditUserComponent } from './containers/admin/edit-user/edit-user.component';
// tslint:disable-next-line:max-line-length
import { PreviousRolesComponent } from './containers/admin/users-table/previous-roles/previous-roles.component';
import { NotAllowedComponent } from './components/not-allowed/not-allowed.component';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomepageComponent } from './containers/homepage/homepage.component';
import { AlertInsightComponent } from './containers/homepage/alert-insight/alert-insight.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { AlertDetailsComponent } from './components/send-mail/alert-details/alert-details.component';
import { AuditabilityComponent } from './containers/auditability/auditability.component';
import { AuditabilityFiltersComponent } from './containers/auditability/auditability-filters/auditability-filters.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { ConfigService } from "./services/config/config.service";

const appInitilizarFn = (appConfig: ConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

@NgModule({
  declarations: [
    AppContainer,
    LandingContainer,
    LogoutComponent,
    HeaderComponent,
    LoginContainer,
    AdminComponent,
    UsersTableComponent,
    UsersFilterComponent,
    CreateUserComponent,
    MappingCodeComponent,
    EditUserComponent,
    PreviousRolesComponent,
    NotAllowedComponent,
    HomepageComponent,
    AlertInsightComponent,
    ProgressBarComponent,
    AlertDetailsComponent,
    AuditabilityComponent,
    AuditabilityFiltersComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,    
    SharedModule,
    NgxsModule.forRoot(
      globalState,
      {
        developmentMode: !environment.production
      }),
    environment.plugins,
    AmplifyAuthenticatorModule
  ],
  providers: [
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitilizarFn,
      multi: true,
      deps: [ConfigService],
    },
  ],
  bootstrap: [AppContainer]
})
export class AppModule {
}

import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of, timer} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {applyOnKeys, camelToSnake, snakeToCamel} from '../../tools';
import { ListLenght, ListLengthFilters, ListLengthParameters } from 'src/modules/types/interfaces';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from '../snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class ListLengthService {

  apiUrl = environment.apiUrl;
  data;
  sub;
  constructor(private http: HttpClient, private snackBar: SnackBarService,
              private spinnerService: NgxSpinnerService) { }

  getListLength(paramerters: ListLengthFilters): Observable<ListLenght[]>{
    this.spinnerService.show();
    return this.http.post(`${this.apiUrl}/list_length`, applyOnKeys(paramerters,camelToSnake)).pipe(
      map(value => {
        this.spinnerService.hide();
        this.snackBar.openSnackBar("List length loaded successfully","success");
        return applyOnKeys(value, snakeToCamel);
      }), catchError(error => {
        this.spinnerService.hide();
        this.snackBar.openSnackBar("Failed to load list length","error");
        return of([]);
      })
    );
  }

  postListLenght(parameters: ListLengthParameters): Observable<ListLenght[]>{
    return this.http.post(`${this.apiUrl}/update_list_length`, parameters).pipe(
      map(value => {return applyOnKeys(value, snakeToCamel);
      }), catchError(error => {
        return of([]);
      })
    );
  }


  deleteListLength(networkLib: string): Observable<ListLenght[]>{
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        network_lib: networkLib
      }
    };
    return this.http.delete(`${this.apiUrl}/update_list_length`, options).pipe(
      map(value => {return applyOnKeys(value, snakeToCamel);
      }), catchError(error => {
        return of([]);
      })
    );
  }

  exportListLength(paramerters: ListLengthFilters): void {
    this.spinnerService.show().then();
    this.http.post(`${this.apiUrl}/export_list_length`,applyOnKeys(paramerters, camelToSnake)).subscribe(response => {
      const blob_name = JSON.parse(JSON.stringify(response)).blob_name;
      this.sub = timer(0, 10000).pipe(
        map(() => {
          this.http.get(`${this.apiUrl}/export_status?task_id=${blob_name}`, {
            responseType: 'json'
          }).subscribe((res) => {
            if (res) {
              this.data = JSON.parse(JSON.stringify(res));
              if (this.data.status === 200) {
                const export_csv_url = this.data.export_csv_url;
                window.open(export_csv_url, '_self');
                this.sub.unsubscribe();
                this.spinnerService.hide().then();
              }
            }
          }, (error) => {
            this.spinnerService.hide();
            this.snackBar.openSnackBar('Failed to export', 'error');
            this.sub.unsubscribe();
          });
        })).subscribe();
    }, error => {
      this.spinnerService.hide().then();
      this.snackBar.openSnackBar('Failed to export', 'error');
    });

  }

}
